import { CircularLoader, formatTime } from "@curaleaf-international/components";
import { EventImpl } from "@fullcalendar/core/internal";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { addMinutes, addWeeks, isAfter, isSameDay, subHours } from "date-fns";
import { useEffect, useRef, useState } from "react";

import CreateAppointmentDialog from "src/components/CreateAppointmentDialog";
import EditAppointmentDialog from "src/components/EditAppointmentDialog";
import { SelectAppointmentCalendarStyleContainer } from "src/components/SelectAppointmentCalendarStyleContainer";
import {
  Appointment,
  AppointmentStatus,
  AppointmentType,
  SpecialityGroup,
} from "src/models";
import { AppointmentSlot } from "src/models/appointmentSlot";
import { PatientFollowUpPreference } from "src/models/patient";
import {
  ClinicianTypeFilter,
  useAppointmentSlotsQuery,
  useClinicianQuery,
  usePatientAppointmentsQuery,
  usePatientQuery,
  useSpecialityGroupsQuery,
} from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  appointment?: Appointment;
  patientId: string;
}

const SelectAppointmentCalendar = ({ appointment, patientId }: IProps) => {
  const [selectedSlot, setSelectedSlot] = useState<EventImpl | null>(null);
  const [clinicianIds, setClinicianIds] = useState<string[]>([]);
  const { data: appointments } = usePatientAppointmentsQuery(patientId);
  const [appointmentType, setAppointmentType] = useState<AppointmentType>(
    AppointmentType.INITIAL,
  );
  const { data: clinician } = useClinicianQuery(appointment?.clinicianId);
  const [clinicianType, setClinicianType] = useState<ClinicianTypeFilter>(
    ClinicianTypeFilter.CONSULTANTS,
  );
  const { data: specialityGroups } = useSpecialityGroupsQuery();
  const { data: patient } = usePatientQuery(patientId);
  const { data: slots } = useAppointmentSlotsQuery(
    appointmentType,
    clinicianType,
    patientId,
  );

  const currentDate = new Date();
  const theme = useTheme();
  const calendarRef = useRef<FullCalendar>(null);

  const headerToolbar = {
    left: "timeGridDay,timeGridWeek,dayGridMonth",
    center: "title",
    right: "prev,today,next",
  };

  useEffect(() => {
    if (appointment && clinician) {
      setAppointmentType(appointment.type);
      setClinicianType(
        clinician.clinicianType === "CONSULTANT"
          ? ClinicianTypeFilter.CONSULTANTS
          : ClinicianTypeFilter.PHARMACISTS_AND_GPS,
      );
    } else if (appointments && patient) {
      const confirmedAppointments = appointments.filter(
        (appt) => appt.status === AppointmentStatus.CONFIRMED,
      );
      if (confirmedAppointments.length === 0) {
        setAppointmentType(AppointmentType.INITIAL);
        setClinicianType(ClinicianTypeFilter.CONSULTANTS);
      } else if (
        patient.patientFollowupPreference ===
        PatientFollowUpPreference.ASSIGNED_CONSULTANT
      ) {
        setAppointmentType(AppointmentType.FOLLOW_UP);
        setClinicianType(ClinicianTypeFilter.CONSULTANTS);
      } else {
        setAppointmentType(AppointmentType.FOLLOW_UP);
        setClinicianType(ClinicianTypeFilter.PHARMACISTS_AND_GPS);
      }
    } else {
      setAppointmentType(AppointmentType.FOLLOW_UP);
      setClinicianType(ClinicianTypeFilter.PHARMACISTS_AND_GPS);
    }
  }, [patient, appointment, appointments, clinician]);

  useEffect(() => {
    let matchedSpecialityGroup: SpecialityGroup | null = null;
    const patientDiagnosis = patient?.primaryDiagnosis ?? null;
    let specialityGroupClinicianIds: string[] = [];
    if (patientDiagnosis !== null) {
      matchedSpecialityGroup =
        specialityGroups?.find((group: SpecialityGroup) =>
          group.associatedDiagnoses.includes(patientDiagnosis),
        ) ?? null;
      if (matchedSpecialityGroup) {
        specialityGroupClinicianIds = matchedSpecialityGroup.clinicians;
      }
    }
    if (appointment) {
      setClinicianIds([appointment.clinicianId]);
    } else {
      if (patient && appointments) {
        const confirmedAppointments = appointments.filter(
          (appt) => appt.status === AppointmentStatus.CONFIRMED,
        );
        if (confirmedAppointments.length === 0) {
          if (patient.assignedConsultantId !== null) {
            setClinicianIds([patient.assignedConsultantId]);
          } else {
            setClinicianIds(specialityGroupClinicianIds);
          }
        } else {
          switch (patient?.patientFollowupPreference) {
            case PatientFollowUpPreference.STANDARD_GROUP_PHARMACIST_OR_GP:
              setClinicianIds(specialityGroupClinicianIds);
              break;
            case PatientFollowUpPreference.ASSIGNED_CONSULTANT:
              if (patient.assignedConsultantId !== null) {
                setClinicianIds([patient.assignedConsultantId]);
              } else {
                setClinicianIds(specialityGroupClinicianIds);
              }
              break;
            case PatientFollowUpPreference.ASSIGNED_PHARMACIST_OR_GP:
              if (patient.assignedPharmacistOrGpId !== null) {
                setClinicianIds([patient.assignedPharmacistOrGpId]);
              } else {
                setClinicianIds(specialityGroupClinicianIds);
              }
              break;
            case PatientFollowUpPreference.MENTAL_HEALTH_PHARMACIST_OR_GP:
              setClinicianIds(
                specialityGroupClinicianIds.filter((id) => {
                  return matchedSpecialityGroup?.complexClinicians.includes(id);
                }),
              );
              break;
            default:
              setClinicianIds(specialityGroupClinicianIds);
          }
        }
      }
    }
  }, [appointment, appointments, patient, specialityGroups]);

  const slotEvents = slots
    ?.filter((slot: AppointmentSlot) => clinicianIds.includes(slot.clinicianId))
    .map((slot) => {
      return {
        title: `${formatTime(slot.start)} to ${formatTime(addMinutes(slot.start, slot.length))}`,
        start: slot.start,
        end: addMinutes(slot.start, slot.length),
        backgroundColor: isAfter(
          new Date(),
          subHours(slot.start, slot.minimumHoursBeforeBooking),
        )
          ? alpha(theme.palette.warning.light, 0.4)
          : alpha(theme.palette.success.light, 0.1),
        borderColor: isAfter(
          new Date(),
          subHours(slot.start, slot.minimumHoursBeforeBooking),
        )
          ? alpha(theme.palette.warning.light, 0.5)
          : alpha(theme.palette.success.light, 0.2),
        extendedProps: {
          appointmentType: slot.appointmentType,
          clinicianId: slot.clinicianId,
          clinicianName: slot.clinicianName,
          length: slot.length,
          price: slot.price,
        },
      };
    });

  const handleAppointmentTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as keyof typeof AppointmentType;
    setAppointmentType(AppointmentType[value]);
  };

  const handleClinicianTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as keyof typeof ClinicianTypeFilter;
    setClinicianType(ClinicianTypeFilter[value]);
  };

  const handleClinicianChange = (
    event: SelectChangeEvent<typeof clinicianIds>,
  ) => {
    const value = event.target.value;
    setClinicianIds(typeof value === "string" ? value.split(",") : value);
  };

  const handleEventClick = (event: EventImpl) => {
    if (event && event.start) {
      setSelectedSlot(event);
    }
  };

  const handleDateClick = (date: Date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView("timeGridDay", date);
    }
  };

  const moreLinkContent = (eventCount: number) => {
    return (
      <Typography
        marginBottom={2}
        sx={{
          width: "100%",
          textAlign: "center",
          display: { xs: "none", sm: "block" },
        }}
      >
        {eventCount} available
      </Typography>
    );
  };

  const clinicianOptions = slots?.reduce(
    (acc: { label: string; value: string }[], slot) => {
      if (!acc.find((item) => item.value === slot.clinicianId)) {
        acc.push({
          label: slot.clinicianName,
          value: slot.clinicianId,
        });
      }
      return acc;
    },
    [],
  );

  const viewDidMount = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi.view.type == "dayGridMonth") {
        calendarApi.setOption("dayMaxEvents", 0);
      }
    }
  };

  const dayCellClassNames = (date: Date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const events = calendarApi.getEvents();
      const hasEvents = events.some((event) =>
        isSameDay(event.start ?? "", date),
      );

      if (hasEvents) {
        return ["appts-available"];
      }
    }
    return [];
  };

  const handleClinicianIconClick = () => {
    if (clinicianIds.length > 0) {
      setClinicianIds([]);
    } else {
      setClinicianIds(
        clinicianOptions?.map((clinician) => clinician.value) ?? [],
      );
    }
  };

  let alertMessage = "";
  if (slots?.length === 0) {
    alertMessage = `There are currently no ${convertEnumValueToReadableString(appointmentType, "-")}
      appointments. Please try again later.`;
  } else if (
    appointment &&
    !slots?.find((slot) => slot.clinicianId === appointment.clinicianId)
  ) {
    alertMessage =
      "There are currently no appointments available with the same Clinician. An alternative can be selected.";
  } else if (
    !appointment &&
    patient?.assignedConsultantId &&
    !slots?.find((slot) => slot.clinicianId === patient?.assignedConsultantId)
  ) {
    alertMessage =
      "There are currently no appointments available with this patient's assigned Clinician. An alternative can be selected.";
  }
  return (
    <>
      <Grid container spacing={1} alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="appointmentType">Appointment Type</InputLabel>
            <Select
              sx={{ textTransform: "capitalize" }}
              labelId="appointmentType"
              fullWidth
              value={appointmentType}
              onChange={handleAppointmentTypeChange}
            >
              {Object.values(AppointmentType).map((type) => (
                <MenuItem
                  sx={{ textTransform: "capitalize" }}
                  key={type}
                  value={type}
                >
                  {type.toLowerCase().replace("_", "-")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="clinicianType">Clinician Type</InputLabel>
            <Select
              label="Clinician Type"
              fullWidth
              value={clinicianType}
              onChange={handleClinicianTypeChange}
            >
              <MenuItem value={ClinicianTypeFilter.CONSULTANTS}>
                Consultants
              </MenuItem>
              <MenuItem value={ClinicianTypeFilter.PHARMACISTS_AND_GPS}>
                Pharmacists and GPs
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="clinicians">Clinicians</InputLabel>
            <Select
              labelId="clinicians"
              multiple
              fullWidth
              value={clinicianIds}
              onChange={handleClinicianChange}
              disabled={clinicianOptions?.length === 0}
              endAdornment={
                <IconButton
                  sx={{ marginRight: 2 }}
                  onClick={handleClinicianIconClick}
                >
                  {clinicianIds.length > 0 ? <ClearIcon /> : <RefreshIcon />}
                </IconButton>
              }
            >
              {clinicianOptions?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <>
        {selectedSlot !== null && !appointment ? (
          <CreateAppointmentDialog
            open={selectedSlot !== null}
            onClose={() => setSelectedSlot(null)}
            patient={patient}
            selectedSlot={selectedSlot}
          />
        ) : selectedSlot !== null && appointment ? (
          <EditAppointmentDialog
            appointmentId={appointment.id}
            open={selectedSlot !== null}
            onClose={() => setSelectedSlot(null)}
            patient={patient}
            selectedSlot={selectedSlot}
          />
        ) : null}
      </>
      {alertMessage ? (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          {alertMessage}
        </Alert>
      ) : null}
      <Box position="relative" zIndex={1}>
        <Box sx={{ opacity: slotEvents === undefined ? 0.3 : 1 }}>
          <SelectAppointmentCalendarStyleContainer>
            <FullCalendar
              allDaySlot={false}
              dateClick={(event) => handleDateClick(event.date)}
              dayCellClassNames={(event) => dayCellClassNames(event.date)}
              dayMaxEvents={
                calendarRef.current?.getApi().view.type == "dayGridMonth"
                  ? 0
                  : false
              }
              eventClick={(event) => {
                handleEventClick(event.event);
              }}
              displayEventTime={false}
              events={slotEvents}
              fixedWeekCount={true}
              firstDay={1}
              height="auto"
              headerToolbar={headerToolbar}
              initialDate={new Date()}
              initialView="dayGridMonth"
              locale="en-IE"
              slotEventOverlap={false}
              dayMaxEventRows={false}
              moreLinkContent={(event) => moreLinkContent(event.num)}
              moreLinkClick={(event) => handleDateClick(event.date)}
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              ref={calendarRef}
              slotMinTime="07:00:00"
              slotMaxTime="23:00:00"
              validRange={{
                start: currentDate,
                end: addWeeks(currentDate, 12),
              }}
              viewDidMount={viewDidMount}
            />
          </SelectAppointmentCalendarStyleContainer>
        </Box>
        {slotEvents == undefined ? (
          <Box
            position={"absolute"}
            zIndex={2}
            top={0}
            justify-content="center"
            width="100%"
            height="100%"
          >
            <CircularLoader />
          </Box>
        ) : null}
      </Box>
    </>
  );
};
export default SelectAppointmentCalendar;
