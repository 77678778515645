import { formatDate } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { Patient } from "src/models/patient";

interface IProps {
  patient: Patient;
}

const PatientRow = ({ patient }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/patients/${patient.id}/`}
        >
          View
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/patients/${patient.id}/resolve-email-issue/`}
          disabled={!patient.hasEmailIssues}
        >
          Resolve email issue
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>
          <Link component={WLink} href={`/patients/${patient.id}/`}>
            {patient.name}
          </Link>
        </TableCell>
        <TableCell>{patient.email}</TableCell>
        <TableCell>{formatDate(patient.dob)}</TableCell>
        <TableCell>{patient.postcode}</TableCell>
        <TableCell>{patient.nhsNumber}</TableCell>
        <TableCell>{formatDate(patient.createdAt)}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PatientRow;
