import { Title } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import SelectAppointmentCalendar from "src/components/SelectAppointmentCalendar";
import { usePatientQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const CreateAppointment = ({ patientId }: IProps) => {
  const { data: patient } = usePatientQuery(patientId);

  return (
    <>
      <Title
        title={`Create Appointment for ${patient?.name}`}
        breadcrumbs={[
          { to: "/patients/", label: "Patients" },
          { label: patient?.name ?? "", to: `/patients/${patient?.id}/` },
          { label: `Create Appointment for ${patient?.name}` },
        ]}
      />
      <Card>
        <CardContent>
          <SelectAppointmentCalendar patientId={patientId} />
        </CardContent>
      </Card>
    </>
  );
};
export default CreateAppointment;
