import * as z from "zod";

import { AppointmentType } from "src/models/appointment";

const clinicianAvailabilitySchema = z.object({
  id: z.coerce.number().int().positive(),
  active: z.boolean(),
  appointmentTypes: z.array(z.nativeEnum(AppointmentType)),
  clinicianId: z.string().uuid(),
  created: z.coerce.date(),
  createdBy: z.coerce.number().int().positive(),
  createdByEmail: z.string().email(),
  endAt: z.coerce.date(),
  recurringId: z.number().int().positive().nullable(),
  startAt: z.coerce.date(),
});

export type ClinicianAvailability = z.infer<typeof clinicianAvailabilitySchema>;

export const newClinicianAvailability = (
  data: unknown,
): ClinicianAvailability => clinicianAvailabilitySchema.parse(data);
