import { Title, ToastContext } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import StaffMemberForm, {
  ValidatedType,
  roles,
} from "src/components/StaffMemberForm";
import { Role } from "src/models/staff";
import { useCreateStaffMutation } from "src/queries";

const CreateStaffMember = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createStaffMember } = useCreateStaffMutation();

  const onSubmit = async (data: ValidatedType) => {
    const newRoles = roles.filter((role) => data.roles[role]);
    try {
      await createStaffMember({
        email: data.email,
        roles: newRoles as Role[],
      });
      if (
        newRoles.includes("CONSULTANT") ||
        newRoles.includes("PHARMACIST") ||
        newRoles.includes("GENERAL_PRACTITIONER")
      ) {
        addToast("Staff member created.", "success");
        setLocation("/clinicians/new/");
      } else {
        addToast("Staff member created.", "success");
        setLocation("/staff/");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          addToast("Staff member already exists.", "error");
        } else if (error.response?.status === 404) {
          addToast("User could not be found from email.", "error");
        }
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Add Staff Member"
        breadcrumbs={[
          { label: "Staff", to: "/staff/" },
          { label: `Add Staff Member` },
        ]}
      />
      <StaffMemberForm onSubmit={onSubmit} />
    </>
  );
};

export default CreateStaffMember;
