import {
  SkeletonRow,
  formatDateTime,
  sortByKey,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { usePatientTrackingNotesQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientTrackingNotesTable = ({ patientId }: IProps) => {
  const { data: trackingNotes } = usePatientTrackingNotesQuery(patientId);

  let rows;
  if (trackingNotes === undefined) {
    rows = <SkeletonRow cols={2} />;
  } else if (trackingNotes.length === 0) {
    rows = (
      <TableRow>
        <TableCell>No tracking notes.</TableCell>
      </TableRow>
    );
  } else {
    rows = trackingNotes
      .sort(sortByKey((note) => [note.time, "desc"]))
      .map((note, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{note.note}</TableCell>
            <TableCell>{formatDateTime(note.time)}</TableCell>
          </TableRow>
        );
      });
  }

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={"80%"}>Note</TableCell>
              <TableCell>Time Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default PatientTrackingNotesTable;
