import * as z from "zod";

export enum PrescriberType {
  PRIVATE_DOCTOR = "PRIVATE_DOCTOR",
  PRIVATE_PHARMACIST_PRESCRIBER = "PRIVATE_PHARMACIST_PRESCRIBER",
}

const prescriberchema = z.object({
  id: z.string().uuid(),
  clinicianId: z.string().uuid(),
  clinicianName: z.string(),
  created: z.coerce.date(),
  createdBy: z.number(),
  createdByEmail: z.string().email(),
  fullTitle: z.string(),
  pin: z.string(),
  prescriberType: z.nativeEnum(PrescriberType),
  registrationNumber: z.string(),
  specialism: z.string(),
  staffId: z.coerce.number().int().positive(),
  signature: z.string().nullable(),
});

export type Prescriber = z.infer<typeof prescriberchema>;

export const newPrescriber = (data: unknown): Prescriber =>
  prescriberchema.parse(data);
