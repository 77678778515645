import {
  formatDate,
  formatDateTime,
  formatTime,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { Appointment } from "src/models";

interface IProps {
  appointment: Appointment;
}

const AppointmentsRow = ({ appointment }: IProps) => {
  return (
    <TableRow>
      <TableCell>
        <Link component={WLink} to={`/appointments/${appointment.id}/`}>
          {appointment.id}
        </Link>
      </TableCell>
      <TableCell>{formatDate(appointment.startAt)}</TableCell>
      <TableCell>{formatTime(appointment.startAt)}</TableCell>
      <TableCell>{appointment.length} minutes</TableCell>
      <TableCell>{appointment.clinicianName}</TableCell>
      <TableCell sx={{ textTransform: "capitalize" }}>
        {appointment.type.toLowerCase().replace("_", "-")}
      </TableCell>
      <TableCell sx={{ textTransform: "capitalize" }}>
        {appointment.status} <br />
        {formatDateTime(appointment.currentStateTimestamp)}
      </TableCell>
    </TableRow>
  );
};
export default AppointmentsRow;
