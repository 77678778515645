import { z } from "zod";

const staffEmailSchema = z.object({
  id: z.number().int().positive(),
  html: z.string(),
  ident: z.string(),
  sent: z.coerce.date().nullable(),
  staffId: z.number().int().positive().nullable(),
  subject: z.string(),
  txt: z.string(),
});

export type StaffEmail = z.infer<typeof staffEmailSchema>;

export const newStaffEmail = (data: unknown): StaffEmail =>
  staffEmailSchema.parse(data);
