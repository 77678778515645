import {
  formatCurrency,
  formatDate,
  formatTime,
  ToastContext,
} from "@curaleaf-international/components";
import { EventImpl } from "@fullcalendar/core/internal";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { addMinutes } from "date-fns";
import { useContext } from "react";
import { useLocation } from "wouter";

import { Patient } from "src/models/patient";
import { useCreateAppointmentMutation } from "src/queries/appointments";

interface IProps {
  onClose: () => void;
  open: boolean;
  patient: Patient | undefined;
  selectedSlot: EventImpl;
}

const CreateAppointmentDialog = ({
  onClose,
  open,
  patient,
  selectedSlot,
}: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createAppointment, isPending } =
    useCreateAppointmentMutation();

  const handleConfirmAppointment = async () => {
    if (patient !== undefined && selectedSlot.start !== null) {
      try {
        const appointmentId = await createAppointment({
          appointmentType: selectedSlot.extendedProps.appointmentType,
          clinicianId: selectedSlot.extendedProps.clinicianId,
          patientId: patient.id,
          start: selectedSlot.start,
        });
        setLocation(`/appointments/${appointmentId}/book/`);
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "NOT_VALID"
        ) {
          addToast("Invalid appointment.", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "SLOT_NOT_AVAILABLE"
        ) {
          addToast("Appointment slot not available.", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "OVERLAPS_EXISTING_APPOINTMENT"
        ) {
          addToast("Overlaps existing appointment.", "error");
        } else {
          addToast("Try again", "error");
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Book Appointment</DialogTitle>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Clinician</TableCell>
              <TableCell>{selectedSlot.extendedProps.clinicianName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>{patient?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Appointment Type</TableCell>
              <TableCell sx={{ textTransform: "capitalize" }}>
                {selectedSlot.extendedProps.appointmentType
                  .toLowerCase()
                  .replace("_", "-")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date</TableCell>
              {selectedSlot && selectedSlot.start ? (
                <TableCell>{formatDate(selectedSlot?.start)}</TableCell>
              ) : null}
            </TableRow>
            <TableRow>
              <TableCell>Time</TableCell>
              {selectedSlot && selectedSlot.start ? (
                <TableCell>
                  {formatTime(selectedSlot.start)} to{" "}
                  {formatTime(
                    addMinutes(
                      selectedSlot.start,
                      selectedSlot.extendedProps.length,
                    ),
                  )}
                </TableCell>
              ) : null}
            </TableRow>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell>
                {formatCurrency(selectedSlot.extendedProps.price)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          variant="contained"
          onClick={handleConfirmAppointment}
          loading={isPending}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAppointmentDialog;
