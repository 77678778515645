import {
  Dropzone,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import { useUploadTravelLetterMutation } from "src/queries";

interface IProps {
  travelLetterId: string;
}

const UploadTravelLetter = ({ travelLetterId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const [files, setFiles] = useState<File[]>([]);
  const { mutateAsync: upload, isPending } =
    useUploadTravelLetterMutation(travelLetterId);

  const onClick = async () => {
    try {
      await upload(files);
      addToast("Travel Letter uploaded", "success");
      setLocation(`/travel-letters/${travelLetterId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  const onDrop = async (newFiles: FileList) => {
    setFiles(
      [...files, ...newFiles].filter((file) => file.type === "application/pdf"),
    );
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: "/travel-letters/", label: "Travel Letters" },
          { to: `/travel-letters/${travelLetterId}/`, label: "This letter" },
          { label: "Upload Travel Letter" },
        ]}
        title="Upload Travel Letter"
      />
      <Card>
        <CardContent>
          <Dropzone label="Add file" multiple={false} onDrop={onDrop} />
          <List>
            {files.map((file) => (
              <ListItem
                key={file.name}
                secondaryAction={
                  <IconButton
                    aria-label="delete"
                    edge="end"
                    onClick={() =>
                      setFiles(files.filter((checkFile) => checkFile !== file))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={files.length === 0}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Upload
          </LoadingButton>
          <FormLinks
            links={[
              { label: "Back", to: `/travel-letters/${travelLetterId}/` },
            ]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default UploadTravelLetter;
