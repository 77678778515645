import {
  FormLinks,
  DateField,
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { addMonths } from "date-fns";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { AppointmentType } from "src/models";
import {
  useCreateAppointmentCreditMutation,
  usePatientQuery,
} from "src/queries";

const FormSchema = z.object({
  activeFrom: z.coerce.date(),
  activeTo: z.coerce.date(),
  appointmentType: z.nativeEnum(AppointmentType),
  reason: z.string().min(1),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  patientId: string;
}

const CreateAppointmentCredit = ({ patientId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateAppointmentCreditMutation();
  const { data: patient } = usePatientQuery(patientId);
  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      activeFrom: new Date(),
      activeTo: addMonths(new Date(), 3),
      appointmentType: AppointmentType.FOLLOW_UP,
      reason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        ...data,
        patientId,
      });
      addToast("Credit created", "success");
      setLocation(`/patients/${patientId}/`);
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: "/patients/", label: "Patients" },
          { to: `/patients/${patientId}/`, label: patient?.name ?? "" },
          { label: "Create Appointment Credit" },
        ]}
        title={`New Appointment Credit - ${patient?.name ?? ""}`}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <DateField
                fullWidth
                label="Active From"
                name="activeFrom"
                required
              />
              <DateField fullWidth label="Active To" name="activeTo" required />
              <SelectField
                fullWidth
                label="Appointment Type"
                name="appointmentType"
                options={Object.values(AppointmentType).map((type) => ({
                  value: type,
                }))}
                required
              />
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Create" />
              <FormLinks
                links={[{ label: "Back", to: `/patients/${patientId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateAppointmentCredit;
