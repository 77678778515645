import { z } from "zod";

export enum Diagnosis {
  ANXIETY = "ANXIETY",
  CHRONIC_PAIN = "CHRONIC_PAIN",
  CLUSTER_HEADACHES = "CLUSTER_HEADACHES",
  COMPLEX_REGIONAL_PAIN_SYNDROME = "COMPLEX_REGIONAL_PAIN_SYNDROME",
  CROHNS = "CROHNS",
  AGORAPHOBIA = "AGORAPHOBIA",
  AUTISTIC_SPECTRUM_DISORDER = "AUTISTIC_SPECTRUM_DISORDER",
  ADHD = "ATTENTION_DEFICIT_HYPERACTIVITY_DISORDER",
  BREAST_PAIN = "BREAST_PAIN",
  CANCER_PAIN = "CANCER_PAIN",
  CHEMOTHERAPY_INDUCED_NAUSEA_AND_VOMITING = "CHEMOTHERAPY_INDUCED_NAUSEA_AND_VOMITING",
  DEPRESSION = "DEPRESSION",
  EATING_DISORDER = "EATING_DISORDER",
  EHLERS_DANLOS = "EHLERS_DANLOS",
  ENDOMETRIOSIS = "ENDOMETRIOSIS",
  EPILEPSY_ADULT = "EPILEPSY_ADULT",
  EPILEPSY_CHILD = "EPILEPSY_CHILD",
  FIBROMYALGIA = "FIBROMYALGIA",
  HEADACHE = "HEADACHE",
  INSOMNIA = "INSOMNIA",
  INFLAMMATORY_ARTHRITIS = "INFLAMMATORY_ARTHRITIS",
  MIGRAINE = "MIGRAINE",
  MULTIPLE_SCLEROSIS = "MULTIPLE_SCLEROSIS",
  NEUROPATHIC_PAIN = "NEUROPATHIC_PAIN",
  OCD = "OCD",
  OSTEOARTHRITIS = "OSTEOARTHRITIS",
  PALLIATIVE_CARE = "PALLIATIVE_CARE",
  PANIC_DISORDER = "PANIC_DISORDER",
  PARKINSONS = "PARKINSONS",
  PTSD = "PTSD",
  RARE_AND_CHALLENGING_SKIN_CONDITION = "RARE_AND_CHALLENGING_SKIN_CONDITION",
  SOCIAL_PHOBIA = "SOCIAL_PHOBIA",
  TRIGEMINAL_NEURALGIA = "TRIGEMINAL_NEURALGIA",
  TOURETTES_SYNDROME = "TOURETTES_SYNDROME",
  ULCERATIVE_COLITIS = "ULCERATIVE_COLITIS",
}

const specialityGroupSchema = z.object({
  id: z.number().int().positive(),
  clinicians: z.array(z.string().uuid()),
  complexClinicians: z.array(z.string().uuid()),
  specialityName: z.string(),
  associatedDiagnoses: z.array(z.nativeEnum(Diagnosis)),
});

export type SpecialityGroup = z.infer<typeof specialityGroupSchema>;

export const newSpecialityGroup = (data: unknown): SpecialityGroup =>
  specialityGroupSchema.parse(data);
