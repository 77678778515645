import { ToastContext, Title } from "@curaleaf-international/components";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";
import { useLocation } from "wouter";

import ClinicianForm, {
  IAppointmentTypeRecord,
  ValidatedType,
} from "src/components/ClinicianForm";
import { AppointmentType, ClinicianType, FollowUpPreference } from "src/models";
import {
  useClinicianQuery,
  useEditClinicianMutation,
} from "src/queries/clinicians";

interface IProps {
  clinicianId: string;
}

const EditClinician = ({ clinicianId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const { mutateAsync: editClinician } = useEditClinicianMutation(clinicianId);

  const types = Object.keys(AppointmentType);

  const onSubmit = async (data: ValidatedType) => {
    const appointmentTypes = types.filter(
      (type) => data.appointmentTypes[type],
    );
    try {
      await editClinician({
        appointmentTypes: appointmentTypes as AppointmentType[],
        clinicianType: data.clinicianType,
        minimumHoursBeforeBooking: data.minimumHoursBeforeBooking,
        name: data.name,
        followUpPreference:
          data.clinicianType === ClinicianType.CONSULTANT
            ? data.followUpPreference
            : FollowUpPreference.NOT_APPLICABLE,
        autoApprovalLetters: data.autoApprovalLetters,
        staffId: parseInt(data.staffId),
        specialisms: data.specialisms,
      });
      addToast("Clinician updated", "success");
      setLocation(`/clinicians/${clinicianId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  if (clinician === undefined) {
    return (
      <Box
        sx={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }
  return (
    <>
      <Title
        title="Edit Clinician"
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician.name}`, to: `/clinicians/${clinicianId}/` },
          { label: "Edit Clinician" },
        ]}
      />
      <ClinicianForm
        initialValues={{
          appointmentTypes: {
            ...types.reduce((accum: IAppointmentTypeRecord, type) => {
              accum[type] =
                clinician.appointmentTypes.includes(type as AppointmentType) ??
                false;
              return accum;
            }, {}),
          },
          clinicianType: clinician.clinicianType,
          minimumHoursBeforeBooking: clinician.minimumHoursBeforeBooking,
          name: clinician.name,
          followUpPreference: clinician.followUpPreference ?? false,
          autoApprovalLetters: clinician.autoApprovalLetters,
          specialisms: clinician.specialisms,
          staffId: clinician.staffId.toString(),
        }}
        submitLabel="Edit Clinician"
        back={`/clinicians/${clinicianId}/`}
        types={types}
        onSubmit={onSubmit}
        editing={true}
      />
    </>
  );
};

export default EditClinician;
