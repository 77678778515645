import {
  SkeletonRow,
  Value,
  formatDate,
  sortByKey,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { Itinerary, Prescription } from "src/models";
import {
  usePatientQuery,
  usePrescriberByClinicianIdQuery,
  useTravelLetterPrescriptionsQuery,
  useTravelLetterQuery,
} from "src/queries";

interface IProps {
  letterId: string;
}

const TravelLetterDetails = ({ letterId }: IProps) => {
  const { data: letter } = useTravelLetterQuery(letterId);
  const { data: patient } = usePatientQuery(letter?.patientId);
  const { data: prescriber } = usePrescriberByClinicianIdQuery(
    patient?.assignedConsultantId,
  );
  const { data: prescriptions } = useTravelLetterPrescriptionsQuery(letterId);

  const uniquePrescriberIds = new Set();

  return (
    <>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Details" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Assigned to</TableCell>
                <TableCell>{letter?.assignedToEmail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Downloadable</TableCell>
                <TableCell>
                  <Value bool={letter?.letterPdfPresent} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Signatures missing for generation</TableCell>
                <TableCell>
                  <List>
                    {prescriptions
                      ?.filter((p) => {
                        if (
                          !p.prescriberSignaturePresent &&
                          !uniquePrescriberIds.has(p.prescriberId)
                        ) {
                          uniquePrescriberIds.add(p.prescriberId);
                          return true;
                        }
                        return false;
                      })
                      .map((p) => (
                        <ListItem key={p.prescriberId}>
                          <Value
                            link={{
                              label: p.prescriberEmail,
                              to: `/prescribers/${p.prescriberId}/`,
                            }}
                          />
                        </ListItem>
                      ))}
                  </List>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Patient Name</TableCell>
                <TableCell>
                  <Value
                    link={
                      patient !== undefined
                        ? {
                            label: `${patient.title} ${patient.name}`,
                            to: `/patients/${patient.id}/`,
                          }
                        : undefined
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date of Birth</TableCell>
                <TableCell>
                  <Value date={patient?.dob} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>
                  <Value text={patient?.addressLine1} />
                  {", "}
                  <Value
                    text={
                      patient?.addressLine2 !== null
                        ? patient?.addressLine2
                        : undefined
                    }
                  />
                  {", "}
                  <Value text={patient?.postcode} />
                  <Value text={patient?.city} />
                  {", "}
                  <Value text={patient?.country} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Itinerary" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Arrival</TableCell>
                <TableCell>Departure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {letter?.itinerary
                .sort(sortByKey((destination) => [destination.arrival], "asc"))
                .map((part: Itinerary) => (
                  <TableRow key={part.destination}>
                    <TableCell>{part.destination}</TableCell>
                    <TableCell>{formatDate(part.arrival)}</TableCell>
                    <TableCell>{formatDate(part.departure)}</TableCell>
                  </TableRow>
                )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Prescriptions" />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Last Printed</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptions ? (
                prescriptions.map((prescription: Prescription) => (
                  <TableRow key={prescription.id}>
                    <TableCell>{prescription.productName}</TableCell>
                    <TableCell>{formatDate(prescription.issueDate)}</TableCell>
                    <TableCell>{prescription.quantity}</TableCell>
                  </TableRow>
                ))
              ) : (
                <SkeletonRow cols={3} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="Clinician" />
        <Divider />
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Clinician Name</TableCell>
                <TableCell>
                  <Link
                    component={WLink}
                    href={`/prescribers/${prescriber?.id}/`}
                  >
                    {prescriber?.fullTitle}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Registration</TableCell>
                <TableCell>{prescriber?.registrationNumber}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TravelLetterDetails;
