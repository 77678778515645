import { ToastContext, Title } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import SpecialityGroupForm, {
  ValidatedType,
} from "src/components/SpecialityGroupForm";
import { Diagnosis } from "src/models";
import {
  useEditSpecialityGroupMutation,
  useSpecialityGroupQuery,
} from "src/queries";

interface IProps {
  specialityGroupId: number;
}

const EditSpecialityGroup = ({ specialityGroupId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: group } = useSpecialityGroupQuery(specialityGroupId);
  const { mutateAsync: editGroup } =
    useEditSpecialityGroupMutation(specialityGroupId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await editGroup({
        associatedDiagnoses: data.associatedDiagnoses as Diagnosis[],
        clinicians: data.clinicians,
        complexClinicians: data.complexClinicians,
        specialityName: data.specialityName,
      });
      setLocation(`/speciality-groups/${specialityGroupId}/`);
      addToast("Speciality Group Updated", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code == "DUPLICATE_SPECIALITY_NAME"
      ) {
        addToast("Speciality Group Name Already In Use", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code == "DIAGNOSIS_ALREADY_ASSOCIATED"
      ) {
        addToast("One Or More Diagnosis Already Assigned To Group", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Update Speciality Group"
        breadcrumbs={[
          { label: "Speciality Groups", to: "/speciality-groups/" },
          {
            label: group?.specialityName ?? "",
            to: `/speciality-groups/${specialityGroupId}/`,
          },
          { label: "Edit Speciality Group" },
        ]}
      />
      <SpecialityGroupForm
        initialValues={{
          associatedDiagnoses: group?.associatedDiagnoses ?? [],
          complexClinicians: group?.complexClinicians ?? [],
          clinicians: group?.clinicians ?? [],
          specialityName: group?.specialityName ?? "",
        }}
        onSubmit={onSubmit}
        back="/speciality-groups/"
        submitLabel="Edit Group"
      />
    </>
  );
};

export default EditSpecialityGroup;
