import { Children, cloneElement, isValidElement, useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { Role } from "src/models";

interface IProps {
  roles: Role[];
  children?: React.ReactNode;
  [extraProps: string]: any;
}

const RequiresRoles = ({ roles, children, ...props }: IProps) => {
  const { member } = useContext(AuthContext);
  return member?.roles.some((role) => roles.includes(role))
    ? Children.map(children, (child) =>
        isValidElement(child) ? cloneElement(child, props) : child,
      )
    : null;
};

export default RequiresRoles;
