import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { Link as WLink } from "wouter";

import OutOfStockListingTable from "src/pages/OutOfStockListings/OutOfStockListingTable";

type States = "active" | "historic";

const OutOfStockListings = () => {
  const [tab, setTab] = useHash<States>("active");

  return (
    <>
      <Title
        actions={
          <Button
            component={WLink}
            to="/out-of-stock-listings/new/"
            variant="contained"
          >
            Add New Listing
          </Button>
        }
        title="Out of Stock Listings"
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as States)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Active" value="active" />
            <Tab label="Historic" value="historic" />
          </TabList>
          <Divider />
          <OutOfStockListingTable active={tab === "active"} />
        </TabContext>
      </Card>
    </>
  );
};

export default OutOfStockListings;
