import { formatDateTime } from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { Appointment } from "src/models/appointment";

interface IProps {
  appointment: Appointment;
}

const AppointmentRow = ({ appointment }: IProps) => {
  return (
    <TableRow>
      <TableCell>
        <Link component={WLink} to={`/appointments/${appointment.id}/`}>
          {appointment.id}
        </Link>
      </TableCell>
      <TableCell>
        <Link component={WLink} to={`/patients/${appointment.patientId}/`}>
          {appointment.patientName}
        </Link>
      </TableCell>
      <TableCell>{formatDateTime(appointment.startAt)}</TableCell>
      <TableCell>
        <Link component={WLink} to={`/clinicians/${appointment.clinicianId}/`}>
          {appointment.clinicianName}
        </Link>
      </TableCell>
      <TableCell>{appointment.length} minutes</TableCell>
      <TableCell sx={{ textTransform: "capitalize" }}>
        {appointment.status} <br />{" "}
        {formatDateTime(appointment.currentStateTimestamp)}
      </TableCell>
      <TableCell>{appointment.type}</TableCell>
    </TableRow>
  );
};

export default AppointmentRow;
