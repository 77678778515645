import * as z from "zod";

import { Diagnosis } from "src/models/specialityGroup";

export enum PatientFollowUpPreference {
  STANDARD_GROUP_PHARMACIST_OR_GP = "STANDARD_GROUP_PHARMACIST_OR_GP",
  MENTAL_HEALTH_PHARMACIST_OR_GP = "MENTAL_HEALTH_PHARMACIST_OR_GP",
  ASSIGNED_CONSULTANT = "ASSIGNED_CONSULTANT",
  ASSIGNED_PHARMACIST_OR_GP = "ASSIGNED_PHARMACIST_OR_GP",
}

const patientSchema = z.object({
  id: z.string().uuid(),
  assignedConsultantId: z.string().uuid().nullable(),
  assignedConsultantName: z.string().nullable(),
  assignedPharmacistOrGpId: z.string().uuid().nullable(),
  assignedPharmacistOrGpName: z.string().nullable(),
  addressLine1: z.string(),
  addressLine2: z.string().nullable(),
  canEmail: z.boolean(),
  carerEmail: z.string().nullable(),
  city: z.string(),
  country: z.string(),
  createdAt: z.coerce.date(),
  dob: z.coerce.date(),
  email: z.string(),
  firebaseUid: z.string(),
  gender: z.string().nullable(),
  hasEmailIssues: z.boolean(),
  name: z
    .string()
    .transform((name) =>
      name.replace(/\b\w/g, (char: string) => char.toUpperCase()),
    ),
  nhsNumber: z.string().nullable(),
  patientFollowupPreference: z.nativeEnum(PatientFollowUpPreference),
  postcode: z.string(),
  primaryDiagnosis: z.nativeEnum(Diagnosis).nullable(),
  title: z.string(),
});

export type Patient = z.infer<typeof patientSchema>;

export const newPatient = (data: unknown): Patient => patientSchema.parse(data);
