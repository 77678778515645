import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useEffect } from "react";
import { useLocation } from "wouter";

import { Clinician } from "src/models/clinician";
import AppointmentsTable from "src/pages/Appointments/AppointmentsTable";
import AppointmentsCalendar from "src/pages/Appointments/Calendar";
import { useCliniciansQuery } from "src/queries/clinicians";

type TabState = "list" | "calendar";

const Appointments = () => {
  const { data: clinicians } = useCliniciansQuery();
  const [_, setLocation] = useLocation();
  const [tab, setTab] = useHash<TabState>("list");

  const clinicianOptions: { label: string; value: string }[] = [];
  if (clinicians !== undefined) {
    clinicians
      .sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if (nameA.startsWith("dr ")) {
          nameA = nameA.slice(3);
        }
        if (nameB.startsWith("dr ")) {
          nameB = nameB.slice(3);
        }
        return nameA.localeCompare(nameB);
      })
      .map((clinician: Clinician) => {
        clinicianOptions.push({
          label: clinician.name,
          value: clinician.id.toString(),
        });
      });
  }

  useEffect(() => {
    setLocation(`/appointments/#${tab}`, { replace: true });
  }, [tab]);

  return (
    <>
      <Title title="Appointments" />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="Appointments menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="List View" value="list" />
            <Tab label="Calendar View" value="calendar" />
          </TabList>
        </Box>
        <TabPanel value="list">
          <AppointmentsTable clinicians={clinicianOptions} />
        </TabPanel>
        <TabPanel value="calendar">
          <AppointmentsCalendar clinicians={clinicianOptions} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Appointments;
