import { ToastContext, Title } from "@curaleaf-international/components";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";
import { useLocation } from "wouter";

import PrescriberForm, { ValidatedType } from "src/components/PrescriberForm";
import {
  usePrescriberQuery,
  useEditPrescriberMutation,
} from "src/queries/prescribers";

interface IProps {
  prescriberId: string;
}

const EditPrescriber = ({ prescriberId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: prescriber } = usePrescriberQuery(prescriberId);
  const { mutateAsync: editPrescriber } =
    useEditPrescriberMutation(prescriberId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await editPrescriber(data);
      addToast("Clinician updated", "success");
      setLocation(`/prescribers/${prescriberId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  if (prescriber === undefined) {
    return (
      <Box
        sx={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }
  return (
    <>
      <Title
        title="Edit Clinician"
        breadcrumbs={[
          { label: "Prescribers", to: "/prescribers/" },
          {
            label: `${prescriber.fullTitle}`,
            to: `/prescribers/${prescriberId}/`,
          },
          { label: "Edit Clinician" },
        ]}
      />
      <PrescriberForm
        initialValues={{
          clinicianId: prescriber.clinicianId,
          fullTitle: prescriber.fullTitle,
          pin: prescriber.pin,
          prescriberType: prescriber.prescriberType,
          registrationNumber: prescriber.registrationNumber,
          specialism: prescriber.specialism,
        }}
        submitLabel="Edit Prescriber"
        onSubmit={onSubmit}
        back={`/prescribers/${prescriberId}/`}
      />
    </>
  );
};

export default EditPrescriber;
