import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import { AppointmentType } from "src/models/appointment";
import {
  ClinicianRecurringAvailability,
  RecurringAvailabilityType,
  newClinicianRecurringAvailability,
} from "src/models/clinicianRecurringAvailability";
import { useQuery, useMutation } from "src/query";

export interface IClinicianRecurringAvailabilityData {
  appointmentTypes: AppointmentType[];
  clinicianId: string;
  dayEnd: Date;
  dayStart: Date;
  endAt: Date | null;
  recurringType: RecurringAvailabilityType;
  startAt: Date;
}

export const useClinicianRecurringAvailabilityQuery = (clinicianId: string) => {
  return useQuery<ClinicianRecurringAvailability[]>({
    queryKey: ["clinicianRecurringAvailability", clinicianId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/clinician-recurring-availability/${clinicianId}/`,
      );
      return response.data.availability.map((data: unknown) =>
        newClinicianRecurringAvailability(data),
      );
    },
    enabled: clinicianId !== undefined,
  });
};

export const useCreateClinicianRecurringAvailabilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianRecurringAvailabilityData) =>
      await axios.post(`/v1/clinician-recurring-availability/`, {
        appointmentTypes: data.appointmentTypes,
        clinicianId: data.clinicianId,
        dayEnd: formatISO(data.dayEnd),
        dayStart: formatISO(data.dayStart),
        endAt: data.endAt
          ? formatISO(data.endAt, { representation: "date" })
          : null,
        recurringType: data.recurringType,
        startAt: formatISO(data.startAt),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["clinicianRecurringAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

export interface IEditRecurringAvailabilityData {
  appointmentTypes: AppointmentType[];
  endAt: Date | null;
  dayEnd: Date;
  recurringType: RecurringAvailabilityType;
  startAt: Date;
  dayStart: Date;
}

export const useEditClinicianRecurringAvailabilityMutation = (
  recurringAvailabilityId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEditRecurringAvailabilityData) =>
      await axios.put(
        `/v1/clinician-recurring-availability/${recurringAvailabilityId}/edit/`,
        {
          appointmentTypes: data.appointmentTypes,
          endAt: data.endAt
            ? formatISO(data.endAt, { representation: "date" })
            : null,
          dayEnd: formatISO(data.dayEnd),
          recurringType: data.recurringType,
          startAt: formatISO(data.startAt),
          dayStart: formatISO(data.dayStart),
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["clinicianRecurringAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

export const useDeactivateClinicianRecurringAvailabilityMutation = (
  recurringAvailabilityId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(
        `/v1/clinician-recurring-availability/${recurringAvailabilityId}/deactivate/`,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["clinicianRecurringAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};
