import { Title, ToastContext } from "@curaleaf-international/components";
import { addDays } from "date-fns";
import { useContext } from "react";
import { useLocation } from "wouter";

import TravelLetterForm, {
  ValidatedType,
} from "src/components/TravelLetterForm";
import { useCreateTravelLetterMutation, usePatientQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const CreateTravelLetter = ({ patientId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createTravelLetter } = useCreateTravelLetterMutation();
  const { data: patient } = usePatientQuery(patientId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      const letterId = await createTravelLetter({
        ...data,
        patientId,
      });
      addToast("Travel letter created", "success");
      setLocation(`/travel-letters/${letterId.id}/`);
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: "/travel-letters/", label: "All Travel Letters" },
          { label: "Create Travel Letter" },
        ]}
        title={`New Letter - ${patient?.name ?? ""}`}
      />
      <TravelLetterForm
        back={`/patients/${patientId}/#travel-letters`}
        initialValues={{
          itinerary: [
            {
              destination: "",
              arrival: new Date(),
              departure: addDays(new Date(), 1),
            },
          ],
          prescriptions: [],
        }}
        label="Create"
        onSubmit={onSubmit}
        patientId={patientId}
      />
    </>
  );
};

export default CreateTravelLetter;
