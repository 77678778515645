import axios from "axios";

import { Email, newEmail } from "src/models";
import { useQuery } from "src/query";

export const useAppointmentEmailsQuery = (appointmentId: string) =>
  useQuery<Email[]>({
    queryKey: ["appointmentEmails", appointmentId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-emails/appointment/${appointmentId}/`,
      );

      return response.data.emails.map((data: unknown) => newEmail(data));
    },
  });
