import { Title, Search, useDebounce } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ChangeEvent, useState } from "react";
import { Link } from "wouter";

import PrescribersTable from "src/pages/Prescribers/PrescribersTable";

const Prescribers = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);

  return (
    <>
      <Title
        actions={
          <Button component={Link} to={`/prescribers/new/`} variant="contained">
            New
          </Button>
        }
        title="Prescribers"
      />
      <Card>
        <CardContent>
          <Search
            fullWidth
            label="Search"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setRawSearch(event.target.value)
            }
            value={rawSearch}
          />
        </CardContent>
        <PrescribersTable search={search} />
      </Card>
    </>
  );
};

export default Prescribers;
