import {
  SubmitButton,
  FormLinks,
  DateField,
  SelectField,
  sortByKey,
  formatDate,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { addDays, isAfter, startOfDay, subWeeks } from "date-fns";
import { useEffect } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import * as z from "zod";

import DestinationField from "src/components/DestinationField";
import { Prescription } from "src/models";
import { usePatientTravelLetterPrescriptionsQuery } from "src/queries";

const FormSchema = z.object({
  itinerary: z
    .array(
      z
        .object({
          arrival: z.coerce.date(),
          departure: z.coerce.date(),
          destination: z.string().min(4),
        })
        .refine(
          (itinerary) => isAfter(itinerary.departure, itinerary.arrival),
          {
            path: ["departure"],
            message: "Departure date must be after arrival",
          },
        ),
    )
    .min(1)
    .superRefine((itinerary, ctx) => {
      const uniqueCombinations = new Set();
      for (let index = 0; index < itinerary.length; index++) {
        const item = itinerary[index];
        const combination = `${item.destination}-${item.arrival.toISOString()}-${item.departure.toISOString()}`;
        if (uniqueCombinations.has(combination)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message:
              "Itinerary items must have unique combinations of destination, arrival and departure",
            path: [`${index}.destination`],
          });
        }
        uniqueCombinations.add(combination);
      }
    }),
  prescriptions: z.array(z.string()).min(1),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  back: string;
  disabled?: boolean;
  initialValues: FormType;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<any>;
  patientId?: string;
}

const TravelLetterForm = ({
  back,
  disabled,
  initialValues,
  label,
  onSubmit,
  patientId,
}: IProps) => {
  const { data: prescriptions } =
    usePatientTravelLetterPrescriptionsQuery(patientId);

  const sortKey = (staff: Prescription) => {
    return [staff.issueDate];
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => methods.reset(initialValues), [initialValues]);

  const options =
    prescriptions
      ?.filter((prescription) => {
        return (
          isAfter(
            prescription.issueDate,
            startOfDay(subWeeks(new Date(), 14)),
          ) || initialValues.prescriptions.includes(prescription.id)
        );
      })
      .sort(sortByKey(sortKey, "desc"))
      .map((prescription) => ({
        label: `${prescription.productName} | Last print date ${formatDate(prescription.issueDate)}`,
        value: prescription.id,
      })) ?? [];

  const {
    fields: itemFields,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    name: "itinerary",
    control: methods.control,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <SelectField
              fullWidth
              label="Prescriptions"
              name="prescriptions"
              options={options}
              multiple={true}
              required
            />
            <List>
              {itemFields.map((item, index) => (
                <ListItem
                  key={item.id}
                  secondaryAction={
                    <IconButton
                      disabled={itemFields.length <= 1}
                      edge="end"
                      onClick={() => removeItem(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <>
                        <DestinationField
                          fullWidth
                          label="Country"
                          name={`itinerary[${index}].destination`}
                          required
                        />
                        <DateField
                          name={`itinerary[${index}].arrival`}
                          label="Arrival"
                          required
                          fullWidth
                        />
                        <DateField
                          name={`itinerary[${index}].departure`}
                          label="Departure"
                          required
                          fullWidth
                        />
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Button
              onClick={() =>
                appendItem({
                  destination: "",
                  arrival: new Date(),
                  departure: addDays(new Date(), 1),
                })
              }
              type="button"
              variant="outlined"
            >
              Add destination
            </Button>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton disabled={disabled} label={label} />
            <FormLinks links={[{ label: "Back", to: back }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default TravelLetterForm;
