import axios from "axios";

import { AppointmentType } from "src/models/appointment";
import {
  AppointmentSlot,
  newAppointmentSlot,
} from "src/models/appointmentSlot";
import { useQuery } from "src/query";

export enum ClinicianTypeFilter {
  CONSULTANTS = "CONSULTANTS",
  PHARMACISTS_AND_GPS = "PHARMACISTS_AND_GPS",
}

export const useAppointmentSlotsQuery = (
  appointmentType: AppointmentType,
  clinicianTypeFilter: ClinicianTypeFilter,
  patientId: string | undefined,
) => {
  return useQuery<AppointmentSlot[]>({
    queryKey: [
      "appointmentSlots",
      appointmentType,
      clinicianTypeFilter,
      patientId,
    ],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-slots/patient/${patientId}/`,
        {
          params: { appointmentType, clinicianTypeFilter },
        },
      );
      return response.data.slots.map((data: unknown) =>
        newAppointmentSlot(data),
      );
    },
    enabled: patientId !== undefined,
  });
};
