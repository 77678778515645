import {
  formatDateTime,
  formatCurrency,
  Title,
  SubmitButton,
  ToastContext,
  FormLinks,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { addMinutes } from "date-fns";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink, useLocation } from "wouter";

import {
  useAppointmentQuery,
  useReleaseAppointmentMutation,
} from "src/queries";

interface IProps {
  appointmentId: string;
}

const ReleaseAppointment = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { mutateAsync: releaseAppointment } =
    useReleaseAppointmentMutation(appointmentId);
  const methods = useForm();

  const onSubmit = async () => {
    try {
      await releaseAppointment();
      setLocation(`/appointments/${appointmentId}/`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        addToast("Cannot find appointment", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title title="Release Appointment" />
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>
                    {appointment !== undefined
                      ? formatCurrency(appointment.price)
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Patient</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      to={`/patients/${appointment?.patientId}/`}
                    >
                      {appointment?.patientName}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Clinician</TableCell>
                  <TableCell>{appointment?.clinicianName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {appointment?.type.toLowerCase().replace("_", "-")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start</TableCell>
                  <TableCell>
                    {appointment !== undefined
                      ? formatDateTime(appointment.startAt)
                      : null}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End</TableCell>
                  <TableCell>
                    {appointment !== undefined
                      ? formatDateTime(
                          addMinutes(appointment.startAt, appointment.length),
                        )
                      : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Divider />
            <CardActions>
              <SubmitButton label="Release Appointment" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/appointments/${appointmentId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};
export default ReleaseAppointment;
