import {
  default as MuiTableSortLabel,
  TableSortLabelOwnProps,
} from "@mui/material/TableSortLabel";

import { PaginationSettings } from "src/pagination";

interface IProps<RowType>
  extends Omit<TableSortLabelOwnProps, "active" | "direction" | "onClick"> {
  columnName: keyof RowType;
  paginationSettings: PaginationSettings<RowType>;
  toggleSort: (sortColumn: keyof RowType) => void;
}

const TableSortLabel = <RowType,>({
  columnName,
  paginationSettings,
  toggleSort,
  ...props
}: IProps<RowType>) => {
  return (
    <MuiTableSortLabel
      {...props}
      active={paginationSettings.sortColumn === columnName}
      direction={paginationSettings.sortDirection}
      onClick={() => toggleSort(columnName)}
    />
  );
};

export default TableSortLabel;
