import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { Clinician } from "src/models";
import {
  useCliniciansBySpecialityGroupQuery,
  useSpecialityGroupQuery,
} from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

type Direction = "asc" | "desc";
type OrderableProperties = "name";

interface IProps {
  specialityGroupId: number;
}

const SpecialityGroupClinicians = ({ specialityGroupId }: IProps) => {
  const [sortDirection, setSortDirection] = useState<Direction>("asc");
  const [sortBy, setSortBy] = useState<OrderableProperties>("name");
  const { data: group } = useSpecialityGroupQuery(specialityGroupId);
  const { data: clinicians } =
    useCliniciansBySpecialityGroupQuery(specialityGroupId);

  const sortKey = (product: Clinician) => [product[sortBy]];
  const sortedClinicians = clinicians?.sort(sortByKey(sortKey, sortDirection));

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "name"}
                  direction={sortDirection}
                  onClick={onSortClick("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Is Specialist Clinician</TableCell>
              <TableCell>Appointment Types</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedClinicians?.map((clinician) => (
              <TableRow key={clinician.id}>
                <TableCell>
                  <Link
                    width={"100%"}
                    component={WLink}
                    to={`/clinicians/${clinician.id}/`}
                  >
                    {clinician.name}
                  </Link>
                </TableCell>
                <TableCell>{clinician.clinicianType}</TableCell>
                <TableCell>
                  {group?.complexClinicians.includes(clinician.id)
                    ? "Yes"
                    : "No"}
                </TableCell>
                <TableCell>
                  {clinician.appointmentTypes
                    .map((appointmentType) =>
                      convertEnumValueToReadableString(appointmentType, "-"),
                    )
                    .join(", ")}
                </TableCell>
              </TableRow>
            )) ?? <SkeletonRow cols={3} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SpecialityGroupClinicians;
