import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { StaffEmail } from "src/models";
import StaffEmailRow from "src/pages/StaffMember/StaffEmailRow";
import { useStaffQuery, useStaffEmailsQuery } from "src/queries";

interface IProps {
  staffId: number;
}

const StaffEmails = ({ staffId }: IProps) => {
  const { data: staff } = useStaffQuery(staffId);
  const { data: staffEmails } = useStaffEmailsQuery(staffId);

  return (
    <>
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sent</TableCell>
                <TableCell>Message Type</TableCell>
                <TableCell>Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staff === undefined || staffEmails === undefined ? (
                <SkeletonRow cols={3} />
              ) : staffEmails.emails.length === 0 ? (
                <TableRow>
                  <TableCell>No emails found.</TableCell>
                </TableRow>
              ) : (
                staffEmails.emails.map((email: StaffEmail) => (
                  <StaffEmailRow
                    key={`${email.id}${email.sent}`}
                    email={email}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default StaffEmails;
