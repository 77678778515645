import {
  sortByKey,
  useDebounce,
  MultiSelect,
  SkeletonRow,
  Search,
} from "@curaleaf-international/components";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Fuse from "fuse.js";
import { ChangeEvent, useState } from "react";

import { OutOfStockListing } from "src/models";
import OutOfStockListingTableRow from "src/pages/OutOfStockListings/OutOfStockListingTableRow";
import { useOutOfStockListingsQuery, useProductsQuery } from "src/queries";

interface IProps {
  active: boolean;
}

const OutOfStockListingTable = ({ active }: IProps) => {
  const { data: listings } = useOutOfStockListingsQuery(active);
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const { data: products } = useProductsQuery(false);
  const [productFilters, setProductFilters] = useState<string[]>([]);

  const filterOutOfStockListings = (listings: OutOfStockListing[]) => {
    if (search !== "") {
      const fuse = new Fuse(listings, {
        keys: ["productName"],
      });
      return fuse.search(search).map((value) => value.item);
    }
    return listings.sort(
      sortByKey((listing) => [listing.createdOn, listing.createdOn], "desc"),
    );
  };

  return (
    <>
      <CardContent>
        <Search
          fullWidth
          label="Search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setRawSearch(event.target.value)
          }
          value={rawSearch}
          sx={{ marginBottom: 2 }}
        />
        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
        <Stack direction="row" spacing={1}>
          {productFilters.length > 0 ? (
            productFilters.map((filter) => (
              <Chip
                key={filter}
                label={products?.find((product) => product.id === filter)?.name}
                onDelete={() =>
                  setProductFilters(
                    productFilters.filter((item) => item !== filter),
                  )
                }
              />
            ))
          ) : (
            <Typography
              sx={{ lineHeight: "32px", marginLeft: 1 }}
              variant="body1"
            >
              No filters
            </Typography>
          )}
        </Stack>
        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
        <MultiSelect
          label="Alternative products"
          onChange={(value: string[]) => setProductFilters(value)}
          options={Object.values(products ?? {})
            .sort(sortByKey((product) => [product.name], "asc"))
            .map((product) => ({
              label: product.name,
              value: product.id,
            }))}
          value={productFilters}
        />
      </CardContent>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Ended</TableCell>
              <TableCell>Has patient message</TableCell>
              <TableCell>Available Options</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings === undefined ? (
              <SkeletonRow cols={6} />
            ) : (
              filterOutOfStockListings(Object.values(listings))
                .filter((listing) => {
                  if (productFilters.length === 0) return true;
                  return listing.alternativeProducts.some(
                    (alternativeProductId) =>
                      productFilters.includes(alternativeProductId),
                  );
                })
                .map((listing) => (
                  <OutOfStockListingTableRow
                    key={listing.id}
                    listing={listing}
                  />
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OutOfStockListingTable;
