import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  children: ReactNode;
  title: string;
}

const Page = ({ children, title }: IProps) => (
  <>
    <Helmet>
      <title>{title} - Curaleaf Clinic</title>
    </Helmet>
    <Typography component="h1" sx={{ marginBottom: 2 }} variant="h4">
      {title}
    </Typography>
    {children}
  </>
);

export default Page;
