import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { addDays, differenceInMinutes, isPast, startOfDay } from "date-fns";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AppointmentStatus } from "src/models";
import AppointmentDetails from "src/pages/Appointment/AppointmentDetails";
import AppointmentEmails from "src/pages/Appointment/AppointmentEmails";
import AppointmentHistory from "src/pages/Appointment/AppointmentHistory";
import AppointmentPaymentsTable from "src/pages/Appointment/AppointmentPaymentsTable";
import { useAppointmentsPaymentsQuery } from "src/queries";
import { useAppointmentQuery } from "src/queries/appointments";

type TabState = "details" | "history" | "emails" | "payments";
interface IProps {
  appointmentId: string;
}

const Appointment = ({ appointmentId }: IProps) => {
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { data: appointment_payments } =
    useAppointmentsPaymentsQuery(appointmentId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (appointment === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/book/`}
          disabled={
            appointment.status !== AppointmentStatus.PENDING ||
            differenceInMinutes(appointment.bookedOn, new Date()) >= 29
          }
        >
          {appointment.price.isZero() ? "Confirm Appointment" : "Add Payment"}
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/import-payment/`}
          disabled={
            appointment.status !== AppointmentStatus.PENDING ||
            appointment.price.isZero() ||
            (appointment_payments && appointment_payments?.length > 0)
          }
        >
          Import Payment
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/edit/`}
          disabled={
            appointment.status !== AppointmentStatus.CONFIRMED ||
            isPast(addDays(startOfDay(appointment.startAt), 1))
          }
        >
          Edit Appointment
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/release/`}
          disabled={appointment.status !== AppointmentStatus.PENDING}
        >
          Release Appointment
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/alter-price/`}
          disabled={appointment.price.isZero()}
        >
          Alter Price
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointments/${appointmentId}/cancel/`}
          disabled={
            appointment.status !== AppointmentStatus.CONFIRMED ||
            isPast(appointment.startAt)
          }
        >
          Cancel Appointment
        </MenuItem>
      </Menu>
      <Title
        title={`Appointment - ${appointment.patientName}`}
        breadcrumbs={[
          { label: "Appointments", to: "/appointments/" },
          { label: `Appointment - ${appointment.patientName}` },
        ]}
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="Appointment menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="Details" value="details" />
            <Tab label="History" value="history" />
            <Tab label="Emails" value="emails" />
            <Tab label="Payments" value="payments" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <AppointmentDetails appointment={appointment} />
        </TabPanel>
        <TabPanel value="history">
          <AppointmentHistory appointmentId={appointment.id} />
        </TabPanel>
        <TabPanel value="emails">
          <AppointmentEmails appointmentId={appointment.id} />
        </TabPanel>
        <TabPanel value="payments">
          <AppointmentPaymentsTable appointmentId={appointment.id} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Appointment;
