import {
  CheckboxField,
  SubmitButton,
  TextField,
  ToastContext,
  FormLinks,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { Role } from "src/models";
import { useStaffQuery, useUpdateStaffRolesMutation } from "src/queries";

interface IRoles {
  [key: string]: boolean | string;
}

interface IProps {
  staffId: number;
}

const FormSchema = z.object({
  roles: z.record(z.string(), z.union([z.boolean(), z.string()])),
  reason: z.string().min(5),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditStaffMember = ({ staffId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: staff } = useStaffQuery(staffId);
  const { mutateAsync: updateMember } = useUpdateStaffRolesMutation(staffId);

  const roles = Object.keys(Role);

  const initialValues = {
    roles: {
      ...roles.reduce((accum: IRoles, role) => {
        accum[role] = staff?.roles.includes(role as Role) ?? false;
        return accum;
      }, {}),
    },
    reason: "",
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  useEffect(() => methods.reset(initialValues), [methods, staff]);

  const onSubmit = async (data: ValidatedType) => {
    const newRoles = roles.filter((role) => data.roles[role]);
    try {
      await updateMember({
        roles: newRoles as Role[],
        reason: data.reason,
      });
      setLocation("/staff/");
      addToast("Staff member updated", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        addToast("You cannot set your own roles.", "error");
      } else {
        addToast("Try Again", "error");
      }
    }
  };

  return staff === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title="Edit Staff Member Roles"
        breadcrumbs={[
          { to: "/staff/", label: "Staff" },
          { label: `${staff.email}`, to: `/staff/${staff.id}/` },
          { label: "Edit Staff Member" },
        ]}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              {roles.map((role) => (
                <CheckboxField
                  key={role}
                  fullWidth
                  label={role}
                  name={`roles.${role}`}
                />
              ))}
              <TextField
                fullWidth
                label="Reason"
                name="reason"
                autoComplete="off"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Edit" />
              <FormLinks
                links={[{ label: "Back", to: `/staff/${staffId}/` }]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default EditStaffMember;
