import Stack from "@mui/system/Stack";

import { Appointment } from "src/models/appointment";
import AppointmentsTable from "src/pages/Clinician/AppointmentsTable";
import { usePaginationSettings } from "src/pagination";

interface IProps {
  clinicianId: string;
}

const Appointments = ({ clinicianId }: IProps) => {
  const [
    upcomingPaginationSettings,
    {
      setPageNumber: setUpcomingApptsPageNumber,
      setPageSize: setUpcomingApptsPageSize,
      toggleSort: toggleUpcomingApptsSort,
    },
  ] = usePaginationSettings<Appointment>({
    pageNumber: 0,
    pageSize: 10,
    sortColumn: "startAt",
    sortDirection: "asc",
  });
  const [
    pastPaginationSettings,
    {
      setPageNumber: setPastApptsPageNumber,
      setPageSize: setPastApptsPageSize,
      toggleSort: togglePastApptsSort,
    },
  ] = usePaginationSettings<Appointment>({
    pageNumber: 0,
    pageSize: 10,
    sortColumn: "startAt",
    sortDirection: "desc",
  });

  return (
    <Stack spacing={2}>
      <AppointmentsTable
        clinicianId={clinicianId}
        upcoming={true}
        paginationSettings={upcomingPaginationSettings}
        setPageNumber={setUpcomingApptsPageNumber}
        setPageSize={setUpcomingApptsPageSize}
        toggleSort={toggleUpcomingApptsSort}
        title="Upcoming Appointments"
      />
      <AppointmentsTable
        clinicianId={clinicianId}
        upcoming={false}
        paginationSettings={pastPaginationSettings}
        setPageNumber={setPastApptsPageNumber}
        setPageSize={setPastApptsPageSize}
        toggleSort={togglePastApptsSort}
        title="Past Appointments"
      />
    </Stack>
  );
};

export default Appointments;
