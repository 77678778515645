import { ToastContext } from "@curaleaf-international/components";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useContext } from "react";

import { Clinician, ClinicianAvailability } from "src/models";
import OneOffAvailabilityForm, {
  ValidatedType,
} from "src/pages/UpdateClinicianAvailability/OneOffAvailabilityForm";
import {
  useDeactivateClinicianAvailabilityMutation,
  useEditClinicianAvailabilityMutation,
} from "src/queries/clinicianAvailability";

interface IProps {
  clinician: Clinician;
  onClose: () => void;
  selectedAvailability: ClinicianAvailability;
}
const EditOneOffAvailability = ({
  clinician,
  onClose,
  selectedAvailability,
}: IProps) => {
  const { mutateAsync: updateAvailability } =
    useEditClinicianAvailabilityMutation(selectedAvailability.id);
  const { mutateAsync: deactivateAvailability } =
    useDeactivateClinicianAvailabilityMutation(selectedAvailability.id);

  const { addToast } = useContext(ToastContext);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateAvailability({
        appointmentTypes: data.appointmentTypes,
        clinicianId: clinician.id,
        endAt: data.endAt,
        startAt: data.startAt,
      });
      onClose();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "TIME_RANGE_OVERLAP"
      ) {
        addToast("Overlaps existing availability", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  const deleteAvailability = async () => {
    try {
      deactivateAvailability();
      onClose();
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        Edit One-Off Availability
      </DialogTitle>
      <OneOffAvailabilityForm
        availability={selectedAvailability}
        clinician={clinician}
        deleteAvailability={() => deleteAvailability()}
        editing={true}
        initialValues={{
          appointmentTypes: selectedAvailability.appointmentTypes,
          endAt: selectedAvailability.endAt,
          startAt: selectedAvailability.startAt,
        }}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );
};
export default EditOneOffAvailability;
