import { formatTime } from "@curaleaf-international/components";
import { EventContentArg, EventInput } from "@fullcalendar/core/index.js";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import RepeatIcon from "@mui/icons-material/Repeat";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { addWeeks, subWeeks } from "date-fns";

import { CalendarStyleContainer } from "src/components/CalendarStyleContainer";

interface IProps {
  availabilityEvents: EventInput[];
  handleDatesSelect: (start: Date, end: Date) => void;
  handleEventClick: (id: string) => void;
}

const EditAvailabilityCalendar = ({
  availabilityEvents,
  handleDatesSelect,
  handleEventClick,
}: IProps) => {
  const theme = useTheme();
  const currentDate = new Date();

  const renderEventContent = (event: EventContentArg) => {
    return (
      <Stack width="100%" height="100%" justifyContent="space-between">
        {event.event.start && event.event.end ? (
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[800] }}
          >
            {formatTime(event.event.start)} - {formatTime(event.event.end)}
          </Typography>
        ) : null}
        <Stack>
          <Typography variant="body2">{event.event.title}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="right">
          {event.event.extendedProps.recurring === true ? (
            <RepeatIcon
              fontSize="small"
              sx={{ color: theme.palette.grey[500] }}
            />
          ) : null}
        </Stack>
      </Stack>
    );
  };

  return (
    <CalendarStyleContainer>
      <FullCalendar
        validRange={{
          start: subWeeks(currentDate, 2),
          end: addWeeks(currentDate, 24),
        }}
        allDaySlot={false}
        eventClick={(event) => handleEventClick(event.event.id)}
        eventContent={renderEventContent}
        events={availabilityEvents}
        firstDay={1}
        headerToolbar={{
          left: "today",
          center: "title",
          right: "prev,next",
        }}
        height="65vh"
        initialView={screen.width > 600 ? "timeGridWeek" : "timeGridDay"}
        locale="en-IE"
        plugins={[interactionPlugin, timeGridPlugin]}
        select={(value) => handleDatesSelect(value.start, value.end)}
        selectable
        selectOverlap={false}
        slotLabelFormat={{
          timeStyle: "short",
        }}
        slotMinTime="07:00:00"
        slotMaxTime="23:00:00"
        expandRows={false}
        eventTimeFormat={{
          timeStyle: "short",
        }}
      />
    </CalendarStyleContainer>
  );
};

export default EditAvailabilityCalendar;
