import { ToastContext, Title } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import PrescriberForm, { ValidatedType } from "src/components/PrescriberForm";
import { PrescriberType } from "src/models";
import { useCreatePrescriberMutation } from "src/queries";

const CreatePrescriber = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createPrescriber } = useCreatePrescriberMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createPrescriber(data);
      setLocation("/prescribers/");
      addToast("Prescriber Added", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        addToast("Prescriber already exists", "error");
      }
      addToast("Try again", "error");
    }
  };
  return (
    <>
      <Title
        title="Add New Clinician"
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: "Create Clinician" },
        ]}
      />
      <PrescriberForm
        initialValues={{
          clinicianId: "",
          fullTitle: "",
          pin: "",
          prescriberType: PrescriberType.PRIVATE_DOCTOR,
          registrationNumber: "",
          specialism: "",
        }}
        onSubmit={onSubmit}
        submitLabel="Add Prescriber"
        back="/prescribers/"
      />
    </>
  );
};

export default CreatePrescriber;
