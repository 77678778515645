import { formatTime } from "@curaleaf-international/components";
import { EventContentArg, EventInput } from "@fullcalendar/core/index.js";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import RepeatIcon from "@mui/icons-material/Repeat";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { addWeeks, subWeeks } from "date-fns";
import { useEffect, useRef, useState } from "react";

import { CalendarStyleContainer } from "src/components/CalendarStyleContainer";
import { ClinicianAvailability } from "src/models";
import { getClinicianAvailabilityEvents } from "src/utils";

interface IProps {
  clinicianAvailability: ClinicianAvailability[];
}
const AvailabilityCalendar = ({ clinicianAvailability }: IProps) => {
  const [availabilityEvents, setAvailabilityEvents] = useState<EventInput[]>(
    [],
  );
  const currentDate = new Date();
  const calendarRef = useRef<FullCalendar>(null);
  const theme = useTheme();

  const getAvailabilityEvents = () => {
    const events = getClinicianAvailabilityEvents(clinicianAvailability, theme);
    setAvailabilityEvents(events);
  };

  useEffect(() => {
    getAvailabilityEvents();
  }, [clinicianAvailability]);

  const renderEventContent = (event: EventContentArg) => {
    return (
      <Stack width="100%" height="100%" justifyContent="space-between">
        {event.event.start && event.event.end ? (
          <Typography
            variant="subtitle2"
            sx={{ color: theme.palette.grey[800] }}
          >
            {formatTime(event.event.start)} - {formatTime(event.event.end)}
          </Typography>
        ) : null}
        <Stack>
          <Typography variant="body2">{event.event.title}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="right">
          {event.event.extendedProps.recurring === true ? (
            <RepeatIcon
              fontSize="small"
              sx={{ color: theme.palette.grey[500] }}
            />
          ) : null}
        </Stack>
      </Stack>
    );
  };

  return (
    <Card>
      <CardHeader title="Availability Calendar" />
      <CardContent>
        <CalendarStyleContainer>
          <FullCalendar
            validRange={{
              start: subWeeks(currentDate, 2),
              end: addWeeks(currentDate, 24),
            }}
            allDaySlot={false}
            events={availabilityEvents}
            eventContent={renderEventContent}
            eventTimeFormat={{
              timeStyle: "short",
            }}
            firstDay={1}
            headerToolbar={{
              left: "today",
              center: "title",
              right: "prev,next",
            }}
            initialView="timeGridWeek"
            locale="en-GB"
            plugins={[interactionPlugin, timeGridPlugin]}
            ref={calendarRef}
            slotLabelFormat={{
              timeStyle: "short",
            }}
            slotMinTime="07:00:00"
            slotMaxTime="23:00:00"
            expandRows={false}
            height="auto"
          />
        </CalendarStyleContainer>
      </CardContent>
    </Card>
  );
};
export default AvailabilityCalendar;
