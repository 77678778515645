import { ToastContext, Title } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import DestroyFP10Form, { ValidatedType } from "src/components/DestroyFP10Form";
import { useCreateAndDestroyFP10Mutation } from "src/queries";

const CreateAndDestroyFP10 = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createAndDestroyFP10 } =
    useCreateAndDestroyFP10Mutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createAndDestroyFP10({
        reason: data.reason,
        serial: data.serial,
        witnessId: parseInt(data.witnessId),
      });
      setLocation("/fp10s/destroy/");
      addToast("FP10 created and destroyed", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "FP10_EXISTS"
      ) {
        addToast("FP10 already exists", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "FP10_ALREADY_DESTROYED"
      ) {
        addToast("FP10 already destroyed", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "INVALID_SERIAL"
      ) {
        addToast("Invalid serial", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "WITNESS_SAME_AS_STAFF"
      ) {
        addToast("Witness must differ from signed in user", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Add and Destroy FP10"
        breadcrumbs={[
          { to: "/fp10s/", label: "FP10s" },
          { label: "Add and Destroy FP10" },
        ]}
      />
      <DestroyFP10Form
        initialValues={{
          reason: "",
          serial: "",
          witnessId: "",
        }}
        clearOnSubmit={true}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default CreateAndDestroyFP10;
