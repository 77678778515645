import {
  KeyElement,
  sortByKey,
  useDebounce,
} from "@curaleaf-international/components";
import Fuse from "fuse.js";
import { useState } from "react";

type SortDirection = "asc" | "desc";

export interface PaginationSettings<RowType> {
  pageNumber: number;
  pageSize?: number;
  sortColumn: undefined | keyof RowType;
  sortDirection: SortDirection;
  rawSearchQuery: string;
  searchQuery: string;
}

export interface PaginatedResult<RowType> {
  rows: RowType[];
  totalRecordCount: number;
  paginationSettings: PaginationSettings<RowType>;
}

export const usePaginationSettings = <RowType>(
  defaultConfig: Partial<PaginationSettings<RowType>>,
) => {
  const extendedDefaultConfig: PaginationSettings<RowType> = {
    ...{
      pageNumber: 0,
      sortDirection: "asc",
      rawSearchQuery: "",
      searchQuery: "",
      sortColumn: undefined,
    },
    ...defaultConfig,
  };
  const [paginationSettings, setPaginationSettings] = useState(
    extendedDefaultConfig,
  );
  const debounced = useDebounce(paginationSettings.rawSearchQuery);
  const setPageNumber = (pageNumber: number) => {
    setPaginationSettings({ ...paginationSettings, pageNumber });
  };
  const setPageSize = (pageSize: number) => {
    setPaginationSettings({ ...paginationSettings, pageSize, pageNumber: 0 });
  };
  const toggleSort = (sortColumn: keyof RowType) => {
    if (
      paginationSettings.sortColumn === sortColumn &&
      paginationSettings.sortDirection === "asc"
    ) {
      setPaginationSettings({ ...paginationSettings, sortDirection: "desc" });
    } else {
      setPaginationSettings({
        ...paginationSettings,
        sortColumn,
        sortDirection: "asc",
      });
    }
  };
  const setSearchQuery = (rawSearchQuery: string) => {
    if (rawSearchQuery !== paginationSettings.rawSearchQuery) {
      setPaginationSettings({
        ...paginationSettings,
        rawSearchQuery,
        // Navigate back to the first page
        pageNumber: 0,
        // If we are entering a non-empty search query clear the sorting
        sortColumn: rawSearchQuery.length
          ? undefined
          : paginationSettings.sortColumn,
      });
    }
  };
  return [
    { ...paginationSettings, searchQuery: debounced },
    { setPageNumber, setPageSize, toggleSort, setSearchQuery },
  ] as const;
};

export const paginateClientSide = <RowType>(
  rows: RowType[],
  paginationSettings: PaginationSettings<RowType>,
  searchColumns?: (string & keyof RowType)[],
) => {
  const { pageNumber, pageSize, sortColumn, sortDirection, searchQuery } =
    paginationSettings;
  const filteredRows = searchQuery
    ? new Fuse(rows, { keys: searchColumns })
        .search(searchQuery)
        .map((value) => value.item)
    : rows;
  const sortedRows =
    sortColumn !== undefined
      ? filteredRows.sort(
          sortByKey(
            ((row: RowType) => [row[sortColumn]]) as (
              arg0: RowType,
            ) => KeyElement[],
            sortDirection,
          ),
        )
      : filteredRows;

  if (pageSize === undefined) {
    return sortedRows;
  }

  const start = pageSize * pageNumber;
  const end = start + pageSize;
  return sortedRows.slice(start, end);
};
