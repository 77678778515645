import * as z from "zod";

export enum TravelLetterState {
  CREATED = "CREATED",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export const itinerarySchema = z.object({
  arrival: z.coerce.date(),
  departure: z.coerce.date(),
  destination: z.string(),
});

export type Itinerary = z.infer<typeof itinerarySchema>;

const travelLetterSchema = z.object({
  travelLetterId: z.string().uuid(),
  assignedTo: z.coerce.number().int().positive().nullable(),
  assignedToEmail: z.string().email().nullable(),
  letterPdfPresent: z.boolean(),
  itinerary: z.array(itinerarySchema),
  patientId: z.string().uuid(),
  patientName: z.string(),
  requiresPrescriberApproval: z.boolean(),
  staffId: z.number().int().nullable(),
  staffEmail: z.string().email().nullable(),
  state: z.nativeEnum(TravelLetterState),
  timestamp: z.coerce.date(),
});

export type TravelLetter = z.infer<typeof travelLetterSchema>;
export const newTravelLetter = (data: unknown): TravelLetter =>
  travelLetterSchema.parse(data);

const travelLetterIdSchema = z.object({
  id: z.string().uuid(),
});

export type TravelLetterId = z.infer<typeof travelLetterIdSchema>;
export const newTravelLetterId = (data: unknown): TravelLetterId =>
  travelLetterIdSchema.parse(data);

const travelLetterHistorySchema = z.object({
  id: z.coerce.number().int().positive(),
  travelLetterId: z.string().uuid(),
  reason: z.string().nullable(),
  staffEmail: z.string().email().nullable(),
  staffId: z.coerce.number().int().positive().nullable(),
  state: z.nativeEnum(TravelLetterState),
  timestamp: z.coerce.date(),
});

export type TravelLetterHistory = z.infer<typeof travelLetterHistorySchema>;

export const newTravelLetterHistory = (data: unknown): TravelLetterHistory => {
  return travelLetterHistorySchema.parse(data);
};
