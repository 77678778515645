import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import { StaffEmail } from "src/models";

type TabState = "html" | "txt";
interface IProps {
  email: StaffEmail;
}

const StaffEmailPreview = ({ email }: IProps) => {
  const [tab, setTab] = useState<TabState>("html");

  return (
    <>
      <DialogTitle>{email.ident}</DialogTitle>
      <DialogContent>
        <TabContext value={tab}>
          <TabList
            aria-label="Email menu"
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginBottom: 2 }}
          >
            <Tab label="HTML" value="html" />
            <Tab label="Plain txt" value="txt" />
          </TabList>

          <TabPanel value="html">
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  email.html
                    .replace(
                      "cid:Logo.png",
                      "/static/media/CuraleafClinic_Colour.png",
                    )
                    .replace("cid:CQCLogo.png", "/static/media/CQCLogo.png") ??
                  "",
              }}
            />
          </TabPanel>
          <TabPanel value="txt">
            <Box
              dangerouslySetInnerHTML={{
                __html: email.txt.replace(/\n/g, "<br />") ?? "",
              }}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </>
  );
};

export default StaffEmailPreview;
