import {
  CircularLoader,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { useContext } from "react";
import { useLocation } from "wouter";

import AppointmentLengthsForm, {
  FormType,
  ValidatedType,
} from "src/components/AppointmentLengthsForm";
import { useClinicianQuery } from "src/queries";
import {
  useClinicianAppointmentLengthsQuery,
  useEditClinicianAppointmentLengthsMutation,
} from "src/queries/appointmentLengths";

interface IProps {
  clinicianId: string;
}

const EditAppointmentLengths = ({ clinicianId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointmentLengths } =
    useClinicianAppointmentLengthsQuery(clinicianId);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const { mutateAsync: editAppointmentLengths } =
    useEditClinicianAppointmentLengthsMutation(clinicianId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      const appointmentLengthsData = Object.entries(data).map(
        ([appointmentLengthId, appointmentLengthData]) => ({
          id: appointmentLengthId,
          ...appointmentLengthData,
        }),
      );
      await editAppointmentLengths({ apptLengths: appointmentLengthsData });
      addToast("Appointment length updated.", "success");
      setLocation(`/clinicians/${clinicianId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return appointmentLengths === undefined || clinician === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title={`Edit Appointment Lengths - ${clinician.name}`}
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician.name}`, to: `/clinicians/${clinicianId}/` },
          { label: "Edit Appointment Lengths" },
        ]}
      />
      <AppointmentLengthsForm
        initialValues={appointmentLengths.reduce((acc, apptLength) => {
          acc[apptLength.id] = {
            appointmentType: apptLength.appointmentType,
            buffer: apptLength.buffer,
            length: apptLength.length,
          };
          return acc;
        }, {} as FormType)}
        appointmentLengths={appointmentLengths}
        clinician={clinician}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditAppointmentLengths;
