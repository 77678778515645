import { CircularLoader, Title } from "@curaleaf-international/components";
import { EventInput } from "@fullcalendar/core/index.js";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import { isFuture, isToday } from "date-fns";
import { useEffect, useState } from "react";

import { ClinicianAvailability } from "src/models/clinicianAvailability";
import AddAvailabilityDialog from "src/pages/UpdateClinicianAvailability/AddAvailabilityDialog";
import EditAvailabilityDialog from "src/pages/UpdateClinicianAvailability/EditAvailabilityDialog";
import EditAvailabilityCalendar from "src/pages/UpdateClinicianAvailability/UpdateAvailabilityCalendar";
import { useClinicianQuery, useClinicianAvailabilityQuery } from "src/queries";
import { getClinicianAvailabilityEvents } from "src/utils";

interface IProps {
  clinicianId: string;
}

const UpdateClinicianAvailability = ({ clinicianId }: IProps) => {
  const [availabilityEvents, setAvailabilityEvents] = useState<EventInput[]>(
    [],
  );
  const [selectedAvailability, setSelectedAvailability] = useState<
    ClinicianAvailability | undefined
  >(undefined);
  const [selectedTime, setSelectedTime] = useState<{ start: Date; end: Date }>({
    start: new Date(),
    end: new Date(),
  });
  const [addAvailabilityDialogOpen, setAddAvailabilityDialogOpen] =
    useState(false);
  const [editAvailabilityDialogOpen, setEditAvailabilityDialogOpen] =
    useState(false);
  const { data: clinicianAvailability } =
    useClinicianAvailabilityQuery(clinicianId);
  const { data: clinician } = useClinicianQuery(clinicianId);
  const theme = useTheme();

  const getAvailabilityEvents = () => {
    const events = getClinicianAvailabilityEvents(
      clinicianAvailability ?? [],
      theme,
    );
    setAvailabilityEvents(events);
  };
  useEffect(() => {
    getAvailabilityEvents();
  }, [clinicianAvailability]);

  if (clinicianAvailability === undefined || clinician === undefined) {
    return <CircularLoader />;
  }

  const handleDatesSelect = (start: Date, end: Date) => {
    setSelectedTime({ start: start, end: end });
    setAddAvailabilityDialogOpen(true);
  };

  const handleEventClick = (availabilityId: string) => {
    const availability = clinicianAvailability.find(
      (availability) => availability.id.toString() == availabilityId,
    );
    if (
      availability &&
      (isToday(availability.startAt) || isFuture(availability.startAt))
    ) {
      setSelectedAvailability(availability);
      setEditAvailabilityDialogOpen(true);
    }
  };

  return (
    <>
      <Title
        title={`Update Availability for ${clinician.name}`}
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: `${clinician.name}`, to: `/clinicians/${clinicianId}/` },
          { label: `Update Availability for ${clinician.name}` },
        ]}
        actions={
          <Button
            variant="contained"
            onClick={() => setAddAvailabilityDialogOpen(true)}
          >
            Add Availability
          </Button>
        }
      />
      <Card>
        <CardContent>
          <EditAvailabilityCalendar
            availabilityEvents={availabilityEvents}
            handleDatesSelect={(start: Date, end: Date) =>
              handleDatesSelect(start, end)
            }
            handleEventClick={(id: string) => handleEventClick(id)}
          />
        </CardContent>
      </Card>
      <AddAvailabilityDialog
        clinician={clinician}
        onClose={() => {
          setAddAvailabilityDialogOpen(false);
        }}
        open={addAvailabilityDialogOpen}
        selectedTime={selectedTime}
      />
      {selectedAvailability ? (
        <EditAvailabilityDialog
          clinician={clinician}
          onClose={() => {
            setSelectedAvailability(undefined);
            setEditAvailabilityDialogOpen(false);
          }}
          open={editAvailabilityDialogOpen}
          openEditRecurring={() => {
            setAddAvailabilityDialogOpen(true);
          }}
          availability={selectedAvailability}
        />
      ) : null}
    </>
  );
};
export default UpdateClinicianAvailability;
