import {
  CircularLoader,
  DateField,
  SelectField,
  SubmitButton,
  formatDate,
  formatTime,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format, getDay } from "date-fns";
import { enGB } from "date-fns/locale";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import {
  ClinicianRecurringAvailability,
  RecurringAvailabilityType,
} from "src/models";
import { AppointmentType } from "src/models/appointment";
import { Clinician } from "src/models/clinician";

const FormSchema = z
  .object({
    appointmentTypes: z.array(z.nativeEnum(AppointmentType)),
    endAt: z.coerce.date().nullable(),
    dayEnd: z.coerce.date(),
    recurringType: z.nativeEnum(RecurringAvailabilityType),
    startAt: z.coerce.date(),
    dayStart: z.coerce.date(),
  })
  .superRefine(({ endAt, startAt }, context) => {
    if (endAt === null) return;
    if (getDay(endAt) !== startAt.getDay()) {
      context.addIssue({
        message: "Must be same day of week as availability",
        path: ["endAt"],
        code: z.ZodIssueCode.custom,
      });
    }
  });

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  clinician: Clinician;
  deleteRecurringAvailability?: () => void;
  editing: boolean;
  initialValues: FormType;
  onClose: () => void;
  onSubmit: (data: ValidatedType) => Promise<any>;
  recurring?: ClinicianRecurringAvailability | undefined;
}

const RecurringAvailabilityForm = ({
  clinician,
  deleteRecurringAvailability,
  editing,
  initialValues,
  onClose,
  onSubmit,
  recurring,
}: IProps) => {
  if (editing && recurring === undefined) {
    return <CircularLoader size="small" />;
  }

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  const values = methods.watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 2 }}>
          {editing ? (
            <Alert severity="info" sx={{ width: "100%" }}>
              These changes will affect all blocks within this recurring
              availability from the 'Change From' date selected.
            </Alert>
          ) : null}
          <Grid container alignItems="center" sx={{ marginBottom: 1 }}>
            <DateField
              required
              fullWidth
              label={editing ? "Change From" : "Start Date"}
              name="startAt"
            />
            <Stack
              direction="row"
              width="100%"
              spacing={1}
              sx={{ marginTop: 1, marginBottom: 1 }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <TimePicker
                  sx={{ width: "100%" }}
                  onChange={(value) =>
                    value ? methods.setValue("dayStart", value) : null
                  }
                  label="Start Time *"
                  name="dayStart"
                  defaultValue={values.dayStart}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <TimePicker
                  sx={{ width: "100%" }}
                  label="End Time *"
                  name="dayEnd"
                  defaultValue={values.dayEnd}
                  onChange={(value) =>
                    value ? methods.setValue("dayEnd", value) : null
                  }
                />
              </LocalizationProvider>
            </Stack>
            <SelectField
              fullWidth
              required
              label="Recurring"
              name="recurringType"
              options={Object.values(RecurringAvailabilityType).map(
                (availability) => ({
                  label: availability
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase()),
                  value: availability,
                }),
              )}
            />
            <SelectField
              multiple
              fullWidth
              required
              label="Appointment Types"
              name="appointmentTypes"
              options={clinician.appointmentTypes.map((type) => ({
                label: type
                  .replace("_", "-")
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
                value: type,
              }))}
            />
            <Grid item xs={11}>
              <DateField fullWidth label="End Date" name="endAt" />
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => methods.setValue("endAt", null)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="subtitle2">
            {editing ? "These changes will" : "This will"} create availability
            on{" "}
            {recurring
              ? recurring.day.charAt(0) + recurring.day.toLowerCase().slice(1)
              : format(values.startAt, "EEEE")}
            s from {formatTime(values.dayStart)} to {formatTime(values.dayEnd)},
            recurring {values.recurringType.toLowerCase()}{" "}
            {values.endAt && !isNaN(values.endAt.getTime())
              ? `until ${formatDate(values.endAt)}`
              : null}{" "}
            for{" "}
            {values.appointmentTypes
              .map((type) => type.toLowerCase().replace("_", "-") + "s")
              .join(" and ")}
            .{" "}
            {editing
              ? "The changes will come into effect"
              : "This availability will start"}{" "}
            on {formatDate(values.startAt)}.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => {
              methods.reset();
              onClose();
            }}
          >
            Close
          </Button>
          {deleteRecurringAvailability ? (
            <Button
              variant="contained"
              color="error"
              onClick={() => deleteRecurringAvailability()}
            >
              Delete
            </Button>
          ) : null}
          <SubmitButton label={editing ? "Edit" : "Add"} />
        </DialogActions>
      </form>
    </FormProvider>
  );
};
export default RecurringAvailabilityForm;
