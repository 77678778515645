import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";

import PaginatedSearch from "src/components/PaginatedSearch";
import { AppointmentPayment, PaymentAction } from "src/models";
import PaymentsTable from "src/pages/AppointmentPayments/PaymentsTable";
import { usePaginationSettings } from "src/pagination";

const AppointmentPayments = () => {
  const [tab, setTab] = useHash<PaymentAction>(PaymentAction.SALE);
  const [
    paginationSettings,
    { setPageNumber, setPageSize, setSearchQuery, toggleSort },
  ] = usePaginationSettings<AppointmentPayment>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "stateTimestamp",
    sortDirection: "desc",
  });
  return (
    <>
      <Title title="Appointment Payments" />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as PaymentAction)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Sale" value={PaymentAction.SALE} />
            <Tab label="Refund" value={PaymentAction.REFUND} />
          </TabList>
          <Divider />
          <CardContent>
            <PaginatedSearch
              setSearchQuery={setSearchQuery}
              paginationSettings={paginationSettings}
              label="Search by Stripe/Viva ID"
            />
          </CardContent>
          <PaymentsTable
            action={tab}
            paginationSettings={paginationSettings}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            toggleSort={toggleSort}
          />
        </TabContext>
      </Card>
    </>
  );
};

export default AppointmentPayments;
