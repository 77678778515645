import Stack from "@mui/system/Stack";
import { isFuture, isPast } from "date-fns";

import AppointmentsTable from "src/pages/Patient/AppointmentsTable";
import { usePatientAppointmentsQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const Appointments = ({ patientId }: IProps) => {
  const { data: appointments } = usePatientAppointmentsQuery(patientId);

  return (
    <Stack spacing={2}>
      <AppointmentsTable
        appointments={appointments?.filter((appointment) =>
          isFuture(appointment.startAt),
        )}
        title="Future Appointments"
      />
      <AppointmentsTable
        appointments={appointments?.filter((appointment) =>
          isPast(appointment.startAt),
        )}
        title="Past Appointments"
      />
    </Stack>
  );
};

export default Appointments;
