import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { SpecialityGroup } from "src/models";
import { useCliniciansBySpecialityGroupQuery } from "src/queries";

interface IProps {
  specialityGroup: SpecialityGroup;
}
const ClinicianRow = ({ specialityGroup }: IProps) => {
  const { data: clinicians } = useCliniciansBySpecialityGroupQuery(
    specialityGroup.id,
  );

  return (
    <TableRow>
      <TableCell>
        <Link
          component={WLink}
          to={`/speciality-groups/${specialityGroup.id}/`}
        >
          {specialityGroup.specialityName}
        </Link>
      </TableCell>
      <TableCell>{specialityGroup.associatedDiagnoses.length}</TableCell>
      <TableCell>{clinicians?.length}</TableCell>
    </TableRow>
  );
};

export default ClinicianRow;
