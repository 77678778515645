import { Title, ToastContext } from "@curaleaf-international/components";
import { addDays } from "date-fns";
import { useContext } from "react";
import { useLocation } from "wouter";

import TravelLetterForm, {
  ValidatedType,
} from "src/components/TravelLetterForm";
import {
  useEditTravelLetterMutation,
  usePatientQuery,
  useTravelLetterPrescriptionsQuery,
  useTravelLetterQuery,
} from "src/queries";

interface IProps {
  travelLetterId: string;
}

const EditTravelLetter = ({ travelLetterId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: editTravelLetter } =
    useEditTravelLetterMutation(travelLetterId);
  const { data: letter } = useTravelLetterQuery(travelLetterId);
  const { data: patient } = usePatientQuery(letter?.patientId);
  const { data: prescriptions } =
    useTravelLetterPrescriptionsQuery(travelLetterId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await editTravelLetter({
        ...data,
        patientId: letter!.patientId,
      });
      addToast("Travel letter edited", "success");
      setLocation(`/travel-letters/${travelLetterId}/`);
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title title={`Edit Letter - ${patient?.name ?? ""}`} />
      <TravelLetterForm
        back={`/travel-letters/${travelLetterId}/`}
        disabled={letter === undefined}
        initialValues={{
          itinerary: letter?.itinerary ?? [
            {
              destination: "",
              arrival: new Date(),
              departure: addDays(new Date(), 1),
            },
          ],
          prescriptions:
            prescriptions?.map((prescription) => prescription.id) ?? [],
        }}
        label="Save"
        onSubmit={onSubmit}
        patientId={letter?.patientId}
      />
    </>
  );
};

export default EditTravelLetter;
