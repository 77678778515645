import * as z from "zod";

import { AppointmentType } from "src/models/appointment";

export enum RecurringAvailabilityType {
  FORTNIGHTLY = "FORTNIGHTLY",
  WEEKLY = "WEEKLY",
}

enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

const clinicianRecurringAvailabilitySchema = z.object({
  id: z.coerce.number().int().positive(),
  active: z.boolean(),
  appointmentTypes: z.array(z.nativeEnum(AppointmentType)),
  clinicianId: z.string().uuid(),
  created: z.coerce.date(),
  createdBy: z.coerce.number().int().positive(),
  createdByEmail: z.string().email(),
  day: z.nativeEnum(DayOfWeek),
  dayEnd: z.string(),
  endAt: z.coerce.date().nullable(),
  recurringType: z.nativeEnum(RecurringAvailabilityType),
  dayStart: z.string(),
  startAt: z.coerce.date(),
});

export type ClinicianRecurringAvailability = z.infer<
  typeof clinicianRecurringAvailabilitySchema
>;

export const newClinicianRecurringAvailability = (
  data: unknown,
): ClinicianRecurringAvailability =>
  clinicianRecurringAvailabilitySchema.parse(data);
