import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { Patient, newPatient } from "src/models/patient";
import { useMutation } from "src/query";

export interface IResolvePatientEmailIssueData {
  resolution: string;
  issueId: number;
}

export const usePatientResolveEmailIssueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      resolution,
      issueId,
    }: IResolvePatientEmailIssueData) =>
      await axios.put(`/v1/email-issues/${issueId}/`, { resolution }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patients"] });
      queryClient.invalidateQueries({ queryKey: ["patientEmailData"] });
      queryClient.invalidateQueries({ queryKey: ["appointmentEmailData"] });
      queryClient.invalidateQueries({ queryKey: ["patientsWithEmailIssues"] });
    },
  });
};

export const usePatientsWithEmailIssuesQuery = () => {
  return useQuery<Patient[]>({
    queryKey: ["patientsWithEmailIssues"],
    queryFn: async () => {
      const response = await axios.get(`/v1/patients/email-issues/`);

      return response.data.patients.map((data: unknown) => {
        return newPatient(data);
      });
    },
  });
};
