import {
  ToastContext,
  SubmitButton,
  FormLinks,
  Title,
  formatDate,
  CircularLoader,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";

import {
  useOutOfStockListingQuery,
  useCloseOutOfStockListingMutation,
} from "src/queries";

interface IProps {
  listingId: string;
}

const CloseOutOfStockListing = ({ listingId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: listing } = useOutOfStockListingQuery(listingId);
  const { mutateAsync: closeListing } =
    useCloseOutOfStockListingMutation(listingId);
  const methods = useForm();

  const onSubmit = async () => {
    try {
      await closeListing();
      addToast("Out of stock listing closed", "success");
      setLocation("/out-of-stock-listings/");
    } catch {
      addToast("Try again", "error");
    }
  };

  if (listing === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Title
        title="Mark Product Back in Stock"
        breadcrumbs={[
          { label: "Out of Stock Listings", to: "/out-of-stock-listings/" },
          {
            label: `${listing.productName}`,
            to: `/out-of-stock-listings/${listing.id}/`,
          },
          { label: "Mark Product Back in Stock" },
        ]}
      />
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>{listing.productName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>created</TableCell>
                  <TableCell>{`${formatDate(listing.createdOn)} by ${
                    listing.createdByEmail
                  }`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Divider />
            <CardActions>
              <SubmitButton label="Mark in stock" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/out-of-stock-listings/${listingId}/edit/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CloseOutOfStockListing;
