import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { Prescriber } from "src/models/prescriber";

interface IProps {
  prescriber: Prescriber;
}

const PrescriberRow = ({ prescriber }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/prescribers/${prescriber.id}/`}
        >
          View
        </MenuItem>

        <MenuItem component={WLink} to={`/prescribers/${prescriber.id}/edit/`}>
          Edit
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>
          <Link component={WLink} to={`/prescribers/${prescriber.id}/`}>
            {prescriber.fullTitle}
          </Link>
        </TableCell>
        <TableCell>
          <Link component={WLink} to={`/clinicians/${prescriber.clinicianId}/`}>
            {prescriber.clinicianName}
          </Link>
        </TableCell>
        <TableCell>{prescriber.pin}</TableCell>
        <TableCell>{prescriber.registrationNumber}</TableCell>
        <TableCell>{prescriber?.signature ? "Yes" : "No"}</TableCell>
        <TableCell>{prescriber.specialism}</TableCell>
        <TableCell>{prescriber.prescriberType}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PrescriberRow;
