import {
  AppointmentCountdown,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  Value,
  formatDateTime,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { addMinutes, isFuture, isPast } from "date-fns";
import { ChangeEvent, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink, useLocation } from "wouter";
import * as z from "zod";

import { AppointmentCreditState } from "src/models";
import {
  useAppointmentQuery,
  useConfirmAppointmentMutation,
  useReleaseAppointmentMutation,
  usePatientsAppointmentCreditsQuery,
} from "src/queries";

interface IProps {
  appointmentId: string;
}

const MakeAppointmentPayment = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { data: credits } = usePatientsAppointmentCreditsQuery(
    appointment?.patientId,
  );
  const { mutateAsync: confirm } = useConfirmAppointmentMutation(appointmentId);
  const { mutateAsync: releaseAppointment, isPending } =
    useReleaseAppointmentMutation(appointmentId);
  const [paymentOption, setPaymentOption] = useState("pay");

  const FormSchema = z
    .object({
      reason: z.string(),
    })
    .superRefine((value, ctx) => {
      if (paymentOption === "free" && value.reason.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Reason required",
          path: ["reason"],
        });
      }
    });

  type FormType = z.input<typeof FormSchema>;

  const methods = useForm<FormType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const handleReleaseAppointment = async () => {
    try {
      await releaseAppointment();
      addToast("Appointment cancelled", "success");
      setLocation(`/patients/${appointment?.patientId}/new-appointment/`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        addToast("Cannot find appointment", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  const onSubmit = async (data: FormType) => {
    try {
      let method = paymentOption;
      let creditId = null;
      if (!["free", "pay"].includes(paymentOption)) {
        method = "credit";
        creditId = paymentOption;
      }
      await confirm({
        creditId,
        method,
        reason: data.reason,
      });
      if (paymentOption === "pay") {
        navigator.clipboard.writeText(appointmentId);
        window.open("https://dashboard.stripe.com/payments/new", "_blank");
        addToast("Awaiting payment confirmation", "success");
      } else {
        addToast("Appointment confirmed", "success");
      }
      setLocation(`/appointments/${appointmentId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        title="Confirm Payment"
        breadcrumbs={[
          { label: "Patients", to: "/patients/" },
          {
            label: `${appointment?.patientName}`,
            to: `/patients/${appointment?.patientId}/`,
          },
          {
            label: "Add New Appointment",
            to: `/patients/${appointment?.patientId}/new-appointment/`,
          },
          { label: "Appointment Payment" },
        ]}
      />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          {appointment ? (
            <AppointmentCountdown
              beforeTimerLabel="Time remaining to complete this booking: "
              endTime={addMinutes(appointment.bookedOn, 30)}
              redirect={`/appointments/${appointment.id}/`}
            />
          ) : null}
          <TableContainer sx={{ marginBottom: 2 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>
                    <Value currency={appointment?.price} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Patient</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      to={`/patients/${appointment?.patientId}/`}
                    >
                      <Value text={appointment?.patientName} />
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Clinician</TableCell>
                  <TableCell>
                    {" "}
                    <Value text={appointment?.clinicianName} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    <Value
                      text={appointment?.type.toLowerCase().replace("_", "-")}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Start</TableCell>
                  <TableCell>
                    <Value dateTime={appointment?.startAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End</TableCell>
                  <TableCell>
                    {appointment !== undefined
                      ? formatDateTime(
                          addMinutes(appointment.startAt, appointment.length),
                        )
                      : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <FormControl>
            <FormLabel>Payment option</FormLabel>
            <RadioGroup
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setPaymentOption((event.target as HTMLInputElement).value)
              }
              value={paymentOption}
            >
              {credits
                ?.filter(
                  (credit) =>
                    credit.appointmentId === null &&
                    credit.appointmentType === appointment?.type &&
                    credit.state === AppointmentCreditState.ACTIVE &&
                    isPast(credit.activeFrom) &&
                    isFuture(credit.activeTo),
                )
                .map((credit) => (
                  <FormControlLabel
                    key={credit.id}
                    control={<Radio />}
                    label={`Use credit - ${credit.stateReason}`}
                    value={credit.id}
                  />
                ))}
              <FormControlLabel
                control={<Radio />}
                label="Take payment"
                value="pay"
              />
              <FormControlLabel
                control={<Radio />}
                label="Free of charge"
                value="free"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {paymentOption === "free" ? (
              <CardContent sx={{ paddingTop: 0 }}>
                <TextField fullWidth label="Reason" name="reason" />
              </CardContent>
            ) : null}
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={appointment === undefined || credits === undefined}
                label="Confirm"
              />
              <LoadingButton
                onClick={handleReleaseAppointment}
                loading={isPending}
                color="warning"
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default MakeAppointmentPayment;
