import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Email } from "src/models";
import AppointmentEmailRow from "src/pages/Appointment/AppointmentEmailRow";
import { useAppointmentEmailsQuery } from "src/queries";

interface IProps {
  appointmentId: string;
}

const AppointmentEmails = ({ appointmentId }: IProps) => {
  const { data: appointmentEmails } = useAppointmentEmailsQuery(appointmentId);

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Sent</TableCell>
              <TableCell>Sent To</TableCell>
              <TableCell>Message Type</TableCell>
              <TableCell>Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentEmails === undefined ? (
              <SkeletonRow cols={4} />
            ) : appointmentEmails.length === 0 ? (
              <TableRow>
                <TableCell>No emails found.</TableCell>
              </TableRow>
            ) : (
              appointmentEmails.map((email: Email) => (
                <AppointmentEmailRow
                  key={email.id + email.ident}
                  email={email}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AppointmentEmails;
