import {
  SkeletonRow,
  formatDate,
  formatTime,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { differenceInMinutes } from "date-fns";
import { Link as WLink } from "wouter";

import { useClinicianRecurringAvailabilityQuery } from "src/queries";

interface IProps {
  clinicianId: string;
}

const RecurringAvailabilityTable = ({ clinicianId }: IProps) => {
  const { data: recurringAvailability } =
    useClinicianRecurringAvailabilityQuery(clinicianId);

  return (
    <Card>
      <CardHeader title="Recurring Availability" />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Day of Week</TableCell>
              <TableCell>Date Range</TableCell>
              <TableCell>Time Range</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Recurring</TableCell>
              <TableCell>Appointment Type(s)</TableCell>
              <TableCell>Created By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recurringAvailability === undefined ? (
              <SkeletonRow cols={7} />
            ) : recurringAvailability.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7}>
                  No recurring availability found.
                </TableCell>
              </TableRow>
            ) : (
              recurringAvailability.map((availability) => (
                <TableRow key={availability.id}>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {availability.day.toLowerCase()}
                  </TableCell>
                  <TableCell>
                    {formatDate(availability.startAt)} -{" "}
                    {availability.endAt ? formatDate(availability.endAt) : ""}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {formatTime(
                      new Date(`1970-01-01T${availability.dayStart}`),
                    )}{" "}
                    -{" "}
                    {formatTime(new Date(`1970-01-01T${availability.dayEnd}`))}
                  </TableCell>
                  <TableCell>
                    {differenceInMinutes(
                      new Date(`1970-01-01T${availability.dayEnd}`),
                      new Date(`1970-01-01T${availability.dayStart}`),
                    ) /
                      60 >
                    1
                      ? `${(
                          differenceInMinutes(
                            new Date(`1970-01-01T${availability.dayEnd}`),
                            new Date(`1970-01-01T${availability.dayStart}`),
                          ) / 60
                        )
                          .toFixed(1)
                          .replace(/[.,]0$/, "")} hours`
                      : `${
                          differenceInMinutes(
                            new Date(`1970-01-01T${availability.dayEnd}`),
                            new Date(`1970-01-01T${availability.dayStart}`),
                          ) / 60
                        } hour`}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {availability.recurringType.toLowerCase()}
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    {availability.appointmentTypes
                      .join(", ")
                      .replace("_", "-")
                      .toLowerCase()}
                  </TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      to={`/staff/${availability.createdBy}`}
                    >
                      {availability.createdByEmail}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default RecurringAvailabilityTable;
