import { zodDecimal } from "@curaleaf-international/components";
import * as z from "zod";

import { brokenSchema } from "src/models/utils";

const packOptionSchema = z.object({
  cost: brokenSchema(zodDecimal().nullable()),
  quantity: z.number().nullable(),
});

export type PackOption = z.infer<typeof packOptionSchema>;

export const newPackOption = (data: unknown): PackOption =>
  packOptionSchema.parse(data);

const productSchema = z.object({
  id: z.string(),
  carrier: z.string().nullable(),
  controlled: z.boolean(),
  createdBy: z.string(),
  createdOn: z.coerce.date(),
  cbdMgPerUnit: z.string().nullable(),
  curaleafFormulaId: z.string().nullable(),
  discontinued: z.coerce.date().nullable(),
  discontinuedBy: z.string().nullable(),
  irradiation: z.string().nullable(),
  formularies: z.array(z.string()),
  flavour: z.string().nullable(),
  lastUpdated: z.coerce.date().nullable(),
  lastUpdatedBy: z.string().nullable(),
  manufacturer: z.string().nullable(),
  name: z.string(),
  outOfStock: z.boolean(),
  packOptions: z.array(packOptionSchema),
  profile: z.string().nullable(),
  routeOfAdministration: z.string().nullable(),
  strain: z.string().nullable(),
  thcMgPerUnit: z.string().nullable(),
  type: z.string().nullable(),
  units: z.string().nullable(),
});

export type Product = z.infer<typeof productSchema>;

export const newProduct = (data: unknown): Product => productSchema.parse(data);
