import {
  formatDateTime,
  ToastContext,
  FormLinks,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useLocation } from "wouter";

import {
  useActivateProductGroupMutation,
  useProductGroupQuery,
} from "src/queries";

interface IProps {
  productGroupId: string;
}

const ActivateProductGroup = ({ productGroupId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: group } = useProductGroupQuery(productGroupId);
  const { mutateAsync: activateGroup } =
    useActivateProductGroupMutation(productGroupId);

  const onClick = async () => {
    try {
      await activateGroup();
      addToast("Group activated", "success");
      setLocation("/product-groups/");
    } catch {
      addToast("Try again", "error");
    }
  };

  if (group === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Title
        title="Activate Group"
        breadcrumbs={[
          { label: "Product Groups", to: "/product-groups/" },
          { label: `${group.name}`, to: `/product-groups/${group.id}/` },
          { label: `Activate Product Group` },
        ]}
      />
      <Card>
        <TableContainer sx={{ marginBottom: 2 }}>
          <CardContent>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Group name</TableCell>
                  <TableCell>{group.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Product type</TableCell>
                  <TableCell>{group.productType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Product count</TableCell>
                  <TableCell>{group.productIds.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discontinued on</TableCell>
                  <TableCell>
                    {group.discontinued === null
                      ? "-"
                      : `${formatDateTime(group.discontinued)} by ${
                          group.discontinuedBy
                        }`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discontinued reason</TableCell>
                  <TableCell>{group.discontinuedReason ?? "-"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </TableContainer>

        <Divider />
        <CardActions>
          <Button
            disabled={group.discontinued === null}
            variant="contained"
            onClick={onClick}
          >
            Activate Group
          </Button>
          <FormLinks
            links={[
              {
                label: "Back",
                to: `/product-groups/${productGroupId}/edit/`,
              },
            ]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default ActivateProductGroup;
