import {
  SkeletonRow,
  formatDateTime,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { differenceInMinutes } from "date-fns";
import { Link as WLink } from "wouter";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { Appointment } from "src/models/appointment";
import { PaginationSettings } from "src/pagination";
import { useClinicianAppointmentsQuery } from "src/queries/appointments";

interface IProps {
  clinicianId: string;
  upcoming: boolean;
  paginationSettings: PaginationSettings<Appointment>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof Appointment) => void;
  title: string;
}

const AppointmentsTable = ({
  clinicianId,
  upcoming,
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
  title,
}: IProps) => {
  const { data } = useClinicianAppointmentsQuery(
    clinicianId,
    upcoming,
    paginationSettings,
  );

  const { rows: appointments, totalRecordCount } = data || {};
  let rows;
  if (appointments === undefined) {
    rows = <SkeletonRow cols={7} />;
  } else if (appointments.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={7}>No {title.toLowerCase()}.</TableCell>
      </TableRow>
    );
  } else {
    rows = appointments.map((appointment: Appointment) => {
      return (
        <TableRow key={appointment.id}>
          <TableCell>
            <Link component={WLink} to={`/appointments/${appointment.id}/`}>
              {appointment.id}
            </Link>
          </TableCell>
          <TableCell>
            <Link component={WLink} to={`/patients/${appointment.patientId}/`}>
              {appointment.patientName}
            </Link>
          </TableCell>
          <TableCell>{formatDateTime(appointment.startAt)}</TableCell>
          <TableCell>{appointment.length} minutes</TableCell>
          <TableCell>
            {differenceInMinutes(appointment.endAt, appointment.startAt, {
              roundingMethod: "ceil",
            }) - appointment.length}{" "}
            minutes
          </TableCell>
          <TableCell>{appointment.type}</TableCell>
          <TableCell>
            {appointment.status} <br />
            {formatDateTime(appointment.currentStateTimestamp)}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Card>
      <CardHeader title={title} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>
                <TableSortLabel<Appointment>
                  columnName={"startAt"}
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel<Appointment>
                  columnName={"length"}
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Length
                </TableSortLabel>
              </TableCell>
              <TableCell>Buffer</TableCell>
              <TableCell>
                <TableSortLabel<Appointment>
                  columnName={"type"}
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel<Appointment>
                  columnName={"status"}
                  paginationSettings={paginationSettings}
                  toggleSort={toggleSort}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
        <TablePagination<Appointment>
          recordCount={totalRecordCount}
          paginationSettings={paginationSettings}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          rowsPerPageOptions={[10, 50]}
        />
      </TableContainer>
    </Card>
  );
};

export default AppointmentsTable;
