import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { Clinician } from "src/models/clinician";
import { Staff } from "src/models/staff";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  clinician: Clinician | undefined;
  staffMember: Staff | undefined;
}

const ClinicianDetails = ({ clinician, staffMember }: IProps) => {
  return (
    <Card>
      <CardHeader title="Info" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Display Name</TableCell>
              <TableCell>
                <Value text={clinician?.name} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Staff Member</TableCell>
              <TableCell>
                <Link component={WLink} href={`/staff/${clinician?.staffId}/`}>
                  <Value text={staffMember?.email} />
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>
                <Value text={clinician?.clinicianType} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Appointment Types</TableCell>
              <TableCell>
                <Value
                  text={clinician?.appointmentTypes
                    .map((type) => type)
                    .join(", ")
                    .replace("_", "-")}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Minimum Hours Before Booking</TableCell>
              <TableCell>
                <Value text={clinician?.minimumHoursBeforeBooking.toString()} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Follow-Up Preference</TableCell>
              <TableCell>
                <Value
                  text={convertEnumValueToReadableString(
                    clinician?.followUpPreference ?? "",
                    " ",
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Specialisms</TableCell>
              <TableCell>
                <Value
                  text={clinician?.specialisms
                    .map((specialism) => specialism)
                    .join(", ")}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pre-Authorised Letters</TableCell>
              <TableCell>
                {clinician && clinician.autoApprovalLetters.length > 0
                  ? clinician.autoApprovalLetters
                      .map((letterType) => letterType)
                      .join(", ")
                  : "None"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ClinicianDetails;
