import {
  FormLinks,
  SelectField,
  SkeletonRow,
  SubmitButton,
  Title,
  ToastContext,
  formatDate,
  sortByKey,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, Link as WLink } from "wouter";
import * as z from "zod";

import { AuthContext } from "src/AuthContext";
import { Itinerary, Prescription, Role, Staff } from "src/models";
import {
  useAllStaffQuery,
  useAssignTravelLetterMutation,
  usePatientQuery,
  useTravelLetterPrescriptionsQuery,
  useTravelLetterQuery,
} from "src/queries";

interface IProps {
  travelLetterId: string;
}

const FormSchema = z.object({
  staffId: z.coerce.number().int().positive(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const AssignTravelLetter = ({ travelLetterId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: letter } = useTravelLetterQuery(travelLetterId);
  const { mutateAsync: assignTravelLetter } =
    useAssignTravelLetterMutation(travelLetterId);
  const { data: prescriptions } =
    useTravelLetterPrescriptionsQuery(travelLetterId);
  const { data: patient } = usePatientQuery(letter?.patientId);
  const { member } = useContext(AuthContext);
  const { data: staff } = useAllStaffQuery();

  const initialValues = { staffId: member?.id };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await assignTravelLetter(data);
      addToast("Travel letter assigned", "success");
      setLocation("/travel-letters/");
    } catch {
      addToast("Try again", "error");
    }
  };

  const sortKey = (staff: Staff) => {
    return [staff.email];
  };

  let filteredStaff: Staff[] = [];
  if (staff !== undefined) {
    filteredStaff = Object.values(staff)
      .filter((staff) => staff.roles.includes(Role.MEDICAL_SECRETARY))
      .sort(sortByKey(sortKey, "asc"));
  }

  return (
    <>
      <Title title="Assign travel letter" />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${letter?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created by</TableCell>
                  <TableCell>
                    <Link component={WLink} href={`/staff/${letter?.staffId}/`}>
                      {letter?.staffEmail ?? patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardHeader title="Itinerary" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Arrival</TableCell>
                <TableCell>Departure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {letter?.itinerary
                .sort(sortByKey((destination) => [destination.arrival], "asc"))
                .map((part: Itinerary) => (
                  <TableRow key={part.destination}>
                    <TableCell>{part.destination}</TableCell>
                    <TableCell>{formatDate(part.arrival)}</TableCell>
                    <TableCell>{formatDate(part.departure)}</TableCell>
                  </TableRow>
                )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <CardHeader title="Prescriptions" />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Last Printed</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptions?.map((prescription: Prescription) => (
                <TableRow key={prescription.id}>
                  <TableCell>{prescription.productName}</TableCell>
                  <TableCell>{formatDate(prescription.issueDate)}</TableCell>
                  <TableCell>{prescription.quantity}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <SelectField
                fullWidth
                label="Assign To"
                name="staffId"
                required
                options={filteredStaff.map((staffMember) => ({
                  label: staffMember.email,
                  value: `${staffMember.id}`,
                }))}
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton disabled={letter === undefined} label="Assign" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/travel-letters/${travelLetterId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default AssignTravelLetter;
