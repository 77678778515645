import {
  ToastContext,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { Link, useLocation } from "wouter";

import ProductGroupForm from "src/components/ProductGroupForm";
import type { IProductGroupData } from "src/queries";
import { useEditProductGroupMutation, useProductGroupQuery } from "src/queries";

interface IProps {
  productGroupId: string;
}

const EditProductGroup = ({ productGroupId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: group } = useProductGroupQuery(productGroupId);
  const { mutateAsync: editGroup } =
    useEditProductGroupMutation(productGroupId);

  const onSubmit = async (data: IProductGroupData) => {
    try {
      await editGroup(data);
      addToast("Product group updated", "success");
      setLocation("/product-groups/");
    } catch {
      addToast("Try again", "error");
    }
  };

  const action =
    group?.discontinued === null ? (
      <Button
        component={Link}
        to={`/product-groups/${productGroupId}/deactivate/`}
        variant="contained"
        color="warning"
      >
        Discontinue Group
      </Button>
    ) : (
      <Button
        component={Link}
        to={`/product-groups/${productGroupId}/activate/`}
        variant="contained"
        color="warning"
      >
        Activate Group
      </Button>
    );

  return group === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title="Edit Product Group"
        actions={action}
        breadcrumbs={[
          { label: "Product Groups", to: "/product-groups/" },
          { label: `${group.name}`, to: `/product-groups/${group.id}/` },
          { label: `Edit Product Group` },
        ]}
      />
      <ProductGroupForm
        initialValues={{
          name: group.name,
          productType: group.productType,
          productIds: group.productIds.map((id) => ({ id })),
        }}
        label="Edit"
        back={`/product-groups/${productGroupId}/`}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditProductGroup;
