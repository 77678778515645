import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { AppointmentStatus } from "src/models";
import AppointmentCredits from "src/pages/Patient/AppointmentCredits";
import Appointments from "src/pages/Patient/Appointments";
import PatientDetails from "src/pages/Patient/PatientDetails";
import PatientEmails from "src/pages/Patient/PatientEmails";
import PatientTrackingNotesSummary from "src/pages/Patient/PatientTrackingNotesSummary";
import PatientTrackingNotesTable from "src/pages/Patient/PatientTrackingNotesTable";
import PatientTravelLetters from "src/pages/Patient/PatientTravelLetters";
import PatientTriagingDetails from "src/pages/Patient/PatientTriagingDetails";
import { usePatientAppointmentsQuery } from "src/queries";
import { usePatientQuery } from "src/queries/patients";

type TabState =
  | "details"
  | "notes"
  | "appointments"
  | "appointmentCredits"
  | "emails"
  | "travel-letters";

interface IProps {
  patientId: string;
}

const Patient = ({ patientId }: IProps) => {
  const { data: patient } = usePatientQuery(patientId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: patientAppointments } = usePatientAppointmentsQuery(patientId);

  const pendingAppointments = patientAppointments?.filter(
    (appointment) => appointment.status === AppointmentStatus.PENDING,
  );

  return patient === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/patients/${patient.id}/new-appointment/`}
          disabled={pendingAppointments?.length !== 0}
        >
          Add New Appointment
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/patients/${patient.id}/new-appointment-credit/`}
        >
          Add Appointment Credit
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/patients/${patientId}/new-travel-letter/`}
          disabled={
            patient.assignedConsultantId === null ||
            patient.assignedConsultantId === undefined
          }
        >
          Create travel letter
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={patient.name}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="Patient menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="Details" value="details" />
            <Tab label="Tracking Notes" value="notes" />
            <Tab label="Appointments" value="appointments" />
            <Tab label="Appointment Credits" value="appointmentCredits" />
            <Tab label="Emails" value="emails" />
            <Tab label="Travel Letters" value="travel-letters" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <PatientDetails patientId={patientId} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <PatientTriagingDetails patientId={patient.id} />
                <PatientTrackingNotesSummary patientId={patient.id} />
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="notes">
          <PatientTrackingNotesTable patientId={patient.id} />
        </TabPanel>
        <TabPanel value="appointments">
          <Appointments patientId={patient.id} />
        </TabPanel>
        <TabPanel value="appointmentCredits">
          <AppointmentCredits patientId={patient.id} />
        </TabPanel>
        <TabPanel value="emails">
          <PatientEmails patientId={patient.id} />
        </TabPanel>
        <TabPanel value="travel-letters">
          <PatientTravelLetters patientId={patient.id} />
        </TabPanel>
      </TabContext>
    </>
  );
};
export default Patient;
