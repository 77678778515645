import * as z from "zod";

const FP10StateHistorySchema = z.object({
  id: z.coerce.number().int().positive(),
  fp10Id: z.string().uuid(),
  reason: z.string().nullable(),
  staffEmail: z.string().email(),
  staffId: z.coerce.number().int().positive(),
  state: z.string(),
  timestamp: z.coerce.date(),
  witnessEmail: z.string().email().nullable(),
  witnessId: z.number().int().positive().nullable(),
});

export type FP10StateHistory = z.infer<typeof FP10StateHistorySchema>;

export const newFP10StateHistory = (data: unknown): FP10StateHistory =>
  FP10StateHistorySchema.parse(data);
