import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { FP10State, FP10 } from "src/models";
import FP10Row from "src/pages/FP10s/FP10Row";
import { PaginationSettings } from "src/pagination";
import { useFp10sQuery } from "src/queries";

interface IProps {
  state: string;
  paginationSettings: PaginationSettings<FP10>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof FP10) => void;
}

const FP10sTable = ({
  state,
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
}: IProps) => {
  let stateFilter = undefined;
  if (state === "destroyed") {
    stateFilter = FP10State.DESTROYED;
  }
  const { data } = useFp10sQuery(paginationSettings, stateFilter);

  const { rows: fp10s, totalRecordCount } = data || {};
  let rows = null;
  if (!fp10s) {
    rows = <SkeletonRow cols={5} />;
  } else {
    rows = (
      <TableRow>
        <TableCell align="center" colSpan={5}>
          No destroyed FP10s found.
        </TableCell>
      </TableRow>
    );
    rows = fp10s.map((fp10) => <FP10Row key={fp10.id} fp10={fp10} />);
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <TableSortLabel<FP10>
                columnName={"serial"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Serial
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel<FP10>
                columnName={"state"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                State
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel<FP10>
                columnName={"stateTimestamp"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Timestamp
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Reason</TableCell>
            <TableCell align="center">Staff Email</TableCell>
            <TableCell align="center">Witness Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <TablePagination<FP10>
        recordCount={totalRecordCount}
        paginationSettings={paginationSettings}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        rowsPerPageOptions={[100, 500, 750, 1000]}
      />
    </TableContainer>
  );
};

export default FP10sTable;
