import { Title } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link as WLink } from "wouter";

import ProductGroupTable from "src/pages/ProductGroups/ProductGroupTable";

const ProductGroups = () => {
  return (
    <>
      <Title
        actions={
          <Button
            component={WLink}
            to={"/product-groups/new/"}
            variant="contained"
          >
            Add New Group
          </Button>
        }
        title="Clinical Alternative Groups"
      />
      <Card>
        <ProductGroupTable />
      </Card>
    </>
  );
};

export default ProductGroups;
