import { EventInput } from "@fullcalendar/core/index.js";
import { darken, lighten, Theme } from "@mui/system";

import { Appointment } from "src/models/appointment";
import { ClinicianAvailability } from "src/models/clinicianAvailability";

export const SX_INFO = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.info.main, 0.75)
      : darken(theme.palette.info.main, 0.75),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.info.main, 0.75)
      : lighten(theme.palette.info.main, 0.75),
};

export const SX_WARNING = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.warning.main, 0.9)
      : darken(theme.palette.warning.main, 0.9),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.warning.main, 0.6)
      : lighten(theme.palette.warning.main, 0.6),
};

export const SX_ERROR = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.error.main, 0.9)
      : darken(theme.palette.error.main, 0.9),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.error.main, 0.6)
      : lighten(theme.palette.error.main, 0.6),
};

export const getAppointmentColour = (
  appointment: Appointment,
  theme: Theme,
) => {
  let eventColour;
  switch (appointment.type) {
    case "CHECK_UP":
      eventColour = theme.palette.primary.main;
      break;
    case "FOLLOW_UP":
      eventColour = theme.palette.secondary.main;
      break;
    case "INITIAL":
      eventColour = theme.palette.secondary.light;
      break;
    default:
      eventColour = theme.palette.primary.dark;
  }
  return eventColour;
};

export const getClinicianAvailabilityEvents = (
  events: ClinicianAvailability[],
  theme: Theme,
) => {
  const availabilityEvents: EventInput[] = [];
  events.map((availability) => {
    const event = {
      id: availability.id.toString(),
      title: `${availability.appointmentTypes
        .map((type) => type + "s")
        .join(", ")
        .toLowerCase()
        .replace("_", "-")
        .replace(/\b\w/g, (char) => char.toUpperCase())}`,
      start: availability.startAt,
      end: availability.endAt,
      backgroundColor:
        availability.recurringId !== null
          ? theme.palette.secondary.main
          : theme.palette.secondary.light,
      recurring: availability.recurringId ? true : false,
    };
    availabilityEvents.push(event);
  });
  return availabilityEvents;
};

export const convertEnumValueToReadableString = (
  value: string,
  separator: string,
) => {
  return value
    .toLowerCase()
    .replaceAll("_", separator)
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
