import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";

import { Clinician } from "src/models";
import AddOneOffAvailability from "src/pages/UpdateClinicianAvailability/AddOneOffAvailability";
import AddRecurringAvailability from "src/pages/UpdateClinicianAvailability/AddRecurringAvailability";

type TabState = "one-off" | "recurring";

interface IProps {
  clinician: Clinician;
  onClose: () => void;
  open: boolean;
  selectedTime?: { start: Date; end: Date } | undefined;
}

const AddAvailabilityDialog = ({
  clinician,
  onClose,
  open,
  selectedTime,
}: IProps) => {
  const [tab, setTab] = useState<TabState>("one-off");

  useEffect(() => {
    if (open === false) {
      setTab("one-off");
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <TabContext value={tab}>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <TabList
            aria-label="Update availability menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="One-Off" value="one-off" />
            <Tab label="Recurring" value="recurring" />
          </TabList>
          <Divider />
        </DialogContent>
        <TabPanel value="one-off">
          <AddOneOffAvailability
            clinician={clinician}
            onClose={onClose}
            selectedTime={selectedTime}
          />
        </TabPanel>
        <TabPanel value="recurring">
          <AddRecurringAvailability
            clinician={clinician}
            onClose={onClose}
            selectedTime={selectedTime}
          />
        </TabPanel>
      </TabContext>
    </Dialog>
  );
};

export default AddAvailabilityDialog;
