import { formatDateTime } from "@curaleaf-international/components";
import Visibility from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";

import { StaffEmail } from "src/models";
import StaffEmailPreview from "src/pages/StaffMember/StaffEmailPreview";

interface IProps {
  email: StaffEmail;
}

const StaffEmailRow = ({ email }: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const showDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={showDialog}>
        <StaffEmailPreview email={email} />
      </Dialog>
      <TableRow key={email.id}>
        <TableCell>{email.sent && formatDateTime(email.sent)}</TableCell>
        <TableCell>{email.ident}</TableCell>
        <TableCell>
          <IconButton onClick={showDialog}>
            <Visibility />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StaffEmailRow;
