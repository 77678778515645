import axios from "axios";

import {
  PatientTrackingNote,
  newPatientTrackingNote,
} from "src/models/patientTrackingNote";
import { useQuery } from "src/query";

export const usePatientTrackingNotesQuery = (patientId: string) => {
  return useQuery<PatientTrackingNote[]>({
    queryKey: ["patient_tracking_notes", patientId],
    queryFn: async () => {
      const response = await axios.get(`/v1/tracking-notes/${patientId}/`);
      return response.data.notes.map((data: unknown) => {
        return newPatientTrackingNote(data);
      });
    },
  });
};
