import axios from "axios";

import { Email, EmailIssue, newEmail, newEmailIssue } from "src/models";
import { useQuery } from "src/query";

interface PatientEmailData {
  emails: Email[];
  issues: EmailIssue[];
}

export const usePatientEmailsQuery = (patientId: string) =>
  useQuery<PatientEmailData>({
    queryKey: ["patientEmailData", patientId],
    queryFn: async () => {
      const response = await axios.get(`/v1/patients/${patientId}/emails/`);

      return {
        emails: response.data.emails.map((data: unknown) => newEmail(data)),
        issues: response.data.issues.map((data: unknown) =>
          newEmailIssue(data),
        ),
      };
    },
  });
