import { Search, Title, useDebounce } from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { ChangeEvent, useState } from "react";
import { Link } from "wouter";

import CliniciansTable from "src/pages/Clinicians/CliniciansTable";

const Clinicians = () => {
  const [rawSearch, setRawSearch] = useState<string>("");
  const search = useDebounce(rawSearch);

  return (
    <>
      <Title
        title="Clinicians"
        actions={
          <Button component={Link} to="/clinicians/new/" variant="contained">
            Add Clinician
          </Button>
        }
      />
      <Card>
        <CardContent>
          <Grid container>
            <Search
              fullWidth
              label="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRawSearch(event.target.value)
              }
              value={rawSearch}
            />
          </Grid>
        </CardContent>
        <CliniciansTable search={search} />
      </Card>
    </>
  );
};
export default Clinicians;
