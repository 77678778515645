import { ToastContext, Title } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import SpecialityGroupForm, {
  ValidatedType,
} from "src/components/SpecialityGroupForm";
import { Diagnosis } from "src/models";
import { useCreateSpecialityGroupMutation } from "src/queries";

const CreateSpecialityGroup = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createGroup } = useCreateSpecialityGroupMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await createGroup({
        associatedDiagnoses: data.associatedDiagnoses as Diagnosis[],
        specialityName: data.specialityName,
        clinicians: data.clinicians,
        complexClinicians: data.complexClinicians,
      });
      setLocation("/speciality-groups/");
      addToast("Speciality Group Added", "success");
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code == "DUPLICATE_SPECIALITY_NAME"
      ) {
        addToast("Speciality Group Name Already In Use", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code == "DIAGNOSIS_ALREADY_ASSOCIATED"
      ) {
        addToast("One Or More Diagnosis Already Assigned To Group", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };
  return (
    <>
      <Title
        title="Add New Speciality Group"
        breadcrumbs={[
          { label: "Speciality Groups", to: "/speciality-groups/" },
          { label: "Create Speciality Group" },
        ]}
      />
      <SpecialityGroupForm
        initialValues={{
          associatedDiagnoses: [],
          clinicians: [],
          specialityName: "",
          complexClinicians: [],
        }}
        onSubmit={onSubmit}
        back="/speciality-groups/"
        submitLabel="Add Group"
      />
    </>
  );
};

export default CreateSpecialityGroup;
