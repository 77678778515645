import { SkeletonRow, sortByKey } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Fuse from "fuse.js";
import { useState } from "react";

import { Clinician } from "src/models/clinician";
import ClinicianRow from "src/pages/Clinicians/ClinicianRow";
import { useCliniciansQuery } from "src/queries";

interface IProps {
  search: string;
}
type Direction = "asc" | "desc";
type OrderableProperties = "name";

const CliniciansTable = ({ search }: IProps) => {
  const { data: clinicians } = useCliniciansQuery();
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("name");

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortKey = (clinicians: Clinician) => [clinicians[orderBy]];

  const filterClinicians = (clinicians: Clinician[]) => {
    if (search !== "") {
      const fuse = new Fuse(clinicians, { keys: ["name"] });
      return fuse.search(search).map((value) => value.item);
    }
    return clinicians.sort(sortByKey(sortKey, order));
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={order}
                onClick={onSortClick("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Staff Member</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Appointment Types</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {clinicians === undefined ? (
            <SkeletonRow cols={5} />
          ) : clinicians.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Clinicians found.
              </TableCell>
            </TableRow>
          ) : (
            filterClinicians(Object.values(clinicians)).map(
              (clinician, index) => (
                <ClinicianRow key={index} clinician={clinician} />
              ),
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CliniciansTable;
