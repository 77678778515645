import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { Clinician } from "src/models";

interface IProps {
  clinician: Clinician;
}
const ClinicianRow = ({ clinician }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={`/clinicians/${clinician.id}/edit/`}>
          Edit Clinician
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/appointment-lengths/${clinician.id}/edit/`}
        >
          Edit Appointment Lengths
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>
          <Link component={WLink} href={`/clinicians/${clinician.id}/`}>
            {clinician.name}
          </Link>
        </TableCell>
        <TableCell>
          <Link component={WLink} href={`/staff/${clinician.staffId}/`}>
            {clinician.email}
          </Link>
        </TableCell>
        <TableCell>{clinician.clinicianType}</TableCell>
        <TableCell>{clinician.appointmentTypes.join(", ")}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ClinicianRow;
