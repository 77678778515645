import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link } from "wouter";

import RequiresRoles from "src/components/RequiresRoles";
import { Role } from "src/models";
import Details from "src/pages/StaffMember/Details";
import RoleHistory from "src/pages/StaffMember/RoleHistory";
import StaffActivities from "src/pages/StaffMember/StaffActivities";
import StaffEmails from "src/pages/StaffMember/StaffEmails";
import StaffSessions from "src/pages/StaffMember/StaffSessions";
import { useStaffQuery } from "src/queries";

type TabState = "details" | "emails" | "roleHistory" | "activity" | "sessions";

interface IProps {
  staffId: number;
}

const StaffMember = ({ staffId }: IProps) => {
  const { data: staff } = useStaffQuery(staffId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return staff === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={Link} to={`/staff/${staff.id}/edit/roles/`}>
          Edit
        </MenuItem>
        <RequiresRoles roles={[Role.DEVELOPER]}>
          <MenuItem component={Link} to={`/staff/${staff.id}/edit/firebase/`}>
            Edit Firebase
          </MenuItem>
        </RequiresRoles>
        <MenuItem component={Link} to={`/staff/${staff.id}/disabled/`}>
          {staff.disabled === null ? "Disable" : "Enable"}
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          { to: "/staff/", label: "Staff" },
          { label: `${staff.email}` },
        ]}
        title={staff.email}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="Staff Member menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="Details" value="details" />
            <Tab label="Emails" value="emails" />
            <Tab label="Role History" value="roleHistory" />
            <Tab label="Sessions" value="sessions" />
            <Tab label="Activities" value="activities" />
          </TabList>
        </Box>
        <Card>
          <TabPanel value={"details"}>
            <Details staffId={staffId} />
          </TabPanel>
          <TabPanel value={"emails"}>
            <StaffEmails staffId={staffId} />
          </TabPanel>
          <TabPanel value={"roleHistory"}>
            <RoleHistory staffId={staffId} />
          </TabPanel>
          <TabPanel value={"sessions"}>
            <StaffSessions staffId={staffId} />
          </TabPanel>
          <TabPanel value={"activities"}>
            <StaffActivities staffId={staffId} />
          </TabPanel>
        </Card>
      </TabContext>
    </>
  );
};

export default StaffMember;
