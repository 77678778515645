import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import SpecialityGroupClinicians from "src/pages/SpecialityGroup/SpecialityGroupClinicians";
import SpecialityGroupDetails from "src/pages/SpecialityGroup/SpecialityGroupDetails";
import { useSpecialityGroupQuery } from "src/queries";

type TabState = "details" | "clinicians";
interface IProps {
  specialityGroupId: number;
}

const SpecialityGroup = ({ specialityGroupId }: IProps) => {
  const { data: group } = useSpecialityGroupQuery(specialityGroupId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (group === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/speciality-groups/${specialityGroupId}/edit/`}
        >
          Edit
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={group?.specialityName ?? ""}
        breadcrumbs={[
          { label: "Speciality Groups", to: "/speciality-groups/" },
          { label: `${group.specialityName}` },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList onChange={(_, value: string) => setTab(value as TabState)}>
            <Tab label="Details" value="details" />
            <Tab label="Clinicians" value="clinicians" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <SpecialityGroupDetails specialityGroupId={specialityGroupId} />
        </TabPanel>
        <TabPanel value="clinicians">
          <SpecialityGroupClinicians specialityGroupId={specialityGroupId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default SpecialityGroup;
