import {
  TextField,
  ToastContext,
  SubmitButton,
  FormLinks,
  Title,
  CircularLoader,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { useUpdateFirebaseDataMutation, useStaffQuery } from "src/queries";

interface IProps {
  staffId: number;
}

const FormSchema = z.object({
  firebaseUid: z.string().nullable(),
  firebaseEmail: z.string().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditStaffFirebaseData = ({ staffId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: staff } = useStaffQuery(staffId);
  const { mutateAsync: updateFirebaseData } =
    useUpdateFirebaseDataMutation(staffId);
  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firebaseUid: staff?.firebaseUid ?? "",
      firebaseEmail: staff?.firebaseEmail ?? "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateFirebaseData(data);
      addToast("Staff member updated", "success");
      setLocation("/staff/");
    } catch {
      addToast("Try again", "error");
    }
  };

  if (staff === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Title title="Edit staff Firebase data" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                fullWidth
                label="Firebase UID"
                name="firebaseUid"
                required
              />
              <TextField
                fullWidth
                label="Firebase Email"
                name="firebaseEmail"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Edit Staff" />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/staff/${staffId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditStaffFirebaseData;
