import { formatDateTime } from "@curaleaf-international/components";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { useStaffQuery } from "src/queries/staff";

interface IProps {
  staffId: number;
}

const Details = ({ staffId }: IProps) => {
  const { data: staff } = useStaffQuery(staffId);

  return (
    <>
      <CardHeader title="Info" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{staff?.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>
              {staff?.created ? formatDateTime(staff?.created) : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Login</TableCell>
            <TableCell>
              {staff?.lastLogin ? formatDateTime(staff?.lastLogin) : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Active Sessions</TableCell>
            <TableCell>{staff?.activeSessions}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Disabled</TableCell>
            <TableCell>
              {staff?.disabled ? formatDateTime(staff?.disabled) : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Firebase email</TableCell>
            <TableCell>{staff?.firebaseEmail ?? ""}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Firebase id</TableCell>
            <TableCell>{staff?.firebaseUid ?? ""}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Details;
