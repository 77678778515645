import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { AppointmentType, newAppointmentLength } from "src/models";
import { AppointmentLength } from "src/models/appointmentLengths";
import { useQuery, useMutation } from "src/query";

export interface IAppointmentLength {
  appointmentType: AppointmentType;
  buffer: number;
  length: number;
}

interface IAppointmentLengthsData {
  apptLengths: IAppointmentLength[];
}

export const useClinicianAppointmentLengthsQuery = (clinicianId: string) => {
  return useQuery<AppointmentLength[]>({
    queryKey: ["appointmentLengths", clinicianId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-lengths/${clinicianId}/`,
      );
      return response.data.appointmentLengths.map((data: unknown) =>
        newAppointmentLength(data),
      );
    },
    enabled: clinicianId !== undefined,
  });
};

export const useEditClinicianAppointmentLengthsMutation = (
  clinicianId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IAppointmentLengthsData) =>
      await axios.put(`/v1/appointment-lengths/${clinicianId}/`, data),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["appointmentLengths", clinicianId],
      }),
  });
};
