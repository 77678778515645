import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EmailRow from "src/components/EmailRow";
import { Email } from "src/models";
import { useTravelLetterEmailsQuery } from "src/queries";

interface IProps {
  letterId: string;
}

const TravelLetterEmails = ({ letterId }: IProps) => {
  const { data: letterEmails } = useTravelLetterEmailsQuery(letterId);

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Sent</TableCell>
              <TableCell>Message Type</TableCell>
              <TableCell>Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {letterEmails === undefined ? (
              <SkeletonRow cols={3} />
            ) : letterEmails.length === 0 ? (
              <TableRow>
                <TableCell>No emails found.</TableCell>
              </TableRow>
            ) : (
              letterEmails.map((email: Email) => (
                <EmailRow key={email.id} email={email} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TravelLetterEmails;
