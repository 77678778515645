import {
  CircularLoader,
  formatDate,
  formatTime,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { addMinutes } from "date-fns";
import { Link as WLink } from "wouter";

import { Appointment } from "src/models";
import { getAppointmentColour } from "src/utils";

interface IProps {
  onClose: () => void;
  open: boolean;
  selectedAppointment: Appointment | undefined;
}

const AppointmentDialog = ({ onClose, open, selectedAppointment }: IProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ zIndex: 2 }}
    >
      {selectedAppointment === undefined ? (
        <DialogContent>
          <CircularLoader sx={{ height: "200px" }} size="small" />
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <List>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItem disablePadding>
                  <Stack spacing={1}>
                    <Link
                      width={"100%"}
                      component={WLink}
                      to={`/patients/${selectedAppointment.patientId}/`}
                    >
                      <Typography gutterBottom variant="h5">
                        {selectedAppointment.patientName}
                      </Typography>
                    </Link>
                    <Stack spacing={1}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography
                          sx={{ color: (theme) => theme.palette.grey[600] }}
                        >
                          Clinician
                        </Typography>
                        <Typography>
                          {selectedAppointment.clinicianName}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography
                          sx={{ color: (theme) => theme.palette.grey[600] }}
                        >
                          Status
                        </Typography>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {selectedAppointment.status.toLowerCase()}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography
                          sx={{ color: (theme) => theme.palette.grey[600] }}
                        >
                          Date
                        </Typography>
                        <Typography>
                          {formatDate(selectedAppointment.startAt)}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography
                          sx={{ color: (theme) => theme.palette.grey[600] }}
                        >
                          Time
                        </Typography>
                        <Typography>
                          {formatTime(selectedAppointment.startAt)} -{" "}
                          {formatTime(
                            addMinutes(
                              selectedAppointment.startAt,
                              selectedAppointment.length,
                            ),
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </ListItem>
                <Chip
                  sx={{
                    backgroundColor: getAppointmentColour(
                      selectedAppointment,
                      theme,
                    ),
                    color: (theme) => theme.palette.common.white,
                    textTransform: "capitalize",
                  }}
                  label={selectedAppointment.type
                    .replace("_", " ")
                    .toLowerCase()}
                />
              </Stack>
            </List>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button fullWidth onClick={() => onClose()}>
              Back
            </Button>
            <Link
              width={"100%"}
              component={WLink}
              to={`/appointments/${selectedAppointment.id}/`}
            >
              <Button fullWidth variant="contained">
                View Appointment
              </Button>
            </Link>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
export default AppointmentDialog;
