import axios from "axios";

import { FP10StateHistory, newFP10StateHistory } from "src/models";
import { useQuery } from "src/query";

export const useFP10StateHistoryQuery = (fp10Id: string) => {
  return useQuery<FP10StateHistory[]>({
    queryKey: ["fp10s", fp10Id, "history"],
    queryFn: async () => {
      const response = await axios.get(`/v1/fp10s/${fp10Id}/state-history/`);
      return response.data.history.map((data: unknown) =>
        newFP10StateHistory(data),
      );
    },
  });
};
