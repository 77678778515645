import {
  SkeletonRow,
  formatDate,
  formatTime,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { differenceInMinutes } from "date-fns";
import { Link as WLink } from "wouter";

import { ClinicianAvailability } from "src/models";

interface IProps {
  clinicianAvailability: ClinicianAvailability[] | undefined;
}

const OneOffAvailabilityTable = ({ clinicianAvailability }: IProps) => {
  return (
    <Card>
      <CardHeader title="One-Off Availability" />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Time Range</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Appointment Type(s)</TableCell>
              <TableCell>Created By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clinicianAvailability === undefined ? (
              <SkeletonRow cols={5} />
            ) : clinicianAvailability.filter(
                (allAvailability) => allAvailability.recurringId === null,
              ).length === 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  No one-off availability found.
                </TableCell>
              </TableRow>
            ) : (
              clinicianAvailability
                .filter(
                  (allAvailability) => allAvailability.recurringId === null,
                )
                .map((availability, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(availability.startAt)}</TableCell>
                    <TableCell>
                      {formatTime(availability.startAt)} -{" "}
                      {formatTime(availability.endAt)}
                    </TableCell>
                    <TableCell>
                      {differenceInMinutes(
                        availability.endAt,
                        availability.startAt,
                      ) /
                        60 >
                      1
                        ? `${(differenceInMinutes(availability.endAt, availability.startAt) / 60).toFixed(1).replace(/[.,]0$/, "")} hours`
                        : `${(differenceInMinutes(availability.endAt, availability.startAt) / 60).toFixed(1).replace(/[.,]0$/, "")} hour`}
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {availability.appointmentTypes
                        .join(", ")
                        .replace("_", "-")
                        .toLowerCase()}
                    </TableCell>
                    <TableCell>
                      <Link
                        component={WLink}
                        to={`/staff/${availability.createdBy}`}
                      >
                        {availability.createdByEmail}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OneOffAvailabilityTable;
