import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import PaginatedSearch from "src/components/PaginatedSearch";
import { FP10 } from "src/models";
import FP10sTable from "src/pages/FP10s/FP10Table";
import { usePaginationSettings } from "src/pagination";

type TabState = "all" | "destroyed";

const FP10s = () => {
  const [tab, setTab] = useHash<TabState>("all");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [
    paginationSettings,
    { setPageNumber, setPageSize, setSearchQuery, toggleSort },
  ] = usePaginationSettings<FP10>({
    pageNumber: 0,
    pageSize: 100,
    sortColumn: "stateTimestamp",
    sortDirection: "desc",
  });
  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={"/fp10s/destroy/"}>
          Add new destroyed FP10
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title="FP10 Log"
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="All" value="all" />
            <Tab label="Destroyed" value="destroyed" />
          </TabList>
          <Divider />
          <CardContent>
            <PaginatedSearch
              setSearchQuery={setSearchQuery}
              paginationSettings={paginationSettings}
              label="Search by serial number"
            />
          </CardContent>
          <FP10sTable
            state={tab}
            paginationSettings={paginationSettings}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            toggleSort={toggleSort}
          />
        </TabContext>
      </Card>
    </>
  );
};

export default FP10s;
