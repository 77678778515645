import { SkeletonRow, sortByKey } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState } from "react";

import { Appointment } from "src/models";
import AppointmentsRow from "src/pages/Patient/AppointmentsRow";

type Direction = "asc" | "desc";
type OrderableProperties = "startAt";

interface IProps {
  appointments: Appointment[] | undefined;
  title: string;
}

const AppointmentsTable = ({ appointments, title }: IProps) => {
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("startAt");

  const sortKey = (appointments: Appointment) => [appointments[orderBy]];

  let rows;
  if (appointments === undefined) {
    rows = <SkeletonRow cols={7} />;
  } else if (appointments.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={7}>No {title.toLowerCase()}.</TableCell>
      </TableRow>
    );
  } else {
    rows = appointments
      .sort(sortByKey(sortKey, order))
      .map((appointment: Appointment, index) => {
        return <AppointmentsRow key={index} appointment={appointment} />;
      });
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Card>
      <CardHeader title={title} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "startAt"}
                  direction={order}
                  onClick={onSortClick("startAt")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>Length</TableCell>
              <TableCell>Clinician</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default AppointmentsTable;
