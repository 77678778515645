import { CircularLoader } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import { Clinician, ClinicianAvailability } from "src/models";
import EditOneOffAvailability from "src/pages/UpdateClinicianAvailability/EditOneOffAvailability";
import EditRecurringAvailability from "src/pages/UpdateClinicianAvailability/EditRecurringAvailability";
import { useClinicianRecurringAvailabilityQuery } from "src/queries/clinicianRecurringAvailability";

type TabState = "one-off" | "recurring";

interface IProps {
  availability: ClinicianAvailability;
  clinician: Clinician;
  onClose: () => void;
  open: boolean;
  openEditRecurring: () => void;
}

const EditAvailabilityDialog = ({
  availability,
  clinician,
  onClose,
  open,
}: IProps) => {
  const [tab, setTab] = useState<TabState>("one-off");
  const { data: recurringAvailability } =
    useClinicianRecurringAvailabilityQuery(clinician.id);

  if (availability === undefined) {
    return <CircularLoader size="small" />;
  }

  let recurring;
  if (availability.recurringId !== null) {
    recurring = recurringAvailability?.find(
      (recurring) => recurring.id === availability.recurringId,
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <TabContext value={tab}>
        {availability.recurringId !== null ? (
          <DialogContent sx={{ paddingBottom: 0 }}>
            <TabList
              aria-label="Update availability menu"
              onChange={(_, value: string) => setTab(value as TabState)}
            >
              <Tab label="One-Off" value="one-off" />
              <Tab label="Recurring" value="recurring" />
            </TabList>
            <Divider />
          </DialogContent>
        ) : null}
        <TabPanel value="one-off">
          <EditOneOffAvailability
            clinician={clinician}
            onClose={onClose}
            selectedAvailability={availability}
          />
        </TabPanel>
        <TabPanel value="recurring">
          {recurring === undefined ? (
            <CircularLoader />
          ) : (
            <EditRecurringAvailability
              clinician={clinician}
              onClose={onClose}
              recurring={recurring}
              selectedAvailability={availability}
            />
          )}
        </TabPanel>
      </TabContext>
    </Dialog>
  );
};
export default EditAvailabilityDialog;
