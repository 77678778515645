import axios from "axios";

import { StaffEmail, newStaffEmail } from "src/models";
import { useQuery } from "src/query";

interface StaffEmailData {
  emails: StaffEmail[];
}

export const useStaffEmailsQuery = (staffId: number) =>
  useQuery<StaffEmailData>({
    queryKey: ["staff-emails", staffId],
    queryFn: async () => {
      const response = await axios.get(`/v1/staff/${staffId}/emails/`);

      return {
        emails: response.data.emails.map((data: unknown) =>
          newStaffEmail(data),
        ),
      };
    },
  });
