import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  AppointmentPayment,
  newAppointmentPayment,
  PaymentAction,
} from "src/models";
import { PaginatedResult, PaginationSettings } from "src/pagination";
import { useQuery, useMutation } from "src/query";

export const useAppointmentPaymentsQuery = (
  pagination?: Partial<PaginationSettings<AppointmentPayment>>,
  action?: PaymentAction,
) => {
  const { rawSearchQuery: _ = "", ...params } = pagination ?? {};
  return useQuery<PaginatedResult<AppointmentPayment>>({
    queryKey: ["appointmentPayments", pagination, action],
    queryFn: async () => {
      const response = await axios.get("/v1/appointment-payments/", {
        params: {
          ...params,
          action,
        },
      });
      return {
        rows: response.data.payments.map((data: unknown) =>
          newAppointmentPayment(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
    placeholderData: keepPreviousData,
  });
};

export const useAppointmentsPaymentsQuery = (appointmentId: string) => {
  return useQuery<AppointmentPayment[]>({
    queryKey: ["appointmentPayments", appointmentId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-payments/appointment/${appointmentId}/`,
      );
      return response.data.payments.map((json: unknown) =>
        newAppointmentPayment(json),
      );
    },
    enabled: appointmentId !== undefined,
  });
};

interface IImportAppointmentPayment {
  paymentIntentId: string;
}

export const useImportAppointmentPaymentMutation = (appointmentId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IImportAppointmentPayment) =>
      await axios.post(`/v1/appointment-payments/${appointmentId}/import/`, {
        paymentIntentId: data.paymentIntentId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["appointmentPayments", appointmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointments", appointmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ["appointmentHistory", appointmentId],
      });
    },
  });
};
