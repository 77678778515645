import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { Prescriber } from "src/models";
import { usePrescribersQuery } from "src/queries/prescribers";

interface IProps {
  clinicianId: string;
}

const PrescribingDetails = ({ clinicianId }: IProps) => {
  const { data: prescribers } = usePrescribersQuery();

  const clinicianPrescribingDetails = prescribers?.find(
    (prescriber: Prescriber) => prescriber.clinicianId === clinicianId,
  );

  return (
    <Card>
      <CardHeader title="Prescribing Details" />
      <TableContainer>
        <Table>
          {prescribers === undefined ? (
            <TableBody>
              <SkeletonRow cols={1} />
            </TableBody>
          ) : prescribers && !clinicianPrescribingDetails ? (
            <>
              <Divider />
              <TableBody>
                <TableCell>No prescribing details found</TableCell>
              </TableBody>
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>Full Title</TableCell>
                <TableCell>
                  {" "}
                  <Link
                    component={WLink}
                    to={`/prescribers/${clinicianPrescribingDetails?.id}/`}
                  >
                    {clinicianPrescribingDetails?.fullTitle}
                  </Link>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Pin</TableCell>
                <TableCell>{clinicianPrescribingDetails?.pin}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Registration Number</TableCell>
                <TableCell>
                  {clinicianPrescribingDetails?.registrationNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Specialism</TableCell>
                <TableCell>{clinicianPrescribingDetails?.specialism}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Prescriber Type</TableCell>
                <TableCell>
                  {clinicianPrescribingDetails?.prescriberType}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
export default PrescribingDetails;
