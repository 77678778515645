interface IProps {
  color?: string;
  height: number;
}

const Logo = ({ height, color = "currentColor" }: IProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 310.41 161.09"
    height={height}
    width="100"
  >
    <path
      fill={color}
      d="M183.68,86.53c-0.01,10.3,8.03,18.67,17.98,18.71c5.46,0.04,10.65-2.41,14.19-6.71
	c0.66-0.88,1.66-1.4,2.73-1.43c1.73-0.07,3.19,1.32,3.26,3.11c0,0.07,0,0.13,0,0.2c-0.01,0.99-0.39,1.93-1.05,2.64
	c-4.86,5.63-11.85,8.77-19.14,8.58c-14.61,0-25.44-11.56-25.44-27.06c0-15.5,10.83-27.07,24.81-27.07
	c14.19,0,23.54,10.01,23.54,25.2c0,2.53-1.26,3.85-3.89,3.85L183.68,86.53z M217.33,80.59c-0.42-9.68-6.1-16.83-16.3-16.83
	c-9.1,0.12-16.6,7.44-17.25,16.83H217.33z"
    />
    <path
      fill={color}
      d="M175.35,105.36c-1.3,0.1-2.53-0.62-3.11-1.83c-0.5-1.2-0.73-2.49-0.68-3.8v-57c0.01-0.89-0.34-1.74-0.95-2.35
	c-1.47-1.31-3.65-1.31-5.12,0c-0.62,0.61-0.96,1.46-0.95,2.35v57.51c-0.23,3.06,0.5,6.12,2.07,8.72c1.57,1.9,3.94,2.89,6.34,2.66
	c1.13,0.11,2.27-0.23,3.17-0.94c0.65-0.69,0.99-1.63,0.94-2.59c0.02-0.73-0.15-1.45-0.49-2.09
	C176.33,105.54,175.85,105.29,175.35,105.36"
    />
    <path
      fill={color}
      d="M309.77,41.24c-0.51-0.69-1.24-1.16-2.06-1.33c-0.58-0.16-1.18-0.27-1.78-0.32c-1.08-0.11-2.17-0.16-3.25-0.15
	c-5.01-0.31-9.88,1.81-13.18,5.73c-3.31,3.83-4.99,9.29-4.99,16.37v46.68c-0.03,0.92,0.32,1.8,0.96,2.43
	c0.67,0.66,1.57,1.02,2.5,0.98c0.94,0.05,1.85-0.3,2.53-0.97c0.62-0.65,0.95-1.53,0.92-2.44V66.51h11.02
	c0.82,0.03,1.62-0.3,2.2-0.92c0.6-0.64,0.91-1.5,0.88-2.39c0.03-0.83-0.28-1.64-0.86-2.22c-0.59-0.59-1.38-0.9-2.19-0.88h-10.97
	l0.06-0.98c0-3.43,1.07-6.77,3.05-9.53c1.99-2.52,5.07-3.85,8.21-3.53c0.9-0.01,1.8,0.04,2.69,0.17c0.64,0.1,1.29,0.14,1.94,0.14
	c0.78,0.03,1.55-0.25,2.15-0.76c0.61-0.65,0.91-1.54,0.82-2.44c-0.01-0.69-0.22-1.35-0.6-1.92"
    />
    <path
      fill={color}
      d="M40.9,99.08c1.67-0.07,3.08,1.27,3.15,3c0,0.07,0,0.13,0,0.19c-0.02,1.09-0.47,2.13-1.26,2.86
	c-4.42,4.39-10.38,6.74-16.5,6.49C10.41,111.62,0,100.07,0,84.56S10.41,57.5,26.29,57.5c5.84-0.03,11.49,2.16,15.87,6.16
	c0.84,0.74,1.34,1.82,1.37,2.97c0,1.83-1.43,3.31-3.19,3.31h-0.07c-0.94,0-1.68-0.22-3.57-2.09c-2.81-2.67-6.49-4.13-10.3-4.07
	c-11.46,0-18.82,9.02-18.82,20.8c0,11.67,7.25,20.69,18.71,20.69c4.38,0.15,8.63-1.56,11.77-4.73c0.72-0.85,1.75-1.36,2.84-1.43"
    />
    <path
      fill={color}
      d="M84.04,57.49c-0.9-0.02-1.78,0.3-2.47,0.89c-0.74,0.63-1.16,1.58-1.14,2.58v29.88
	c0.02,1.92-0.27,3.82-0.84,5.65c-0.51,1.62-1.35,3.11-2.47,4.36c-1.13,1.24-2.48,2.22-3.99,2.89c-3.49,1.42-7.36,1.42-10.85,0
	c-1.51-0.67-2.87-1.66-4-2.89c-1.12-1.25-1.96-2.74-2.47-4.36c-0.57-1.82-0.86-3.73-0.84-5.65V60.96c0.03-1-0.39-1.95-1.14-2.58
	c-0.7-0.59-1.57-0.91-2.47-0.89c-0.89-0.03-1.76,0.31-2.42,0.94c-0.67,0.66-1.03,1.58-1,2.53v29.67c-0.03,2.82,0.39,5.63,1.24,8.31
	c0.79,2.47,2.07,4.75,3.76,6.67c1.73,1.94,3.86,3.46,6.23,4.44c2.72,1.1,5.62,1.63,8.53,1.58c2.88,0.05,5.75-0.49,8.43-1.58
	c2.37-0.98,4.5-2.5,6.23-4.44c1.69-1.93,2.99-4.2,3.81-6.66c0.88-2.67,1.32-5.49,1.29-8.31V60.96c0.03-0.95-0.33-1.88-1-2.53
	C85.8,57.8,84.93,57.46,84.04,57.49"
    />
    <path
      fill={color}
      d="M161.52,106.01c-0.24-0.46-0.72-0.71-1.21-0.64c-1.3,0.1-2.53-0.62-3.1-1.82c-0.5-1.19-0.73-2.49-0.68-3.79
	V60.82c0.01-0.88-0.33-1.73-0.95-2.35c-1.47-1.31-3.64-1.31-5.11,0c-0.62,0.61-0.96,1.46-0.95,2.35v7
	c-1.55-2.53-3.53-4.76-5.83-6.57c-3.5-2.59-7.72-3.91-12.02-3.75c-3.23-0.03-6.44,0.67-9.38,2.07c-2.84,1.36-5.38,3.28-7.49,5.67
	c-2.18,2.51-3.88,5.43-5,8.61c-1.22,3.45-1.84,7.1-1.81,10.77c-0.03,3.65,0.57,7.28,1.76,10.72c1.1,3.16,2.78,6.07,4.96,8.56
	c2.1,2.38,4.62,4.3,7.43,5.66c2.93,1.4,6.11,2.1,9.33,2.07c4.3,0.1,8.5-1.28,11.97-3.9c2.4-1.85,4.46-4.14,6.09-6.74v-0.38
	c-0.17,2.95,0.55,5.88,2.06,8.38c1.57,1.9,3.93,2.89,6.33,2.65c1.13,0.11,2.26-0.22,3.16-0.94c0.65-0.68,0.99-1.62,0.94-2.58
	C162.02,107.37,161.85,106.65,161.52,106.01 M132.23,104.19c-10.61,0-17.26-8.81-17.26-19.68s7.1-19.68,17.26-19.68
	c9.2,0,17.26,8.31,17.26,19.68C149.5,96.57,141.43,104.19,132.23,104.19"
    />
    <path
      fill={color}
      d="M133.57,50.89c0.99,0.07,1.96-0.33,2.63-1.08c0.57-0.71,0.86-1.61,0.83-2.54c0-0.99-0.7-4.04-5.39-4.04
	c-7.39-0.13-14.62,2.18-20.66,6.58c-7.61,5.27-10.12,13.88-10.79,14.93V60.9c0.13-1.75-1.13-3.29-2.83-3.43
	c-0.21-0.02-0.42-0.01-0.63,0.02c-0.93-0.05-1.85,0.3-2.53,0.97c-0.62,0.65-0.95,1.53-0.92,2.43v47.21
	c-0.04,0.99,0.36,1.94,1.08,2.59c0.69,0.62,1.57,0.95,2.48,0.92c0.91,0.03,1.79-0.31,2.45-0.95c0.67-0.67,1.04-1.6,1-2.57V91.21
	c-0.21-4.47-0.01-8.94,0.6-13.37c0.81-4.38,2.32-8.58,4.48-12.43c1.96-3.61,4.68-6.73,7.93-9.14c0.6-0.43,1.21-0.83,1.84-1.19
	c5.7-2.99,12.04-4.43,18.42-4.18"
    />
    <path
      fill={color}
      d="M280.34,106.01c-0.24-0.46-0.72-0.71-1.21-0.64c-1.3,0.1-2.53-0.62-3.1-1.82c-0.5-1.19-0.73-2.49-0.68-3.79
	V60.82c0.01-0.88-0.33-1.73-0.95-2.35c-1.47-1.31-3.64-1.31-5.11,0c-0.62,0.61-0.96,1.46-0.95,2.35v7
	c-1.55-2.53-3.53-4.76-5.83-6.57c-3.5-2.59-7.73-3.91-12.02-3.75c-3.23-0.03-6.44,0.67-9.38,2.07c-2.84,1.36-5.39,3.28-7.5,5.67
	c-2.18,2.51-3.87,5.43-4.99,8.61c-1.22,3.45-1.84,7.1-1.81,10.77c-0.03,3.65,0.57,7.28,1.76,10.72c1.1,3.16,2.78,6.07,4.95,8.56
	c2.1,2.38,4.62,4.3,7.43,5.66c2.93,1.4,6.11,2.1,9.33,2.07c4.3,0.1,8.5-1.28,11.97-3.9c2.4-1.86,4.46-4.15,6.09-6.76v-0.38
	c-0.17,2.95,0.55,5.88,2.06,8.38c1.57,1.9,3.93,2.89,6.33,2.65c1.13,0.11,2.26-0.22,3.17-0.94c0.65-0.68,0.99-1.62,0.94-2.58
	c0.02-0.73-0.15-1.45-0.49-2.09 M251.06,104.19c-10.61,0-17.26-8.81-17.26-19.68s7.1-19.68,17.26-19.68
	c9.2,0,17.27,8.31,17.27,19.68C268.32,96.57,260.25,104.19,251.06,104.19"
    />
    <path
      fill={color}
      d="M182.17,0.11c-0.66-0.66-5.6,1.83-10.74,2.4c-5.13,0.57-12,0.91-19.2,8.31c-9.6,9.87-4.68,24.26-4.29,23.24
	c0.39-1.03-2.78-11.07,6.12-20.33c8.89-9.26,22.12-7.92,22.12-7.92s-1,15.59-8.53,23.17c-3.5,3.86-8.25,6.26-13.34,6.74l0.35-1.73
	c0.22-1.52,0.54-3.02,0.96-4.5c0.43-1.4,1.05-2.73,1.84-3.95c0.8-1.24,1.71-2.4,2.71-3.47c0.25-0.27,0.49-0.56,0.75-0.82
	c0.05-0.06,0.62-0.59,0.44-0.69c-0.13-0.07-0.33,0-0.46,0.04c-0.32,0.1-0.62,0.24-0.9,0.41c-0.43,0.24-0.85,0.52-1.25,0.83
	c-0.53,0.4-1.02,0.85-1.49,1.32c-0.58,0.58-1.13,1.2-1.64,1.86c-0.61,0.79-1.17,1.61-1.68,2.47c-0.6,1.01-1.14,2.06-1.62,3.14
	c-0.7,1.56-1.2,3.19-1.86,4.75c-0.68,1.58-1.48,3.11-2.39,4.56c-0.63,1.18-1.71,2.02-2.98,2.33c-0.49,0.12-0.99,0.18-1.49,0.2
	c-1.01,0.01-2.01,0.31-2.87,0.86c-1.55,1.18-1.98,3.38-1,5.09c0.98,1.61,3.59,1.63,5.14,1.08c1.77-0.87,3.28-2.22,4.37-3.9
	l0.07-0.08l0.15-0.21l0.24-0.33l0.3-0.43l0.35-0.5c0.13-0.18,0.26-0.37,0.38-0.55l0.4-0.57l0.4-0.58c0.13-0.19,0.26-0.37,0.4-0.56
	l0.37-0.52l0.32-0.45l0.26-0.37l0.14-0.19c0.04-0.06,0.08-0.12,0.13-0.18c0.12-0.1,0.28-0.15,0.43-0.16
	c0.13-0.01,0.26-0.02,0.4-0.02l0.16-0.01c0.06,0,0.12-0.02,0.18-0.02c1.78-0.24,3.53-0.63,5.25-1.17c2.52-0.75,4.91-1.9,7.1-3.41
	c0.84-0.6,1.63-1.27,2.35-2.01c8.68-8.92,9.6-19.39,10.82-24.34C181.05,3.97,182.84,0.78,182.17,0.11"
    />
    <path
      fill={color}
      d="M20.96,153.67l-0.73-0.47c-0.82-0.52-1.73-0.22-2.49,0.83c-1.44,2.13-3.1,3.07-5.39,3.07
	c-4.1,0-6.36-3.21-6.36-9.05c0-5.7,2.45-9.24,6.4-9.24c2.21,0,3.7,0.87,4.83,2.82c0.65,1.16,1.49,1.59,2.37,1.2l0.7-0.29
	c0.66-0.29,0.97-0.84,0.97-1.66c0-0.33-0.1-0.61-0.21-0.88c-1.7-3.43-4.65-5.17-8.76-5.17c-6.68,0-10.66,4.95-10.66,13.23
	c0,8.04,4.03,13.04,10.53,13.04c3.99,0,6.99-1.62,9.15-4.96c0.28-0.44,0.38-0.98,0.27-1.49C21.5,154.21,21.27,153.87,20.96,153.67"
    />
    <path
      fill={color}
      d="M28.68,125.89h-0.41c-1.1,0-1.99,0.93-1.99,2.06v30.49c0,1.14,0.89,2.06,1.99,2.06h0.41
	c1.1,0,1.99-0.93,1.99-2.06v-30.49C30.67,126.82,29.78,125.89,28.68,125.89"
    />
    <path
      fill={color}
      d="M39.19,135.39h-0.41c-1.1,0-1.99,0.93-1.99,2.06v21c0,1.14,0.89,2.06,1.99,2.06h0.41c1.1,0,1.99-0.93,1.99-2.06
	v-21C41.18,136.32,40.29,135.39,39.19,135.39"
    />
    <path
      fill={color}
      d="M39.42,125.75h-0.83c-1.1,0-1.99,0.92-1.99,2.06v1.28c0,1.14,0.89,2.06,1.99,2.06h0.83
	c1.1,0,1.99-0.92,1.99-2.06v-1.28C41.41,126.68,40.52,125.75,39.42,125.75"
    />
    <path
      fill={color}
      d="M59.33,134.82c-2.83,0-5.27,1.13-7.83,3.64v-1c0-1.21-0.72-2.06-1.76-2.06h-0.69c-1.04,0-1.76,0.85-1.76,2.06
	v20.99c0,1.14,0.89,2.07,1.99,2.07h0.41c1.1,0,1.99-0.93,1.99-2.07v-15.9c2.43-2.59,4.32-3.65,6.5-3.65c2.59,0,3.69,1.36,3.69,4.54
	v15.01c0,1.14,0.9,2.07,1.99,2.07h0.41c1.1,0,1.99-0.93,1.99-2.07v-14.96C66.28,137.58,64.07,134.82,59.33,134.82"
    />
    <path
      fill={color}
      d="M74.53,135.39h-0.41c-1.1,0-1.99,0.93-1.99,2.06v21c0,1.14,0.89,2.06,1.99,2.06h0.41c1.1,0,1.99-0.93,1.99-2.06
	v-21C76.52,136.32,75.62,135.39,74.53,135.39"
    />
    <path
      fill={color}
      d="M74.76,125.75h-0.83c-1.1,0-1.99,0.92-1.99,2.06v1.28c0,1.14,0.89,2.06,1.99,2.06h0.83
	c1.1,0,1.99-0.92,1.99-2.06v-1.28C76.75,126.68,75.85,125.75,74.76,125.75"
    />
    <path
      fill={color}
      d="M100.93,154.63c-0.09-0.42-0.31-0.76-0.62-0.96l-0.74-0.48c-0.82-0.52-1.73-0.22-2.49,0.83
	c-1.44,2.12-3.1,3.07-5.39,3.07c-4.1,0-6.36-3.21-6.36-9.05c0-5.7,2.45-9.24,6.4-9.24c2.2,0,3.69,0.87,4.83,2.82
	c0.65,1.16,1.49,1.59,2.37,1.2l0.7-0.29c0.66-0.29,0.98-0.84,0.98-1.66c0-0.33-0.11-0.61-0.21-0.88c-1.7-3.44-4.65-5.18-8.75-5.18
	c-6.68,0-10.67,4.94-10.67,13.23c0,8.04,4.04,13.04,10.53,13.04c4,0,6.99-1.62,9.16-4.96C100.94,155.69,101.04,155.14,100.93,154.63
	"
    />
  </svg>
);

export default Logo;
