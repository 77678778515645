import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { Prescription, newPrescription } from "src/models";

export const usePatientTravelLetterPrescriptionsQuery = (
  patientId: string | undefined,
) => {
  return useQuery<Prescription[]>({
    queryKey: ["patientPrescriptions", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/prescriptions/patient/${patientId}/`,
      );

      return response.data.prescriptions.map((data: unknown) =>
        newPrescription(data),
      );
    },
    enabled: patientId !== undefined,
  });
};

export const useTravelLetterPrescriptionsQuery = (
  travelLetterId: string | undefined,
) => {
  return useQuery<Prescription[]>({
    queryKey: ["travelLetterPrescriptions", travelLetterId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/prescriptions/travel-letter/${travelLetterId}/`,
      );

      return response.data.prescriptions.map((data: unknown) =>
        newPrescription(data),
      );
    },
    enabled: travelLetterId !== undefined,
  });
};
