import { formatDate } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import TravelLetterActions from "src/components/TravelLetterActions";
import { TravelLetter } from "src/models";

interface IProps {
  letter: TravelLetter;
}

const TravelLetterRow = ({ letter }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const destinationList: any = [];
  letter.itinerary.forEach((part) => {
    destinationList.push(part.destination);
  });

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/travel-letters/${letter.travelLetterId}/`}
        >
          View
        </MenuItem>
        <TravelLetterActions letter={letter} />
      </Menu>
      <TableRow key={letter.travelLetterId}>
        <TableCell>{formatDate(letter.timestamp)}</TableCell>
        <TableCell>
          <Link
            component={WLink}
            href={`/travel-letters/${letter.travelLetterId}/`}
          >
            {letter.travelLetterId}
          </Link>
        </TableCell>
        <TableCell>{destinationList.join(", ")}</TableCell>
        <TableCell>{formatDate(letter.itinerary[0].arrival)}</TableCell>
        <TableCell>{formatDate(letter.itinerary[0].departure)}</TableCell>
        <TableCell>
          <Link component={WLink} href={`/patients/${letter.patientId}/`}>
            {letter?.patientName}
          </Link>
        </TableCell>
        <TableCell>
          <Link component={WLink} href={`/staff/${letter?.assignedTo}/`}>
            {letter.assignedToEmail?.replace(
              /@(curealeafclinic\.com|curaleafint\.com)/,
              "",
            ) ?? ""}
          </Link>
        </TableCell>
        <TableCell>
          {letter.requiresPrescriberApproval ? "Yes" : "No"}
        </TableCell>
        <TableCell>{letter.letterPdfPresent ? "Yes" : "No"}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TravelLetterRow;
