import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import Products from "src/components/Products";
import OutOfStockListingDetails from "src/pages/OutOfStockListing/OutOfStockListingDetails";
import OutOfStockHistoryTable from "src/pages/OutOfStockListing/OutOfStockListingHistoryTable";
import { useOutOfStockListingQuery } from "src/queries";

type TabState = "details" | "products" | "history";
interface IProps {
  listingId: string;
}

const OutOfStockListing = ({ listingId }: IProps) => {
  const { data: listing } = useOutOfStockListingQuery(listingId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (listing === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          to={`/out-of-stock-listings/${listingId}/edit/`}
        >
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/out-of-stock-listings/${listingId}/close/`}
        >
          Mark in stock
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={listing?.productName ?? ""}
        breadcrumbs={[
          { label: "Out of Stock Listings", to: "/out-of-stock-listings/" },
          { label: `${listing.productName}` },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList onChange={(_, value: string) => setTab(value as TabState)}>
            <Tab label="Details" value="details" />
            <Tab label="Products" value="products" />
            <Tab label="History" value="history" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <OutOfStockListingDetails listingId={listingId} />
        </TabPanel>
        <TabPanel value="products">
          <Products productIds={listing.alternativeProducts} />
        </TabPanel>
        <TabPanel value="history">
          <OutOfStockHistoryTable listingId={listingId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default OutOfStockListing;
