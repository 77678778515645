import {
  FormLinks,
  SkeletonRow,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  formatDate,
  sortByKey,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, Link as WLink } from "wouter";
import * as z from "zod";

import { Itinerary, Prescription } from "src/models";
import {
  useCancelTravelLetterMutation,
  usePatientQuery,
  useTravelLetterPrescriptionsQuery,
  useTravelLetterQuery,
} from "src/queries";

interface IProps {
  travelLetterId: string;
}

const FormSchema = z.object({
  reason: z.string().min(5, "A reason is required"),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CancelTravelLetter = ({ travelLetterId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: letter } = useTravelLetterQuery(travelLetterId);
  const { data: prescriptions } =
    useTravelLetterPrescriptionsQuery(travelLetterId);
  const { mutateAsync: cancelTravelLetter } =
    useCancelTravelLetterMutation(travelLetterId);
  const { data: patient } = usePatientQuery(letter?.patientId);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      reason: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await cancelTravelLetter(data);
      addToast("Travel letter cancelled", "success");
      setLocation("/travel-letters/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Cancel travel letter" />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${letter?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created by</TableCell>
                  <TableCell>
                    <Link component={WLink} href={`/staff/${letter?.staffId}/`}>
                      {letter?.staffEmail ?? patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardHeader title="Itinerary" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Arrival</TableCell>
                <TableCell>Departure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {letter?.itinerary
                .sort(sortByKey((destination) => [destination.arrival], "asc"))
                .map((part: Itinerary) => (
                  <TableRow key={part.destination}>
                    <TableCell>{part.destination}</TableCell>
                    <TableCell>{formatDate(part.arrival)}</TableCell>
                    <TableCell>{formatDate(part.departure)}</TableCell>
                  </TableRow>
                )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <CardHeader title="Prescriptions" />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Last Printed</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptions?.map((prescription: Prescription) => (
                <TableRow key={prescription.id}>
                  <TableCell>{prescription.productName}</TableCell>
                  <TableCell>{formatDate(prescription.issueDate)}</TableCell>
                  <TableCell>{prescription.quantity}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom variant="body2">
                The reason will be displayed to the patient.
              </Typography>
              <TextField fullWidth label="Reason" name="reason" required />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={letter === undefined}
                label="Cancel Letter"
              />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/travel-letters/${travelLetterId}/`,
                  },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CancelTravelLetter;
