import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

import { AppointmentType } from "src/models/appointment";

const appointmentSlotSchema = z.object({
  appointmentType: z.nativeEnum(AppointmentType),
  clinicianId: z.string().uuid(),
  clinicianName: z.string(),
  duration: z.coerce.number().int().positive(),
  length: z.coerce.number().int().positive(),
  minimumHoursBeforeBooking: z.coerce.number().int().positive(),
  price: zodDecimal(),
  start: z.coerce.date(),
});

export type AppointmentSlot = z.infer<typeof appointmentSlotSchema>;

export const newAppointmentSlot = (data: unknown): AppointmentSlot =>
  appointmentSlotSchema.parse(data);
