import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import FP10Details from "src/pages/FP10/FP10Details";
import FP10StateHistory from "src/pages/FP10/FP10StateHistory";
import { useFP10Query } from "src/queries";

type TabState = "details" | "history";

interface IProps {
  fp10Id: string;
}

const FP10 = ({ fp10Id }: IProps) => {
  const { data: fp10 } = useFP10Query(fp10Id);
  const [tab, setTab] = useHash<TabState>("details");

  return fp10 === undefined ? (
    <CircularLoader />
  ) : (
    <>
      <Title
        title={fp10.serial}
        breadcrumbs={[
          { to: "/fp10s/", label: "FP10s" },
          { label: `${fp10.serial}` },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList
            aria-label="FP10 menu"
            onChange={(_, value: string) => setTab(value as TabState)}
          >
            <Tab label="Details" value="details" />
            <Tab label="History" value="history" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <FP10Details fp10Id={fp10Id} />
        </TabPanel>
        <TabPanel value="history">
          <FP10StateHistory fp10Id={fp10Id} />
        </TabPanel>
      </TabContext>
    </>
  );
};
export default FP10;
