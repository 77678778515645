import { AutocompleteField } from "@curaleaf-international/components";
import { countries } from "countries-list";
import type { TCountryCode } from "countries-list";

type IProps = Omit<React.ComponentProps<typeof AutocompleteField>, "options">;

const DestinationField = (props: IProps) => {
  const countryNames = Object.keys(countries).map(
    (key) => countries[key as TCountryCode].name,
  );

  return (
    <AutocompleteField
      {...props}
      options={countryNames.map((countryName) => ({
        value: countryName,
      }))}
    />
  );
};

export default DestinationField;
