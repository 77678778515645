import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TablePagination from "src/components/TablePagination";
import TableSortLabel from "src/components/TableSortLabel";
import { AppointmentPayment, PaymentAction } from "src/models";
import PaymentRow from "src/pages/AppointmentPayments/PaymentRow";
import { PaginationSettings } from "src/pagination";
import { useAppointmentPaymentsQuery } from "src/queries";

interface IProps {
  action: PaymentAction;
  paginationSettings: PaginationSettings<AppointmentPayment>;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  toggleSort: (columnName: keyof AppointmentPayment) => void;
}

const PaymentsTable = ({
  action,
  paginationSettings,
  setPageNumber,
  setPageSize,
  toggleSort,
}: IProps) => {
  const { data } = useAppointmentPaymentsQuery(paginationSettings, action);

  const { rows: payments, totalRecordCount } = data || {};
  let rows = null;
  if (!payments) {
    rows = <SkeletonRow cols={6} />;
  } else {
    rows = (
      <TableRow>
        <TableCell colSpan={6}>No Payments found.</TableCell>
      </TableRow>
    );
    rows = payments.map((payment) => (
      <PaymentRow key={payment.id} payment={payment} />
    ));
  }

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>
              <TableSortLabel<AppointmentPayment>
                columnName={"stateTimestamp"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                State & Timestamp
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel<AppointmentPayment>
                columnName={"amount"}
                paginationSettings={paginationSettings}
                toggleSort={toggleSort}
              >
                Amount
              </TableSortLabel>
            </TableCell>
            <TableCell>Appointment ID</TableCell>
            <TableCell>Processor</TableCell>
            <TableCell>Xero ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
      <TablePagination<AppointmentPayment>
        recordCount={totalRecordCount}
        paginationSettings={paginationSettings}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        rowsPerPageOptions={[100, 500, 750, 1000]}
      />
    </TableContainer>
  );
};

export default PaymentsTable;
