import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react/jsx-runtime";

import { useClinicianQuery } from "src/queries";
import { useClinicianAppointmentLengthsQuery } from "src/queries/appointmentLengths";

interface IProps {
  clinicianId: string;
}

const AppointmentLengths = ({ clinicianId }: IProps) => {
  const { data: appointmentLengths } =
    useClinicianAppointmentLengthsQuery(clinicianId);
  const { data: clinician } = useClinicianQuery(clinicianId);

  return (
    <Card>
      <CardHeader title="Appointment Lengths" />
      <TableContainer>
        <Table>
          {appointmentLengths === undefined || clinician === undefined ? (
            <TableBody>
              <SkeletonRow cols={1} />
            </TableBody>
          ) : clinician.appointmentTypes.length === 0 ? (
            <>
              <Divider />
              <TableBody>
                <TableCell>No appointment types assigned.</TableCell>
              </TableBody>
            </>
          ) : (
            clinician.appointmentTypes.map((appType, index) => (
              <Fragment key={index}>
                <TableHead>
                  <TableRow>
                    <TableCell>{appType.replace("_", "-")}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Length</TableCell>
                    <TableCell>
                      {appointmentLengths.find(
                        (apptLengths) =>
                          apptLengths.appointmentType === appType,
                      )?.length ?? "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Buffer</TableCell>
                    <TableCell>
                      {appointmentLengths.find(
                        (apptLengths) =>
                          apptLengths.appointmentType === appType,
                      )?.buffer ?? "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Fragment>
            ))
          )}
        </Table>
      </TableContainer>
    </Card>
  );
};
export default AppointmentLengths;
