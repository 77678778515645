import {
  SelectField,
  SubmitButton,
  formatTime,
} from "@curaleaf-international/components";
import { EventInput, FormatterInput } from "@fullcalendar/core/index.js";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import {
  formatISO,
  parseISO,
  addDays,
  addMinutes,
  startOfToday,
  endOfTomorrow,
  endOfDay,
  startOfDay,
} from "date-fns";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { CalendarStyleContainer } from "src/components/CalendarStyleContainer";
import { Appointment, AppointmentStatus } from "src/models/appointment";
import AppointmentDialog from "src/pages/Appointments/AppointmentDialog";
import { useAppointmentsQuery } from "src/queries";
import { getAppointmentColour } from "src/utils";

interface IProps {
  clinicians: { label: string; value: string }[];
}
const FormSchema = z.object({
  after: z.coerce.date(),
  clinician: z.string(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

export const titleFormat: FormatterInput = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

const AppointmentsCalendar = ({ clinicians }: IProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState<
    Appointment | undefined
  >(undefined);
  const [appointmentEvents, setAppointmentEvents] = useState<EventInput[]>([]);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [_, setLocation] = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const after = searchParams.has("after")
    ? parseISO(searchParams.get("after") ?? "")
    : startOfToday();
  const before = searchParams.has("before")
    ? parseISO(searchParams.get("before") ?? "")
    : endOfTomorrow();
  const clinician = searchParams.get("clinician") ?? "";
  const { data: appointments } = useAppointmentsQuery(
    after,
    before,
    clinician,
    AppointmentStatus.CONFIRMED,
  );
  const calendarRef = useRef<FullCalendar>(null);
  const theme = useTheme();

  const defaultValues = {
    after: after,
    clinician: clinician,
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ValidatedType) => {
    const params: any = {};
    if (calendarRef.current) {
      const startDate = calendarRef.current.getApi().view.activeStart;
      const endDate = calendarRef.current.getApi().view.activeEnd;
      params["after"] = formatISO(startOfDay(startDate), {
        representation: "date",
      });
      params["before"] = formatISO(endOfDay(endDate), {
        representation: "date",
      });
    } else {
      params["after"] = formatISO(startOfDay(data.after), {
        representation: "date",
      });
      params["before"] = formatISO(endOfDay(addDays(data.after, 1)), {
        representation: "date",
      });
    }
    params["clinician"] = data.clinician;
    const newSearchParams = new URLSearchParams(params as any);
    setLocation(`/appointments/?${newSearchParams.toString()}`, {
      replace: true,
    });
    methods.reset(data);
  };

  const getAppointmentCalendarEvents = () => {
    const events: EventInput[] = [];
    if (appointments !== undefined) {
      appointments.forEach((appointment) => {
        const event = {
          id: appointment.id,
          title: `${appointment.patientName}: ${formatTime(appointment.startAt)}, ${appointment.type
            .split("_")
            .join(" ")
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())}`,
          start: appointment.startAt,
          end: addMinutes(appointment.startAt, appointment.length),
          color: getAppointmentColour(appointment, theme),
        };
        events.push(event);
      });
    }
    setAppointmentEvents(events);
  };

  useEffect(() => {
    getAppointmentCalendarEvents();
  }, [appointments]);

  const handleDateChange = (start: Date, end: Date) => {
    const params: any = {};
    params["clinician"] = clinician;
    params["after"] = formatISO(startOfDay(start), { representation: "date" });
    params["before"] = formatISO(endOfDay(end), { representation: "date" });
    const newSearchParams = new URLSearchParams(params as any);
    setLocation(`/appointments/?${newSearchParams.toString()}`, {
      replace: true,
    });
    methods.reset();
  };

  const handleEventClick = (appointmentId: string) => {
    if (appointments) {
      setSelectedAppointment(undefined);
      const appointment = appointments.find(
        (appointment) => appointment.id.toString() == appointmentId,
      );
      setSelectedAppointment(appointment);
      setOpenAppointment(true);
    }
  };

  const handleDateClick = (date: Date) => {
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView("timeGridDay", date);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid alignItems="center" container spacing={2} paddingBottom={2}>
                <Grid item sm={10} xs={12}>
                  <SelectField
                    size="small"
                    fullWidth
                    label="Clinician"
                    name="clinician"
                    options={Object.values(clinicians).map(
                      (clinician) => clinician,
                    )}
                    required
                    defaultValue={clinician}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <SubmitButton label="View" fullWidth />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {clinician !== "" ? (
            <CalendarStyleContainer>
              <FullCalendar
                allDaySlot={false}
                dateClick={(event) => handleDateClick(event.date)}
                datesSet={(event) => handleDateChange(event.start, event.end)}
                dayMaxEvents={3}
                displayEventTime={false}
                eventClick={(event) => handleEventClick(event.event.id)}
                events={appointmentEvents}
                firstDay={1}
                headerToolbar={{
                  left: "timeGridDay,timeGridWeek,dayGridMonth",
                  center: "title",
                  right: "prev,next today",
                }}
                initialDate={after}
                initialView="timeGridDay"
                locale="en-IE"
                moreLinkHint="View Day"
                moreLinkText="more"
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                ref={calendarRef}
                slotMinTime="07:00:00"
                slotMaxTime="23:00:00"
                titleFormat={titleFormat}
              />
            </CalendarStyleContainer>
          ) : null}
        </Stack>
        <AppointmentDialog
          onClose={() => {
            setOpenAppointment(false);
          }}
          open={openAppointment}
          selectedAppointment={selectedAppointment ?? undefined}
        />
      </CardContent>
    </Card>
  );
};
export default AppointmentsCalendar;
