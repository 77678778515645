import { formatDateTime } from "@curaleaf-international/components";
import Visibility from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import EmailPreview from "src/components/EmailPreview";
import { Email } from "src/models";
import { useStaffQuery } from "src/queries";

interface IProps {
  email: Email;
}

const AppointmentEmailRow = ({ email }: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: staff } = useStaffQuery(email?.staffId ?? undefined);

  const showDialog = () => {
    setDialogOpen(!dialogOpen);
  };
  return (
    <>
      <Dialog open={dialogOpen} onClose={showDialog}>
        <EmailPreview email={email} />
      </Dialog>
      <TableRow key={email.id}>
        <TableCell>{email.sent && formatDateTime(email.sent)}</TableCell>
        <TableCell>
          {email.staffId ? (
            <Link component={WLink} href={`/staff/${email.staffId}/`}>
              {staff?.email}
            </Link>
          ) : (
            "Patient"
          )}
        </TableCell>
        <TableCell>{email.ident}</TableCell>
        <TableCell>
          <IconButton onClick={showDialog}>
            <Visibility />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AppointmentEmailRow;
