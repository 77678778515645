import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { usePatientQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  patientId: string;
}

const PatientTriagingDetails = ({ patientId }: IProps) => {
  const { data: patient } = usePatientQuery(patientId);

  if (!patient) {
    return <Skeleton variant="rounded" height="100%" width={"100%"} />;
  }

  return (
    <Card>
      <CardHeader title="Triaging Details" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Assigned Consultant</TableCell>
              <TableCell>
                {patient.assignedConsultantId ? (
                  <Link
                    component={WLink}
                    href={`/clinicians/${patient.assignedConsultantId}/`}
                  >
                    {patient.assignedConsultantName}
                  </Link>
                ) : (
                  "None"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assigned Pharmacist or GP</TableCell>
              <TableCell>
                {patient.assignedPharmacistOrGpId ? (
                  <Link
                    component={WLink}
                    href={`/clinicians/${patient.assignedPharmacistOrGpId}/`}
                  >
                    {patient.assignedPharmacistOrGpName}
                  </Link>
                ) : (
                  "None"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Has follow-ups with</TableCell>
              <TableCell>
                <Value
                  text={convertEnumValueToReadableString(
                    patient.patientFollowupPreference ?? "",
                    " ",
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary Diagnosis</TableCell>
              <TableCell>
                <Value
                  text={convertEnumValueToReadableString(
                    patient.primaryDiagnosis ?? "",
                    " ",
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientTriagingDetails;
