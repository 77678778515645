import { SkeletonRow, sortByKey } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { TravelLetter } from "src/models";
import PatientTravelLetterRow from "src/pages/Patient/PatientTravelLetterRow";
import { usePatientTravelLettersQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientTravelLetters = ({ patientId }: IProps) => {
  const { data: travelLetters } = usePatientTravelLettersQuery(patientId);
  const letters = travelLetters?.sort(
    sortByKey((letter) => [letter.itinerary[0].arrival], "asc"),
  );

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Letter Id</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Arrival Date</TableCell>
              <TableCell>Return Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {letters === undefined ? (
              <SkeletonRow cols={6} />
            ) : letters.length === 0 ? (
              <TableRow>
                <TableCell>No travel letters found.</TableCell>
              </TableRow>
            ) : (
              letters.map((letter: TravelLetter) => (
                <PatientTravelLetterRow
                  key={`${letter.travelLetterId}`}
                  letter={letter}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default PatientTravelLetters;
