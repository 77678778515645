import {
  CircularLoader,
  DateTimeField,
  SelectField,
  SubmitButton,
  formatDateWithDay,
  formatTime,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { isSameDay } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { AppointmentType } from "src/models/appointment";
import { Clinician } from "src/models/clinician";
import { ClinicianAvailability } from "src/models/clinicianAvailability";

const FormSchema = z.object({
  appointmentTypes: z.array(z.nativeEnum(AppointmentType)),
  endAt: z.date(),
  startAt: z.date(),
});

export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  availability?: ClinicianAvailability;
  deleteAvailability?: () => void;
  clinician: Clinician;
  editing: boolean;
  initialValues: FormType;
  onClose: () => void;
  onSubmit: (data: ValidatedType) => Promise<any>;
}

const OneOffAvailabilityForm = ({
  availability,
  clinician,
  deleteAvailability,
  editing,
  initialValues,
  onClose,
  onSubmit,
}: IProps) => {
  if (editing && availability === undefined) {
    return <CircularLoader size="small" />;
  }

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  const values = methods.watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent sx={{ paddingTop: 2 }}>
          {editing && availability?.recurringId !== null ? (
            <Alert severity="info" sx={{ width: "100%" }}>
              These changes will only affect this singular block of
              availability.
            </Alert>
          ) : null}
          <Grid container alignItems="center">
            <DateTimeField fullWidth required name="startAt" label="Start" />
            <DateTimeField fullWidth required name="endAt" label="End" />
            {!isSameDay(values.startAt, values.endAt) ? (
              <Alert severity="warning" sx={{ width: "100%" }}>
                Start and end times must be on the same day.
              </Alert>
            ) : null}
            <SelectField
              multiple
              fullWidth
              required
              label="Appointment Types"
              name="appointmentTypes"
              options={clinician.appointmentTypes.map((type) => ({
                label: type
                  .replace("_", "-")
                  .toLowerCase()
                  .replace(/\b\w/g, (char) => char.toUpperCase()),
                value: type,
              }))}
              sx={{ marginBottom: 2 }}
            />
            <Typography variant="subtitle2">
              {editing
                ? `This one-off block of availability will be changed to`
                : `A one-off block of availability will be added for`}{" "}
              {formatTime(values.startAt)} - {formatTime(values.endAt)} on{" "}
              {formatDateWithDay(values.startAt)}.
            </Typography>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button color="inherit" onClick={() => onClose()}>
            Close
          </Button>
          {deleteAvailability ? (
            <Button
              onClick={() => deleteAvailability()}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          ) : null}
          <SubmitButton
            disabled={!isSameDay(values.startAt, values.endAt)}
            label={editing ? "Edit" : "Add"}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
};
export default OneOffAvailabilityForm;
