import * as z from "zod";

const patientTrackingNoteSchema = z.object({
  note: z.string(),
  time: z.coerce.date(),
});

export type PatientTrackingNote = z.infer<typeof patientTrackingNoteSchema>;

export const newPatientTrackingNote = (data: unknown): PatientTrackingNote =>
  patientTrackingNoteSchema.parse(data);
