import { formatDateTime } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";

import { usePatientTrackingNotesQuery } from "src/queries";

interface IProps {
  patientId: string;
}

const PatientTrackingNotesSummary = ({ patientId }: IProps) => {
  const { data: trackingNotes } = usePatientTrackingNotesQuery(patientId);

  let notes;
  if (trackingNotes === undefined) {
    notes = (
      <ListItem>
        <Skeleton width="100%" />
      </ListItem>
    );
  } else if (trackingNotes && trackingNotes.length === 0) {
    notes = (
      <ListItem disableGutters divider>
        <ListItemText primary="No tracking notes." />
      </ListItem>
    );
  } else {
    notes = trackingNotes.slice(0, 3).map((note, index) => {
      return (
        <ListItem disableGutters divider key={index}>
          <ListItemText
            primary={note.note}
            secondary={formatDateTime(note.time)}
          />
        </ListItem>
      );
    });
  }
  return (
    <Card>
      <CardHeader title="Recent Tracking Notes" />
      <Divider />
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        <List disablePadding>{notes}</List>
      </CardContent>
    </Card>
  );
};
export default PatientTrackingNotesSummary;
