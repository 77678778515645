import * as z from "zod";

import { ActivityType } from "src/pages/Activities";

const activitySchema = z.object({
  activity: z.nativeEnum(ActivityType),
  data: z.any().nullable(),
  firebaseUid: z.string().nullable(),
  staffId: z.number().nullable(),
  timestamp: z.coerce.date(),
  staffMemberEmail: z.string().nullable(),
});

export type Activity = z.infer<typeof activitySchema>;

export const newActivity = (data: unknown): Activity =>
  activitySchema.parse(data);
