import { formatDate } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useOutOfStockListingQuery } from "src/queries";

interface IProps {
  listingId: string;
}

const OutOfStockListingDetails = ({ listingId }: IProps) => {
  const { data: listing } = useOutOfStockListingQuery(listingId);

  if (!listing) {
    return <Skeleton height="80px" />;
  }

  return (
    <Card>
      <CardHeader title="Out of stock listing" />
      <Divider />

      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>{listing?.productName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>created</TableCell>
              <TableCell>{`${formatDate(listing.createdOn)} by ${
                listing.createdByEmail
              }`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ended</TableCell>
              <TableCell>
                {listing.endedOn
                  ? `${formatDate(listing.endedOn)} by ${listing.endedByEmail}`
                  : "ACTIVE"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alternative product count</TableCell>
              <TableCell>{listing.alternativeProducts.length}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Patient information</TableCell>
              <TableCell>{listing.patientInformation}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internal information</TableCell>
              <TableCell>{listing.internalInformation}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default OutOfStockListingDetails;
