import { fromUnixTime } from "date-fns";
import * as z from "zod";

import { Role } from "src/models/staff";

export const zFirebaseDateSchema = () =>
  z
    .object({ seconds: z.number() })
    .transform((value) => fromUnixTime(value.seconds));

const allEndpointRolesSchema = z.object({
  allRoles: z.array(z.nativeEnum(Role)),
  endpointRoles: z.array(
    z.object({
      endpoint: z.string(),
      accessibleTo: z.array(z.nativeEnum(Role)),
    }),
  ),
});

export type AllEndpointRoles = z.infer<typeof allEndpointRolesSchema>;
export const newAllEndpointRoles = (data: unknown): AllEndpointRoles =>
  allEndpointRolesSchema.parse(data);

export const brokenSchema = <
  Output,
  Def extends z.ZodTypeDef = z.ZodTypeDef,
  Input = Output,
>(
  expected: z.ZodType<Output, Def, Input>,
) => z.union([z.literal("").transform(() => null), expected]);
