import axios from "axios";

import { Dashboard, newDashboard } from "src/models";
import { useQuery } from "src/query";

export const useDashboardQuery = () => {
  return useQuery<Dashboard>({
    queryKey: ["dashboard"],
    queryFn: async () => {
      const response = await axios.get("/v1/dashboard/");
      return newDashboard(response.data);
    },
  });
};
