import {
  TextField,
  SubmitButton,
  formatDateTime,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useLocation } from "wouter";
import * as z from "zod";

import {
  usePatientResolveEmailIssueMutation,
  usePatientEmailsQuery,
} from "src/queries";

interface IProps {
  patientId: string;
}

const FormSchema = z.object({
  resolution: z.string().min(10),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const typeDescriptions: Record<string, string> = {
  HardBounce:
    "Hard bounce — The server was unable to deliver your message (ex: unknown user, mailbox not found).",
  "SoftBounce/Undeliverable":
    "Soft bounce/Undeliverable — Unable to temporarily deliver message (i.e. mailbox full, account disabled, exceeds quota, out of disk space).",
  SpamComplaint:
    "Spam complaint — The subscriber explicitly marked this message as spam. Please verify with patient this was a mistake and ask in Cooper Qs to reinstate the email status.",
  BadEmailAddress:
    "Email address is incorrect, please edit the patient's email address and resolve this issue.",
};

const ResolveEmailIssue = ({ patientId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data } = usePatientEmailsQuery(patientId);
  const { mutateAsync: resolveIssue } = usePatientResolveEmailIssueMutation();
  const issue = data?.issues.at(0);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      resolution: "",
    },
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await resolveIssue({ resolution: data.resolution, issueId: issue!.id });
      addToast("Resolution updated", "success");
      setLocation(`/patients/${patientId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  let content: any;

  if (data === undefined) content = <Skeleton height="80px" />;
  if (issue === undefined) {
    content = (
      <>
        <CardContent>
          <Typography>No email issues detected</Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            component={Link}
            to={`/patients/${patientId}/`}
            variant="contained"
          >
            Back
          </Button>
        </CardActions>
      </>
    );
  } else {
    content = (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <Typography sx={{ marginBottom: 2 }}>
              The following issue was raised by our email provider:
            </Typography>
            <TableContainer sx={{ marginBottom: 2 }}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Email address</TableCell>
                    <TableCell>
                      <strong>{issue.email}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>{formatDateTime(issue.created)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Issue type</TableCell>
                    <TableCell>
                      {issue.type}:{" "}
                      {typeDescriptions[issue.type] ??
                        "Please report the issue type to the devlopment team."}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography sx={{ marginBottom: 2 }}>
              Resolving the issue will send a request to our email provider to
              send further emails to the patient.
            </Typography>
            <TextField
              fullWidth
              label="Please describe how this issue was resolved (e.g. email address edited)"
              name="resolution"
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Resolve" />
            <FormLinks
              links={[
                {
                  label: "Back",
                  to: `/patients/${patientId}/`,
                },
              ]}
            />
          </CardActions>
        </form>
      </FormProvider>
    );
  }

  return (
    <>
      <Title title="Resolve email issue" />
      <Card>{content}</Card>
    </>
  );
};

export default ResolveEmailIssue;
