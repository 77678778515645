import {
  Search,
  Title,
  useDebounce,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { ChangeEvent, useState } from "react";
import { Link } from "wouter";

import type { States } from "src/pages/Staff/StaffTable";
import StaffTable from "src/pages/Staff/StaffTable";

const Staff = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const [tab, setTab] = useHash<States>("enabled");

  return (
    <>
      <Title
        title="Staff"
        actions={
          <Button component={Link} to="/staff/new/" variant="contained">
            Add Staff Member
          </Button>
        }
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as States)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Enabled" value="enabled" />
            <Tab label="Disabled" value="disabled" />
          </TabList>
          <Divider />
          <CardContent>
            <Search
              fullWidth
              label="Search"
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setRawSearch(event.target.value)
              }
              value={rawSearch}
            />
          </CardContent>
          <StaffTable search={search} state={tab} />
        </TabContext>
      </Card>
    </>
  );
};

export default Staff;
