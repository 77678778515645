import {
  AutocompleteField,
  sortByKey,
} from "@curaleaf-international/components";

import { Product } from "src/models";
import { useProductsQuery } from "src/queries";

interface IOption {
  label?: string;
  value: string;
}
interface IProps {
  type?: string;
  name: string;
  index: number;
}

const ProductItem = ({ index, name, type }: IProps) => {
  const { data: products } = useProductsQuery(false);

  const sortKey = (product: Product) => {
    return [product.type, product.name];
  };

  let options: IOption[] = [];
  if (products !== undefined) {
    let filteredProducts = products.filter(
      (product) =>
        !product.formularies.includes("Other Medicines") &&
        !product.discontinued,
    );
    if (type) {
      filteredProducts = filteredProducts.filter(
        (product) => product.type === type,
      );
    }
    options = filteredProducts
      .sort(sortByKey(sortKey, "asc"))
      .map((product) => ({
        label: product.name,
        value: product.id,
      }));
  }

  return (
    <AutocompleteField
      fullWidth
      label={`Product ${index + 1}`}
      name={`${name}.id`}
      options={options}
      required
      groupBy={(option) => {
        return (
          products?.find((product) => product.id === option.value)?.type ?? ""
        );
      }}
    />
  );
};

export default ProductItem;
