import { ToastContext, Title } from "@curaleaf-international/components";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import ClinicianForm, {
  ValidatedType,
  IAppointmentTypeRecord,
} from "src/components/ClinicianForm";
import { ClinicianType, FollowUpPreference } from "src/models";
import { AppointmentType } from "src/models/appointment";
import { useCreateClinician } from "src/queries";

const CreateClinician = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: createClinician } = useCreateClinician();

  const types = Object.keys(AppointmentType);

  const onSubmit = async (data: ValidatedType) => {
    const appointmentTypes = types.filter(
      (type) => data.appointmentTypes[type],
    );
    try {
      await createClinician({
        appointmentTypes: appointmentTypes as AppointmentType[],
        clinicianType: data.clinicianType,
        minimumHoursBeforeBooking: data.minimumHoursBeforeBooking,
        name: data.name,
        followUpPreference:
          data.clinicianType === ClinicianType.CONSULTANT
            ? data.followUpPreference
            : FollowUpPreference.NOT_APPLICABLE,
        autoApprovalLetters: data.autoApprovalLetters,
        staffId: parseInt(data.staffId),
        specialisms: data.specialisms,
      });
      setLocation("/clinicians/");
      addToast("Clinician Added", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        addToast("Clinician already exists", "error");
      }
      addToast("Try again", "error");
    }
  };
  return (
    <>
      <Title
        title="Add New Clinician"
        breadcrumbs={[
          { label: "Clinicians", to: "/clinicians/" },
          { label: "Create Clinician" },
        ]}
      />
      <ClinicianForm
        initialValues={{
          appointmentTypes: {
            ...types.reduce((accum, type) => {
              accum[type] = false;
              return accum;
            }, {} as IAppointmentTypeRecord),
          },
          clinicianType: ClinicianType.CONSULTANT,
          minimumHoursBeforeBooking: 0,
          name: "",
          followUpPreference:
            FollowUpPreference.SEES_PATIENTS_FOR_FIRST_FOLLOW_UP,
          autoApprovalLetters: [],
          specialisms: [],
          staffId: "",
        }}
        onSubmit={onSubmit}
        back="/clinicians/"
        submitLabel="Add Clinician"
        types={types}
      />
    </>
  );
};

export default CreateClinician;
