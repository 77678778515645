import {
  CircularLoader,
  Title,
  Value,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SelectAppointmentCalendar from "src/components/SelectAppointmentCalendar";
import { useAppointmentQuery } from "src/queries";

interface IProps {
  appointmentId: string;
}

const EditAppointment = ({ appointmentId }: IProps) => {
  const { data: appointment } = useAppointmentQuery(appointmentId);

  return (
    <>
      <Title title="Edit Appointment" />
      <Stack spacing={2}>
        <Card>
          <CardHeader title="Existing Appointment" />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Clinician</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Value text={appointment?.clinicianName} />
                  </TableCell>
                  <TableCell>
                    <Value date={appointment?.startAt} />
                  </TableCell>
                  <TableCell>
                    <Value time={appointment?.startAt} />
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize" }}>
                    <Value
                      text={appointment?.type.toLowerCase().replace("_", "-")}
                    />
                  </TableCell>
                  <TableCell>
                    <Value currency={appointment?.price} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Card>
          <CardHeader
            title="Select New Appointment"
            sx={{ paddingBottom: 0 }}
          />
          <CardContent>
            {appointment !== undefined ? (
              <SelectAppointmentCalendar
                appointment={appointment}
                patientId={appointment.patientId}
              />
            ) : (
              <CircularLoader size="large" />
            )}
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

export default EditAppointment;
