import {
  CircularLoader,
  ToastContext,
} from "@curaleaf-international/components";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useContext } from "react";

import { RecurringAvailabilityType } from "src/models";
import { Clinician } from "src/models/clinician";
import RecurringAvailabilityForm, {
  ValidatedType,
} from "src/pages/UpdateClinicianAvailability/RecurringAvailabilityForm";
import { useCreateClinicianRecurringAvailabilityMutation } from "src/queries/clinicianRecurringAvailability";

interface IProps {
  clinician: Clinician;
  onClose: () => void;
  selectedTime: { start: Date; end: Date } | undefined;
}

const AddRecurringAvailability = ({
  clinician,
  onClose,
  selectedTime,
}: IProps) => {
  const { mutateAsync: createClinicianRecurringAvailability } =
    useCreateClinicianRecurringAvailabilityMutation();
  const { addToast } = useContext(ToastContext);

  if (selectedTime === undefined) {
    return <CircularLoader />;
  }
  const onSubmit = async (data: ValidatedType) => {
    try {
      await createClinicianRecurringAvailability({
        appointmentTypes: data.appointmentTypes,
        clinicianId: clinician.id,
        dayEnd: data.dayEnd,
        dayStart: data.dayStart,
        endAt: data.endAt,
        recurringType: data.recurringType,
        startAt: data.startAt,
      });
      onClose();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "TIME_RANGE_OVERLAP"
      ) {
        addToast("Overlaps existing availability", "error");
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "END_AT_BEFORE_START_AT"
      ) {
        addToast("End time must be after start time", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        Add Recurring Availability
      </DialogTitle>
      <RecurringAvailabilityForm
        clinician={clinician}
        editing={false}
        initialValues={{
          appointmentTypes: clinician.appointmentTypes,
          dayEnd: selectedTime.end,
          dayStart: selectedTime.start,
          endAt: null,
          recurringType: RecurringAvailabilityType.WEEKLY,
          startAt: selectedTime.start,
        }}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );
};
export default AddRecurringAvailability;
