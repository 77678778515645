import { DashboardPlot } from "@curaleaf-international/components";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid2";

import Page from "src/components/Page";
import { useDashboardQuery } from "src/queries";

const Dashboard = () => {
  const { data: dashboard } = useDashboardQuery();

  return (
    <Page title="Dashboard">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Card>
            <CardHeader title="Assisted Referrals" />
            <Box height="400px">
              <DashboardPlot
                colors={["#0094CA", "#005A85"]}
                data={{
                  AsssistedReferrals: dashboard?.assistedReferrals ?? [],
                }}
                loading={dashboard === undefined}
              />
            </Box>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Card>
            <CardHeader title="Appointments" />
            <Box height="400px">
              <DashboardPlot
                colors={["#0094CA", "#005A85"]}
                data={{
                  Occured: dashboard?.appointmentsOccured ?? [],
                  Booked: dashboard?.confirmedAppointments ?? [],
                }}
                referenceMax={dashboard?.maxConfirmedAppointments}
                loading={dashboard === undefined}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Dashboard;
