import {
  SubmitButton,
  FormLinks,
  TextField,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { Diagnosis } from "src/models/specialityGroup";
import { useCliniciansQuery } from "src/queries";

export interface IAppointmentTypeRecord {
  [key: string]: boolean;
}

const FormSchema = z.object({
  associatedDiagnoses: z.array(z.nativeEnum(Diagnosis)),
  clinicians: z.array(z.string().uuid()),
  complexClinicians: z.array(z.string().uuid()),
  specialityName: z.string(),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.infer<typeof FormSchema>;

interface IProps {
  back: string;
  onSubmit: (data: ValidatedType) => Promise<any>;
  submitLabel: string;
  initialValues: FormType;
}

const SpecialityGroupForm = ({
  back,
  initialValues,
  onSubmit,
  submitLabel,
}: IProps) => {
  const { data: allClinicians } = useCliniciansQuery();

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(initialValues), [initialValues]);

  const values = methods.watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <TextField
                fullWidth
                label="Speciality Name"
                name="specialityName"
              />

              <SelectField
                label="Associated Diagnoses"
                name="associatedDiagnoses"
                options={Object.values(Diagnosis).map((diagnosis) => ({
                  label: diagnosis,
                  value: diagnosis,
                }))}
                multiple={true}
                required
                fullWidth
              />

              <SelectField
                label="Clinicians"
                name="clinicians"
                options={
                  allClinicians?.map((clinician) => ({
                    label: clinician.name,
                    value: clinician.id,
                  })) ?? []
                }
                multiple={true}
                required
                fullWidth
              />
              <SelectField
                label="Specialist Clinicians"
                name="complexClinicians"
                options={
                  allClinicians
                    ?.filter((clinician) => {
                      return values.clinicians.includes(clinician.id);
                    })
                    .map((clinician) => ({
                      label: clinician.name,
                      value: clinician.id,
                    })) ?? []
                }
                multiple={true}
                fullWidth
              />
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={submitLabel} />
            <FormLinks
              links={[
                {
                  label: "Back",
                  to: back,
                },
              ]}
            />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default SpecialityGroupForm;
