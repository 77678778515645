import {
  QueryKey,
  UseQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";

import {
  ProductGroup,
  ProductGroupHistory,
  newProductGroup,
  newProductGroupHistory,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useProductGroupQuery = (
  productGroupId: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, ProductGroup, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<ProductGroup>({
    ...options,
    queryKey: ["productGroups", { productGroupId: productGroupId ?? "" }],
    queryFn: async () => {
      const response = await axios.get(`/v1/product-groups/${productGroupId}/`);
      return newProductGroup(response.data);
    },
    enabled: productGroupId !== undefined,
  });
};

export const useProductGroupsQuery = () => {
  return useQuery<ProductGroup[]>({
    queryKey: ["productGroups"],
    queryFn: async () => {
      const response = await axios.get("/v1/product-groups/");
      return response.data.productGroups.map((data: unknown) => {
        return newProductGroup(data);
      });
    },
  });
};

export const useProductGroupHistoryQuery = (
  groupId: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, ProductGroupHistory[], QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<ProductGroupHistory[]>({
    ...options,
    queryKey: ["productGroupHistory", { groupId: groupId ?? "" }],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/product-groups/${groupId}/history/`,
      );
      return response.data.history.map((data: unknown) => {
        return newProductGroupHistory(data);
      });
    },
    enabled: groupId !== undefined,
  });
};

export interface IProductGroupData {
  name: string;
  productIds: string[];
  productType: string;
}

export const useCreateProductGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IProductGroupData) =>
      await axios.post("/v1/product-groups/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productGroups"] });
      queryClient.invalidateQueries({ queryKey: ["productGroupHistory"] });
    },
  });
};

export const useEditProductGroupMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IProductGroupData) =>
      await axios.put(`/v1/product-groups/${id}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productGroups"] });
      queryClient.invalidateQueries({ queryKey: ["productGroupHistory"] });
    },
  });
};

export interface IDiscontinueProductGroupData {
  discontinuedReason: string;
}

export const useDiscontinueProductGroupMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IDiscontinueProductGroupData) =>
      await axios.put(`/v1/product-groups/${id}/discontinued/`, {
        discontinued: true,
        ...data,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["productGroups"] }),
  });
};

export const useActivateProductGroupMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(`/v1/product-groups/${id}/discontinued/`, {
        discontinued: false,
        discontinuedReason: null,
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["productGroups"] }),
  });
};
