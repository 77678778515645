import {
  SubmitButton,
  FormLinks,
  AutocompleteField,
  TextField,
  sortByKey,
  CircularLoader,
  SelectField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { Clinician, Prescriber, PrescriberType } from "src/models";
import { useCliniciansQuery, usePrescribersQuery } from "src/queries";

const FormSchema = z.object({
  clinicianId: z.string(),
  fullTitle: z.string(),
  pin: z.string().refine((value) => value?.match(/^\d{6,7}$/) !== null, {
    message: "Pin must be 6 or 7 digits",
  }),

  prescriberType: z.nativeEnum(PrescriberType),
  registrationNumber: z.string(),
  specialism: z.string(),
});

type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.infer<typeof FormSchema>;

interface IProps {
  onSubmit: (data: ValidatedType) => Promise<any>;
  submitLabel: string;
  initialValues: FormType;
  back: string;
}

const PrescriberForm = ({
  back,
  initialValues,
  onSubmit,
  submitLabel,
}: IProps) => {
  const { data: clinicians } = useCliniciansQuery();
  const { data: prescribers } = usePrescribersQuery();

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  const sortKey = (clinician: Clinician) => {
    return [clinician.name];
  };

  if (clinicians === undefined || prescribers === undefined) {
    return <CircularLoader />;
  }

  const filteredClinicians = clinicians.filter((clinician: Clinician) => {
    return (
      !prescribers.some((prescriber: Prescriber) => {
        return prescriber.clinicianId === clinician.id;
      }) || clinician.id === initialValues.clinicianId
    );
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Full title (with suffixes)"
                    name="fullTitle"
                  />
                </Grid>

                <AutocompleteField
                  fullWidth
                  label="Clinician"
                  name="clinicianId"
                  options={filteredClinicians
                    .sort(sortByKey(sortKey, "desc"))
                    .map((clinician) => ({
                      label: `${clinician.name}`,
                      value: clinician.id.toString(),
                    }))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField fullWidth required label="Pin" name="pin" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Registration number"
                  name="registrationNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Specialism"
                  name="specialism"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  fullWidth
                  label="Prescriber Type"
                  name="prescriberType"
                  options={Object.values(PrescriberType)
                    .sort()
                    .map((type) => ({
                      label: type,
                      value: type.toString(),
                    }))}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={submitLabel} />
            <FormLinks
              links={[
                {
                  label: "Back",
                  to: back,
                },
              ]}
            />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default PrescriberForm;
