import {
  FormLinks,
  SkeletonRow,
  Title,
  ToastContext,
  formatDate,
  sortByKey,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useContext } from "react";
import { useLocation, Link as WLink } from "wouter";

import { Itinerary, Prescription } from "src/models";
import {
  useGenerateTravelLetterMutation,
  usePatientQuery,
  useTravelLetterPrescriptionsQuery,
  useTravelLetterQuery,
} from "src/queries";

interface IProps {
  travelLetterId: string;
}

const GenerateTravelLetter = ({ travelLetterId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: letter } = useTravelLetterQuery(travelLetterId);
  const { data: patient } = usePatientQuery(letter?.patientId);
  const { data: prescriptions } =
    useTravelLetterPrescriptionsQuery(travelLetterId);
  const { mutateAsync: generateTravelLetter, isPending } =
    useGenerateTravelLetterMutation(travelLetterId);

  const onClick = async () => {
    try {
      await generateTravelLetter();
      addToast("Travel letter generated", "success");
      setLocation(`/travel-letters/${travelLetterId}/`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.code) {
          addToast(
            `Error generating file: ${error.response?.data.code}`,
            "error",
          );
        } else {
          addToast("Try again", "error");
        }
      }
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          { to: "/travel-letters/", label: "Travel Letters" },
          {
            to: `/travel-letters/${travelLetterId}/`,
            label: `Letter for ${patient?.name}`,
          },
          { label: "Generate Travel Letter" },
        ]}
        title="Generate Travel Letter"
      />
      <Card>
        <CardContent sx={{ paddingBottom: 0 }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Patient Name</TableCell>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/patients/${letter?.patientId}/`}
                    >
                      {patient?.title} {patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created by</TableCell>
                  <TableCell>
                    <Link component={WLink} href={`/staff/${letter?.staffId}/`}>
                      {letter?.staffEmail ?? patient?.name}
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardHeader title="Itinerary" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Destination</TableCell>
                <TableCell>Arrival</TableCell>
                <TableCell>Departure</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {letter?.itinerary
                .sort(sortByKey((destination) => [destination.arrival], "asc"))
                .map((part: Itinerary) => (
                  <TableRow key={part.destination}>
                    <TableCell>{part.destination}</TableCell>
                    <TableCell>{formatDate(part.arrival)}</TableCell>
                    <TableCell>{formatDate(part.departure)}</TableCell>
                  </TableRow>
                )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <CardHeader title="Prescriptions" />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Last Printed</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptions?.map((prescription: Prescription) => (
                <TableRow key={prescription.id}>
                  <TableCell>{prescription.productName}</TableCell>
                  <TableCell>{formatDate(prescription.issueDate)}</TableCell>
                  <TableCell>{prescription.quantity}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={3} />}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            loading={isPending}
            onClick={onClick}
            variant="contained"
            disabled={letter === undefined}
          >
            Generate
          </LoadingButton>
          <FormLinks
            links={[
              { label: "Back", to: `/travel-letters/${travelLetterId}/` },
            ]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default GenerateTravelLetter;
