import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useSpecialityGroupQuery } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  specialityGroupId: number | undefined;
}

const SpecialityGroupDetails = ({ specialityGroupId }: IProps) => {
  const { data: group } = useSpecialityGroupQuery(specialityGroupId);

  return (
    <Card>
      <CardHeader title="Speciality group" />
      <Divider />

      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                <Value text={group?.specialityName} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Associate Diagnoses</TableCell>
              <TableCell>
                <Value
                  text={group?.associatedDiagnoses
                    .map((diagnosis) =>
                      convertEnumValueToReadableString(diagnosis, " "),
                    )
                    .join(", ")}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SpecialityGroupDetails;
