import { formatDate } from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { FP10 } from "src/models";

interface IProps {
  fp10: FP10;
}

const Fp10Row = ({ fp10 }: IProps) => {
  return (
    <TableRow>
      <TableCell align="center">
        <Link component={WLink} to={`/fp10s/${fp10.id}/`}>
          {fp10.serial}
        </Link>
      </TableCell>
      <TableCell align="center">{fp10.state}</TableCell>
      <TableCell align="center">{formatDate(fp10.stateTimestamp)}</TableCell>
      <TableCell align="center">
        {fp10.stateReason !== null ? fp10.stateReason : null}
      </TableCell>
      <TableCell align="center">
        <Link component={WLink} to={`/staff/${fp10.stateStaffId}/`}>
          {fp10.stateStaffEmail}
        </Link>
      </TableCell>
      <TableCell align="center">
        {fp10.stateWitnessEmail ? (
          <Link component={WLink} to={`/staff/${fp10.stateWitnessId}/`}>
            {fp10.stateWitnessEmail}
          </Link>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default Fp10Row;
