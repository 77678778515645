import axios from "axios";

import { Product, newProduct } from "src/models";
import { useQuery } from "src/query";

export const useProductsQuery = (includeOtherMeds: boolean) => {
  return useQuery<Product[]>({
    queryKey: ["products", includeOtherMeds],
    queryFn: async () => {
      const params = { includeOtherMeds };
      const response = await axios.get("/v1/products/", { params });
      return response.data.products.map((data: unknown) => {
        return newProduct(data);
      });
    },
  });
};

export type ProductMapping = { [key: string]: Product };

export const useProductsQuery1 = (includeOtherMeds: boolean) =>
  useQuery<ProductMapping>({
    queryKey: ["products", includeOtherMeds],
    queryFn: async () => {
      const params = { includeOtherMeds };
      const response = await axios.get("/v1/products/", { params });
      return response.data.formulas.reduce(
        (accum: ProductMapping, json: unknown) => {
          const product = newProduct(json);
          accum[product.id] = product;
          return accum;
        },
        {},
      );
    },
  });
