import {
  DateField,
  SelectField,
  SubmitButton,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  endOfDay,
  endOfTomorrow,
  formatISO,
  startOfDay,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import { AppointmentStatus, AppointmentType } from "src/models";
import { Appointment } from "src/models/appointment";
import AppointmentRow from "src/pages/Appointments/AppointmentRow";
import { useAppointmentsQuery } from "src/queries/appointments";

interface IProps {
  clinicians: { label: string; value: string }[];
}

const FormSchema = z.object({
  after: z.date(),
  before: z.date(),
  clinician: z.string().nullable(),
  status: z.union([z.nativeEnum(AppointmentStatus), z.literal("")]).nullable(),
  type: z.union([z.nativeEnum(AppointmentType), z.literal("")]).nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

type Direction = "asc" | "desc";
type OrderableProperties =
  | "clinicianName"
  | "patientName"
  | "length"
  | "startAt";

const AppointmentsTable = ({ clinicians }: IProps) => {
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("startAt");
  const [after, setAfter] = useState<Date>(startOfToday());
  const [before, setBefore] = useState<Date>(endOfTomorrow());
  const [clinician, setClinician] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<AppointmentStatus | null>(null);
  const [type, setType] = useState<AppointmentType | null>(null);
  const { data: appointments } = useAppointmentsQuery(
    after,
    before,
    clinician,
    status,
    type,
  );
  const defaultValues = {
    after: startOfToday(),
    before: endOfTomorrow(),
    clinician: "",
    status: "" as const,
    type: "" as const,
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ValidatedType) => {
    const params: any = {
      after: formatISO(startOfDay(data.after), { representation: "date" }),
      before: formatISO(endOfDay(data.before), { representation: "date" }),
      clinician: data.clinician,
      status: data.status,
      type: data.type,
    };

    setAfter(startOfDay(params.after));
    setBefore(endOfDay(params.before));
    setClinician(data.clinician || undefined);
    setStatus(data.status || null);
    setType(data.type || null);
    methods.reset(data);
  };

  const resetForm = () => {
    methods.reset(defaultValues);
    setAfter(defaultValues.after);
    setBefore(defaultValues.before);
    setClinician(undefined);
    setStatus(null);
    setType(null);
  };

  const sortKey = (appointments: Appointment) => [appointments[orderBy]];

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let rows;
  if (appointments === undefined) {
    rows = <SkeletonRow cols={7} />;
  } else if (appointments.length > 0) {
    const sortedAppointments = appointments.sort(sortByKey(sortKey, order));
    rows = sortedAppointments.map((appointment, index) => {
      return <AppointmentRow key={index} appointment={appointment} />;
    });
  } else if (appointments.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan={7} align="center">
          No appointments found.
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Card>
      <CardContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid alignItems={"center"} container spacing={2}>
              <Grid item sm={4} xs={12}>
                <DateField fullWidth label="After" name="after" required />
              </Grid>
              <Grid item sm={4} xs={12}>
                <DateField fullWidth label="Before" name="before" required />
              </Grid>
              <Grid item sm={4} xs={12}>
                <SelectField
                  fullWidth
                  label="Clinician"
                  name="clinician"
                  options={Object.values(clinicians).map(
                    (clinician) => clinician,
                  )}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <SelectField
                  fullWidth
                  label="Appointment Status"
                  name="status"
                  options={[
                    AppointmentStatus.CANCELLED,
                    AppointmentStatus.CONFIRMED,
                    AppointmentStatus.CLINICIAN_DNA,
                    AppointmentStatus.PATIENT_DNA,
                    AppointmentStatus.PENDING,
                  ].map((status) => ({
                    value: status,
                  }))}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <SelectField
                  fullWidth
                  label="Appointment Type"
                  name="type"
                  options={Object.values(AppointmentType).map((type) => ({
                    value: type,
                  }))}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <SubmitButton label="Filter" fullWidth />
              </Grid>
              <Grid item sm={1} xs={12}>
                <Button fullWidth onClick={() => resetForm()}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "patientName"}
                  direction={order}
                  onClick={onSortClick("patientName")}
                >
                  Patient
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={orderBy === "startAt"}
                  direction={order}
                  onClick={onSortClick("startAt")}
                >
                  Start
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "clinicianName"}
                  direction={order}
                  onClick={onSortClick("clinicianName")}
                >
                  Clinician
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "length"}
                  direction={order}
                  onClick={onSortClick("length")}
                >
                  Length
                </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AppointmentsTable;
