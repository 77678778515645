import MenuItem from "@mui/material/MenuItem";
import { useContext } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import { Role, TravelLetter } from "src/models";
import {
  usePatientQuery,
  usePrescriberByClinicianIdQuery,
  useTravelLetterPrescriptionsQuery,
} from "src/queries";

interface IProps {
  letter: TravelLetter;
}

const TravelLetterActions = ({ letter }: IProps) => {
  const { data: patient } = usePatientQuery(letter.patientId);
  const { data: letterPrescriber } = usePrescriberByClinicianIdQuery(
    patient?.assignedConsultantId,
  );
  const { data: prescriptions } = useTravelLetterPrescriptionsQuery(
    letter.travelLetterId,
  );
  const { member } = useContext(AuthContext);

  const isSpainPresent =
    letter.itinerary.some((item) => item.destination === "Spain") || false;
  const areSignaturesMissing =
    prescriptions?.some((item) => !item.prescriberSignaturePresent) || false;
  const generationBlocked = isSpainPresent && areSignaturesMissing;
  const isNotMedicalSecretary = !member?.roles?.some(
    (role) =>
      role === Role.MEDICAL_SECRETARY || role === Role.MEDICAL_SECRETARY_LEAD,
  );

  return (
    <>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/assign/`}
        disabled={
          letter === undefined ||
          letter?.state !== "CREATED" ||
          letter?.assignedTo !== null ||
          isNotMedicalSecretary
        }
      >
        Assign
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/remove-assignment/`}
        disabled={
          letter === undefined ||
          letter?.state !== "CREATED" ||
          letter?.assignedTo === null ||
          isNotMedicalSecretary
        }
      >
        Remove assignment
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/approve/`}
        disabled={
          letter === undefined ||
          letter?.state !== "CREATED" ||
          !letter?.letterPdfPresent
        }
      >
        Approve
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/cancel/`}
        disabled={
          letter === undefined ||
          letter?.state !== "CREATED" ||
          isNotMedicalSecretary
        }
      >
        Cancel
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/edit/`}
        disabled={letter?.state !== "CREATED"}
      >
        Edit
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/generate/`}
        disabled={
          generationBlocked ||
          letterPrescriber?.signature === null ||
          letterPrescriber?.signature === undefined ||
          letter.state !== "CREATED" ||
          isNotMedicalSecretary
        }
      >
        Generate
      </MenuItem>
      <MenuItem
        component={WLink}
        to={`/travel-letters/${letter.travelLetterId}/upload/`}
        disabled={letter?.state !== "CREATED" || isNotMedicalSecretary}
      >
        Upload
      </MenuItem>
      <MenuItem
        component="a"
        href={`/v1/travel-letters/${letter.travelLetterId}/file/`}
        disabled={!letter?.letterPdfPresent}
      >
        Download
      </MenuItem>
    </>
  );
};
export default TravelLetterActions;
