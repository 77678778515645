import { Title, Value, formatDate } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { usePrescriberByStaffIdQuery, usePrescriberQuery } from "src/queries";

interface IProps {
  prescriberId: string;
}

const Prescriber = ({ prescriberId }: IProps) => {
  const { data: prescriber } = usePrescriberQuery(prescriberId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: loggedInPrescriber } = usePrescriberByStaffIdQuery();

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem component={WLink} to={`/prescribers/${prescriber?.id}/edit/`}>
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          to={`/prescribers/${prescriber?.id}/add-signature/`}
          disabled={
            prescriber?.signature === undefined ||
            prescriber !== loggedInPrescriber
          }
        >
          Add signature
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={prescriber?.fullTitle ?? ""}
      />
      <Card>
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Full Title</TableCell>
                  <TableCell>
                    <Value text={prescriber?.fullTitle} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Clinician Name</TableCell>
                  <TableCell>
                    <Value
                      link={
                        prescriber !== undefined
                          ? {
                              label: `${prescriber.clinicianName}`,
                              to: `/clinicians/${prescriber.clinicianId}/`,
                            }
                          : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Pin</TableCell>
                  <TableCell>
                    <Value text={prescriber?.pin} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Registration Number</TableCell>
                  <TableCell>
                    <Value text={prescriber?.registrationNumber} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Specialism</TableCell>
                  <TableCell>
                    <Value text={prescriber?.specialism} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Prescriber Type</TableCell>
                  <TableCell>
                    <Value text={prescriber?.prescriberType} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>
                    <Value
                      text={
                        prescriber?.created
                          ? formatDate(prescriber.created)
                          : ""
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created By</TableCell>
                  <TableCell>
                    <Value
                      link={
                        prescriber !== undefined
                          ? {
                              label: `${prescriber.createdByEmail}`,
                              to: `/staff/${prescriber.createdBy}/`,
                            }
                          : undefined
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Signature</TableCell>
                  <TableCell>
                    {prescriber?.signature ? (
                      <img
                        src={`data:image/png;base64,${prescriber.signature}`}
                        alt="Prescriber Signature"
                        style={{ width: "50px", height: "auto" }}
                      />
                    ) : (
                      "No"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};

export default Prescriber;
