import { SkeletonRow, formatDate } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useState, MouseEvent } from "react";
import { Link as WLink } from "wouter";

import Products from "src/components/Products";
import { OutOfStockListing } from "src/models";
import { useOutOfStockListingsQuery } from "src/queries";
import { SX_WARNING } from "src/utils";

interface IProps {
  listing: OutOfStockListing;
}

const OutOfStockListingTableRow = ({ listing }: IProps) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const { data: listings } = useOutOfStockListingsQuery(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!listings) return <SkeletonRow cols={5} />;
  const availableAlternativeCount = listing.alternativeProducts.filter(
    (productId) => !listings.some((l) => l.productId === productId),
  ).length;

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/`}
        >
          View
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/edit/`}
          disabled={listing.endedOn !== null}
        >
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/out-of-stock-listings/${listing.id}/close/`}
          disabled={listing.endedOn !== null}
        >
          Mark in stock
        </MenuItem>
      </Menu>
      <TableRow
        key={listing.id}
        sx={availableAlternativeCount < 2 ? SX_WARNING : null}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link
            component={WLink}
            href={`/out-of-stock-listings/${listing.id}/`}
          >
            {listing.productName}
          </Link>
        </TableCell>
        <TableCell>{`${formatDate(listing.createdOn)} by ${
          listing.createdByEmail
        } `}</TableCell>
        <TableCell>
          {listing.endedOn
            ? `${formatDate(listing.endedOn)} by ${listing.endedByEmail}`
            : "Active"}
        </TableCell>
        <TableCell>{listing.patientInformation ? "Yes" : "No"}</TableCell>
        <TableCell>{listing.alternativeProducts.length}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box sx={{ padding: 2 }}>
              <Products productIds={listing.alternativeProducts} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OutOfStockListingTableRow;
