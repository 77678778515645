import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatISO } from "date-fns";
import Fuse from "fuse.js";

import { Patient } from "src/models/patient";
import PatientRow from "src/pages/Patients/PatientRow";
import { usePatientsWithEmailIssuesQuery } from "src/queries";

interface IProps {
  searchValue: string | null;
  dateOfBirth: Date | null;
}
const PatientEmailIssuesTable = ({ searchValue, dateOfBirth }: IProps) => {
  const { data: patients } = usePatientsWithEmailIssuesQuery();

  const filterPatients = (patients: Patient[]) => {
    if (searchValue !== "" && searchValue !== null) {
      const fuse = new Fuse(patients, { keys: ["name"] });
      return fuse.search(searchValue).map((value) => value.item);
    }
    if (dateOfBirth !== null) {
      return patients.filter((patient) => {
        return (
          formatISO(patient.dob, { representation: "date" }) ===
          formatISO(dateOfBirth, { representation: "date" })
        );
      });
    }
    return patients;
  };
  let rows;

  if (patients === undefined) {
    rows = <SkeletonRow cols={7} />;
  } else {
    const filteredPatients = filterPatients(patients);
    if (filteredPatients.length === 0) {
      rows = (
        <TableRow>
          <TableCell colSpan={7} align="center">
            No Patients found.
          </TableCell>
        </TableRow>
      );
    } else {
      rows = filteredPatients.map((patient, index) => (
        <PatientRow key={index} patient={patient} />
      ));
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date of Birth</TableCell>
            <TableCell>Postcode</TableCell>
            <TableCell>NHS Number</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientEmailIssuesTable;
