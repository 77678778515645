import {
  Title,
  useHash,
  DateField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import PatientEmailIssuesTable from "src/pages/Patients/PatientEmailIssuesTable";
import PatientsTable from "src/pages/Patients/PatientsTable";

type TabState = "search" | "email";

const FormSchema = z.object({
  search: z.string().trim().nullable(),
  dateOfBirth: z.coerce.date().nullable(),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const Patients = () => {
  const [tab, setTab] = useHash<TabState>("search");
  const [search, setSearch] = useState<string | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const defaultValues = {
    search: "",
    dateOfBirth: null,
  };

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const onSubmit = (data: ValidatedType) => {
    setSearch(data.search);
    setDateOfBirth(data.dateOfBirth);
    methods.reset(data);
  };

  const reset = () => {
    methods.reset(defaultValues);
    setSearch(null);
    setDateOfBirth(null);
  };

  return (
    <>
      <Title title="Patients" />
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Search" value="search" />
          <Tab label="Email issues" value="email" />
        </TabList>
        <Card>
          <CardContent>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid alignItems="center" container spacing={2}>
                  <Grid item sm={5} xs={12}>
                    <TextField
                      fullWidth
                      label="First name, last name,  email, postcode"
                      name="search"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <DateField
                      fullWidth
                      label="Date of Birth"
                      name="dateOfBirth"
                    />
                  </Grid>
                  <Grid item sm={2} xs={6}>
                    <SubmitButton label="Filter" fullWidth />
                  </Grid>
                  <Grid item sm={1} xs={6}>
                    <Button onClick={() => reset()}>Reset</Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </CardContent>
          <TabPanel value="search">
            <PatientsTable searchValue={search} dateOfBirth={dateOfBirth} />
          </TabPanel>
          <TabPanel value="email">
            <PatientEmailIssuesTable
              searchValue={search}
              dateOfBirth={dateOfBirth}
            />
          </TabPanel>
        </Card>
      </TabContext>
    </>
  );
};

export default Patients;
