import {
  SubmitButton,
  formatDate,
  formatTime,
  ToastContext,
  formatCurrency,
  AutocompleteField,
} from "@curaleaf-international/components";
import { EventImpl } from "@fullcalendar/core/internal";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { addMinutes } from "date-fns";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as z from "zod";

import { Patient } from "src/models/patient";
import { useEditAppointmentMutation } from "src/queries";
import { convertEnumValueToReadableString } from "src/utils";

interface IProps {
  appointmentId: string;
  onClose: () => void;
  open: boolean;
  patient: Patient | undefined;
  selectedSlot: EventImpl;
}

const FormSchema = z.object({
  reason: z.string(),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.infer<typeof FormSchema>;

const editReasonOptions = [
  { value: "Clinician Unavailable" },
  { value: "Clinician Medical Issue" },
  { value: "Clinician Technical Issue" },
  { value: "Patient Unavailable" },
  { value: "Patient Medical Issue" },
  { value: "Patient Technical Issue" },
];

const EditAppointmentDialog = ({
  appointmentId,
  onClose,
  open,
  patient,
  selectedSlot,
}: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: EditAppointment } = useEditAppointmentMutation(
    appointmentId ?? "",
  );

  const methods = useForm<ValidatedType>({
    defaultValues: {
      reason: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    if (patient !== undefined && selectedSlot.start !== null) {
      try {
        await EditAppointment({
          appointmentType: selectedSlot.extendedProps.appointmentType,
          clinicianId: selectedSlot.extendedProps.clinicianId,
          patientId: patient.id,
          reason: data.reason,
          start: selectedSlot.start,
        });
        setLocation(`/appointments/${appointmentId}/`);
        addToast("Appointment Edited", "success");
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "NOT_VALID"
        ) {
          addToast("Invalid appointment.", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "SLOT_NOT_AVAILABLE"
        ) {
          addToast("Appointment slot not available.", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "OVERLAPS_EXISTING_APPOINTMENT"
        ) {
          addToast("Overlaps existing appointment.", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "PRICE_INCREASE"
        ) {
          addToast("Cannot replace with more expensive appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code == "APPOINTMENT_OVER_2_DAYS_IN_PAST"
        ) {
          addToast(
            "Cannot edit appointment originally scheduled for more than 2 days in the past.",
            "error",
          );
        } else {
          addToast("Try again", "error");
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>New Appointment</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={2}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Clinician</TableCell>
                      <TableCell>
                        {selectedSlot.extendedProps.clinicianName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Patient</TableCell>
                      <TableCell>{patient?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Appointment Type</TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {convertEnumValueToReadableString(
                          selectedSlot.extendedProps.appointmentType,
                          "-",
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      {selectedSlot && selectedSlot.start ? (
                        <TableCell>{formatDate(selectedSlot?.start)}</TableCell>
                      ) : null}
                    </TableRow>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      {selectedSlot && selectedSlot.start ? (
                        <TableCell>
                          {formatTime(selectedSlot.start)} to{" "}
                          {formatTime(
                            addMinutes(
                              selectedSlot.start,
                              selectedSlot.extendedProps.length,
                            ),
                          )}
                        </TableCell>
                      ) : null}
                    </TableRow>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell>
                        {" "}
                        {selectedSlot.extendedProps.price.isZero()
                          ? "Free"
                          : formatCurrency(selectedSlot.extendedProps.price)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <AutocompleteField
                fullWidth
                required={appointmentId !== undefined}
                freeSolo
                options={editReasonOptions}
                name="reason"
                label="Reason for Change of Appointment"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
            <SubmitButton label="Confirm" />
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditAppointmentDialog;
