import { useQuery } from "@curaleaf-international/components";
import axios from "axios";

import { newAllEndpointRoles, AllEndpointRoles } from "src/models/utils";

export const useEndpointRolesQuery = () =>
  useQuery<AllEndpointRoles>({
    queryKey: ["endpoint-roles"],
    queryFn: async () => {
      const response = await axios.get("/v1/utils/endpoint-roles/");
      return newAllEndpointRoles(response.data);
    },
  });
