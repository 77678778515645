import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Fuse from "fuse.js";
import { memo, useEffect, useState } from "react";

import { Prescriber } from "src/models/prescriber";
import PrescriberRow from "src/pages/Prescribers/PrescriberRow";
import { usePrescribersQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "fullTitle"
  | "clinicianName"
  | "pin"
  | "prescriberType"
  | "registrationNumber"
  | "specialism";

export type States = "all" | "unverified";

interface IProps {
  search: string;
}

const PrescribersTable = ({ search }: IProps) => {
  const { data: prescribers } = usePrescribersQuery();
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties | null>(
    "fullTitle",
  );

  useEffect(() => {
    if (search !== "") {
      setOrderBy(null);
    }
  }, [search]);

  let rows;
  if (prescribers === undefined) {
    rows = <SkeletonRow cols={6} />;
  } else {
    let filteredPrescribers = Object.values(prescribers);

    if (search !== "") {
      const fuse = new Fuse(filteredPrescribers, {
        keys: [
          "fullTitle",
          "pin",
          "registrationNumber",
          "specialism",
          "clinicianName",
        ],
      });
      filteredPrescribers = fuse.search(search).map((value) => value.item);
    }
    if (orderBy !== null) {
      const sortKey = (prescriber: Prescriber) => [prescriber[orderBy]];
      filteredPrescribers = filteredPrescribers.sort(sortByKey(sortKey, order));
    }

    rows = filteredPrescribers.map((prescriber: Prescriber) => (
      <PrescriberRow key={prescriber.id} prescriber={prescriber} />
    ));
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "fullTitle"}
                direction={order}
                onClick={onSortClick("fullTitle")}
              >
                Full Title
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "clinicianName"}
                direction={order}
                onClick={onSortClick("clinicianName")}
              >
                Clinician
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "pin"}
                direction={order}
                onClick={onSortClick("pin")}
              >
                Pin
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "registrationNumber"}
                direction={order}
                onClick={onSortClick("registrationNumber")}
              >
                Registration
              </TableSortLabel>
            </TableCell>
            <TableCell>Signature</TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={orderBy === "specialism"}
                direction={order}
                onClick={onSortClick("specialism")}
              >
                Specialism
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(PrescribersTable);
