import { Search as BaseSearch } from "@curaleaf-international/components";
import { StandardTextFieldProps } from "@mui/material/TextField";
import { ChangeEvent } from "react";

import { PaginationSettings } from "src/pagination";

interface IProps<RowType> extends StandardTextFieldProps {
  setSearchQuery: (rawSearchQuery: string) => void;
  paginationSettings: PaginationSettings<RowType>;
}

const PaginatedSearch = <RowType,>({
  setSearchQuery,
  paginationSettings,
  ...props
}: IProps<RowType>) => {
  return (
    <BaseSearch
      fullWidth
      label="Search"
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        setSearchQuery(event.target.value)
      }
      value={paginationSettings.rawSearchQuery}
      {...props}
    />
  );
};

export default PaginatedSearch;
