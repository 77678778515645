import * as z from "zod";

export enum FP10State {
  CREATED = "CREATED",
  DESTROYED = "DESTROYED",
}

const FP10Schema = z.object({
  id: z.string().uuid(),
  serial: z.string(),
  state: z.nativeEnum(FP10State),
  stateReason: z.string().nullable(),
  stateStaffEmail: z.string().email(),
  stateStaffId: z.coerce.number().int().positive(),
  stateTimestamp: z.coerce.date(),
  stateWitnessEmail: z.string().email().nullable(),
  stateWitnessId: z.number().int().positive().nullable(),
});

export type FP10 = z.infer<typeof FP10Schema>;

export const newFP10 = (data: unknown): FP10 => FP10Schema.parse(data);
