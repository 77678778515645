import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactNode } from "react";

import { ProductGroupHistory } from "src/models";
import ProductGroupHistoryTableRow from "src/pages/ProductGroup/ProductGroupHistoryTableRow";
import { useProductGroupHistoryQuery } from "src/queries";

interface IProps {
  groupId: string;
}

const ProductGroupHistoryTable = ({ groupId }: IProps) => {
  const { data: history } = useProductGroupHistoryQuery(groupId);

  const sortKey = (history: ProductGroupHistory) => [history["created"]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={3} />;
  let sortedHistory: ProductGroupHistory[] = [];

  if (history && history.length > 0) {
    sortedHistory = history.sort(sortByKey(sortKey, "desc"));

    rows = sortedHistory.map((record, index) => (
      <ProductGroupHistoryTableRow
        key={record.id}
        record={record}
        current={index === 0}
      />
    ));
  } else if (history !== null) {
    rows = (
      <TableRow>
        <TableCell colSpan={3}>No history found</TableCell>
      </TableRow>
    );
  }

  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Ended</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProductGroupHistoryTable;
