import { CircularLoader } from "@curaleaf-international/components";
import { createElement as h, ReactElement, useContext } from "react";
import { DefaultParams, Redirect, RouteProps, useRoute } from "wouter";

import { AuthContext } from "src/AuthContext";
import { usePrescriberByStaffIdQuery } from "src/queries";

type SwitchedProps<T extends DefaultParams> = { match?: any } & RouteProps<T>;

const PrivateRoute = <T extends DefaultParams>({
  path,
  match,
  component,
  children,
}: SwitchedProps<T>): ReactElement | null => {
  const { member } = useContext(AuthContext);
  const { data: prescriber } = usePrescriberByStaffIdQuery();
  const useRouteMatch = useRoute(path as string);

  // `props.match` is present - Route is controlled by the Switch
  const [matches, params] = match || useRouteMatch;

  if (!matches) return null;
  if (member === undefined) {
    return <CircularLoader />;
  } else if (member === null) {
    return <Redirect to="/login/" />;
  } else if (member.roles.length === 0) {
    return <Redirect to="/insecure/" />;
  } else {
    if (prescriber?.signature === null) {
      return (
        <Redirect
          to={`/prescribers/${prescriber.id}/add-signature/`}
          replace={true}
        />
      );
    } else {
      // React-Router style `component` prop
      if (component) return h(component, { params });

      // support render prop or plain children
      return (
        typeof children === "function" ? children(params) : children
      ) as ReactElement;
    }
  }
};

export default PrivateRoute;
