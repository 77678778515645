import {
  formatDate,
  sortByKey,
  useDebounce,
  Search,
  SkeletonRow,
} from "@curaleaf-international/components";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Fuse from "fuse.js";
import { ChangeEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { ProductGroup } from "src/models";
import { useProductGroupsQuery } from "src/queries";

const ProductGroupTable = () => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const { data: groups } = useProductGroupsQuery();

  const filterGroups = (groups: ProductGroup[]) => {
    if (search !== "") {
      const fuse = new Fuse(groups, {
        keys: ["name"],
      });
      return fuse.search(search).map((value) => value.item);
    } else {
      return groups.sort(
        sortByKey((group) => [group.discontinued, group.name], "asc"),
      );
    }
  };

  return (
    <>
      <CardContent>
        <Search
          fullWidth
          label="Search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setRawSearch(event.target.value)
          }
          value={rawSearch}
        />
      </CardContent>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Product type</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Active prescriptions</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Discontinued</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups === undefined ? (
              <SkeletonRow cols={6} />
            ) : (
              filterGroups(Object.values(groups)).map((group) => (
                <TableRow key={group.id}>
                  <TableCell>
                    <Link
                      component={WLink}
                      href={`/product-groups/${group.id}/`}
                    >
                      {group.name}
                    </Link>
                  </TableCell>
                  <TableCell>{group.productType}</TableCell>
                  <TableCell>{group.productIds.length}</TableCell>
                  <TableCell>{group.prescriptionCount}</TableCell>
                  <TableCell>{formatDate(group.createdOn)}</TableCell>
                  <TableCell>{group.discontinued ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductGroupTable;
