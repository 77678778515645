import { Title } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { Redirect } from "wouter";

import { AuthContext } from "src/AuthContext";

const Insecure = () => {
  const { member } = useContext(AuthContext);

  let message = <Skeleton height="20%" width="100%" />;
  if (member?.roles.length === 0) {
    message = (
      <>
        You have no active roles, please contact your manager to approve your
        access.
      </>
    );
  } else if (member) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Title title="Missing Authentication" />
      <Card>
        <CardContent>
          <Typography variant="body1">{message}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default Insecure;
