import {
  FormLinks,
  SelectField,
  SubmitButton,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useLocation } from "wouter";
import { z } from "zod";

import { AuthContext } from "src/AuthContext";
import SignatureBox, { NoSignatureError } from "src/components/SignatureBox";
import { LetterType } from "src/models/clinician";
import {
  useAddPrescriberSignatureMutation,
  usePrescriberQuery,
} from "src/queries";

const FormSchema = z.object({
  signature: z.string(),
  autoApprovalLetters: z.array(z.nativeEnum(LetterType)),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.infer<typeof FormSchema>;

interface IProps {
  prescriberId: string;
}

const AddPrescriberSignature = ({ prescriberId }: IProps) => {
  const { data: prescriber, isSuccess } = usePrescriberQuery(prescriberId);
  const ref = useRef<any>({});
  const { addToast } = useContext(ToastContext);
  const [, setLocation] = useLocation();
  const { mutateAsync: addSignature } =
    useAddPrescriberSignatureMutation(prescriberId);
  const { member } = useContext(AuthContext);

  const methods = useForm<FormType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      signature: "",
      autoApprovalLetters: [],
    },
  });
  if (member === null) {
    return <Redirect to="/login/" />;
  }

  const onSubmit = async (data: ValidatedType) => {
    try {
      const signature = await ref.current();
      await addSignature({
        signature,
        autoApprovalLetters: data.autoApprovalLetters,
      });

      addToast("Signature saved", "success");
      setLocation(`/prescribers/${prescriberId}/`);
    } catch (error) {
      if (error instanceof NoSignatureError) {
        addToast("Please enter a signature", "error");
      } else {
        addToast("Please try again", "error");
      }
    }
  };

  return (
    <>
      <Title
        title="Add Prescriber Signature"
        breadcrumbs={[
          { to: "/prescribers/", label: "Prescribers" },
          {
            label: `${prescriber?.clinicianName}`,
            to: `/prescribers/${prescriber?.id}/`,
          },
          { label: "Add Prescriber Signature" },
        ]}
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    Permission to Store Signature
                  </Typography>
                  <Typography variant="body2">
                    By providing my signature, I hereby grant permission for my
                    signature to be securely stored by the clinic. I understand
                    that my signature will only be used to sign letters for my
                    patients and that I can personally approve those letters
                    should I choose to do so. The signature will not be used for
                    any other documents or by any other parties.
                  </Typography>
                  <SignatureBox ref={ref} />
                </Stack>
                <SelectField
                  fullWidth
                  helperText="Any option NOT selected will require your approval
                  before being sent."
                  label="Pre-Authorised Letters"
                  multiple={true}
                  name="autoApprovalLetters"
                  options={Object.keys(LetterType).map((letter) => ({
                    label: letter,
                    value: letter,
                  }))}
                />
              </CardContent>
              <Divider />
              <CardActions>
                <SubmitButton disabled={isSuccess === false} label="Submit" />
                <FormLinks
                  links={[
                    { label: "Back", to: `/prescribers/${prescriberId}/` },
                  ]}
                />
              </CardActions>
            </Card>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default AddPrescriberSignature;
