import * as z from "zod";

import { AppointmentType } from "src/models/appointment";

const appointmentLengthSchema = z.object({
  id: z.coerce.number().int().positive(),
  active: z.boolean(),
  appointmentType: z.nativeEnum(AppointmentType),
  buffer: z.number().int(),
  clinicianId: z.string().uuid(),
  created: z.coerce.date(),
  length: z.coerce.number().int().positive(),
});

export type AppointmentLength = z.infer<typeof appointmentLengthSchema>;

export const newAppointmentLength = (data: unknown): AppointmentLength =>
  appointmentLengthSchema.parse(data);
