import {
  default as MuiTablePagination,
  TablePaginationOwnProps,
} from "@mui/material/TablePagination";

import { PaginationSettings } from "src/pagination";

interface IProps<RowType>
  extends Omit<
    TablePaginationOwnProps,
    "count" | "page" | "rowsPerPage" | "onPageChange" | "onRowsPerPageChange"
  > {
  recordCount: number | undefined;
  paginationSettings: PaginationSettings<RowType>;
  setPageSize: (pageSize: number) => void;
  setPageNumber: (pageNumber: number) => void;
}

const TablePagination = <RowType,>({
  recordCount,
  paginationSettings,
  setPageSize,
  setPageNumber,
  ...props
}: IProps<RowType>) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value, 10));
  };
  return (
    <MuiTablePagination
      {...props}
      component="div"
      count={recordCount ?? 0}
      page={paginationSettings.pageNumber}
      rowsPerPage={paginationSettings.pageSize || recordCount || 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default TablePagination;
