import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useFP10Query } from "src/queries";

interface IProps {
  fp10Id: string;
}

const FP10Details = ({ fp10Id }: IProps) => {
  const { data: fp10 } = useFP10Query(fp10Id);

  if (!fp10) {
    return <Skeleton variant="rounded" height="100%" width="100%" />;
  }

  return (
    <Card>
      <CardHeader title="Info" />
      <Divider />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>{fp10.serial}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>{fp10.state}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State reason</TableCell>
              <TableCell>{fp10.stateReason}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default FP10Details;
